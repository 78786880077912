<web-admin-job-edit-status-indicator
  [status]="status"
></web-admin-job-edit-status-indicator>
<form [formGroup]="form" (ngSubmit)="submit()">
  <h1>{{ jobTypeName }} állás szerkesztése</h1>
  <web-admin-job-form
    [formControl]="control"
    [isVolunteer]="isVolunteer"
    [isEdit]="true"
    (switchLinkToCreate)="createSwitchLink = ($event)"
  >
    <div in-bottom-card>
      <div>
        <a
          mat-stroked-button
          type="button"
          data-test="discard"
          color="primary"
          routerLink="/job"
        >
          Elvetés
        </a>
        <button
          mat-stroked-button
          type="button"
          data-test="delete"
          color="warn"
          (click)="onDeleteClicked()"
        >
          Végleges törlés
        </button>
      </div>
      <div>
        <button
          *ngIf="stoppable"
          mat-stroked-button
          type="button"
          data-test="stop"
          color="primary"
          (click)="onStopClicked()"
        >
          Hirdetés leállítása
        </button>
        <button mat-raised-button data-test="submit" color="primary">
          Feltöltés
        </button>
      </div>
    </div>
  </web-admin-job-form>
</form>
