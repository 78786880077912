import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class EditInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.method !== 'PUT' ||
      !/admin\/notification\/\d+$/.test(request.url)
    ) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      tap((result) => {
        if (result.type === HttpEventType.Response) {
          this.snackBar.open('A módosításokat sikeresen mentettük', 'OK', {
            panelClass: ['snack-bar-container', 'success'],
            duration: 3000,
          });
          this.router.navigateByUrl('/notification');
        }
      }),
      catchError((error) => {
        this.snackBar.open(
          'Nem sikerült menteni a módosításokat. Próbáld meg újra.',
          'OK',
          {
            panelClass: ['snack-bar-container', 'failure'],
            duration: 3000,
          }
        );
        return of(error);
      })
    );
  }
}
