import { ChangeDetectionStrategy, Component } from '@angular/core';
import { typeList } from '../helpers/type-name.list';

@Component({
  selector: 'web-admin-create-job-drop-down',
  templateUrl: './create-job-drop-down.component.html',
  styleUrls: ['./create-job-drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateJobDropDownComponent {
  public typeList = typeList;
}
