import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import {
  DateTimeInputModule,
  FileUploaderModule,
  FormCardModule,
  ListEmptyModule,
  MaterialErrorModule,
} from '@web-admin/shared-lib';
import { QuizCreateComponent } from './create/create.component';
import { QuizEditComponent } from './edit/edit.component';
import { FormComponent } from './form/form.component';
import { QuizListComponent } from './list/list.component';
import { QuizRoutingModule } from './quiz-routing.module';
import { ResultComponent } from './result/result.component';

@NgModule({
  declarations: [
    QuizListComponent,
    QuizCreateComponent,
    FormComponent,
    ResultComponent,
    QuizEditComponent,
  ],
  imports: [
    FileUploaderModule,
    CommonModule,
    QuizRoutingModule,
    ReactiveFormsModule,
    MaterialErrorModule,
    MatSnackBarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DateTimeInputModule,
    FormCardModule,
    MatSelectModule,
    MatIconModule,
    MatSelectModule,
    MatPaginatorModule,
    ListEmptyModule,
    MatTableModule,
    MatProgressBarModule,
    FormsModule,
  ],
})
export class QuizModule {}
