import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/auth.guard';
import { LeaveGuard } from '../../core/leave.guard';
import { PageLayoutComponent } from '../layout/page-layout/page-layout.component';
import { JobCreateComponent } from './create/create.component';
import { JobEditComponent } from './edit/edit.component';
import { JobListComponent } from './list/list.component';

const routes: Routes = [
  {
    path: 'job',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: PageLayoutComponent,
    children: [
      {
        path: 'paid',
        children: [
          {
            path: ':id',
            component: JobEditComponent,
            canDeactivate: [LeaveGuard],
          },
          {
            path: '',
            component: JobCreateComponent,
            canDeactivate: [LeaveGuard],
          },
        ],
      },
      {
        path: 'volunteer',
        children: [
          {
            path: ':id',
            component: JobEditComponent,
            canDeactivate: [LeaveGuard],
          },
          {
            path: '',
            component: JobCreateComponent,
            canDeactivate: [LeaveGuard],
          },
        ],
      },
      {
        path: 'internship',
        children: [
          {
            path: ':id',
            component: JobEditComponent,
            canDeactivate: [LeaveGuard],
          },
          {
            path: '',
            component: JobCreateComponent,
            canDeactivate: [LeaveGuard],
          },
        ],
      },
      {
        path: '',
        component: JobListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobRoutingModule {}
