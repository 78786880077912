<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Új kategória hozzáadása</h2>
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Kategória neve</mat-label>
      <input matInput [formControl]="controls.name" />
      <mat-hint align="end">{{ controls.name.value?.length || 0 }}/60</mat-hint>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      (click)="onCancelClicked()"
      type="button"
      color="primary"
      data-test="cancel"
    >
      Elvetés
    </button>
    <button mat-raised-button color="primary" data-test="submit">
      Létrehozás
    </button>
  </mat-dialog-actions>
</form>
