import '@angular/common/locales/global/hu';
import { LOCALE_ID, NgModule } from '@angular/core';
import {
  MatCheckboxDefaultOptions,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from '@angular/material/checkbox';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  MatSnackBarConfig,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  BASE_PATH,
  Configuration as BackendConfiguration,
} from '@otp-junior/admin-client';
import { ErrorMessageProvider, ModalModule } from '@web-admin/shared-lib';
import { AppComponent } from './app.component';
import { AppRouterModule } from './app.router.module';
import { AppConfig } from './config/app.config';
import { ConfigModule } from './config/config.module';
import { CoreModule } from './core/core.module';
import { OtpErrorMessageProviderService } from './core/otp-error-message-provider.service';
import { MockServiceWorkerService } from './mock/mock-worker.service';
import { AdminModule } from './modules/admin/admin.module';
import { AnalyticsModule } from './modules/analytics/analytics.module';
import { DeclarationModule } from './modules/declaration/declaration.module';
import { GameModule } from './modules/game/game.module';
import { JobModule } from './modules/job/job.module';
import { LexiconModule } from './modules/lexicon/lexicon.module';
import { LoginModule } from './modules/login/login.module';
import { NotificationModule } from './modules/notification/notification.module';
import { PostModule } from './modules/post/post.module';
import { QuizModule } from './modules/quiz/quiz.module';
import { SurveyModule } from './modules/survey/survey.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ConfigModule,
    CoreModule,
    BrowserModule,
    AppRouterModule,
    LoginModule,
    BrowserAnimationsModule,
    PostModule,
    NotificationModule,
    JobModule,
    AnalyticsModule,
    ModalModule,
    SurveyModule,
    QuizModule,
    DeclarationModule,
    AdminModule,
    GameModule,
    LexiconModule,
  ],
  providers: [
    MockServiceWorkerService,
    {
      provide: BASE_PATH,
      deps: [AppConfig],
      useFactory: (appConfig: AppConfig) =>
        appConfig.getConfig('backendBaseUrl'),
    },
    {
      provide: BackendConfiguration,
      deps: [AppConfig],
      useFactory: (appConfig: AppConfig) =>
        new BackendConfiguration({
          credentials: {
            mockAuth: 'Bearer',
          },
          basePath: appConfig.getConfig('backendBaseUrl'),
          withCredentials: true,
        }),
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        color: 'primary',
      } as MatCheckboxDefaultOptions,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 7000000,
      } as MatSnackBarConfig,
    },
    {
      provide: LOCALE_ID,
      useValue: 'hu',
    },
    {
      provide: ErrorMessageProvider,
      useClass: OtpErrorMessageProviderService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
