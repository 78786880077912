import { Injectable } from '@angular/core';
import { ErrorMessageProvider } from '@web-admin/shared-lib';
import { urlRegex } from '../utils/url-regex';

@Injectable()
export class OtpErrorMessageProviderService extends ErrorMessageProvider {
  public getErrorMessagesFor(errorType: string, errorValue: unknown): string {
    switch (errorType) {
      case 'required':
        return 'Kötelező mező';
      case 'minlength': {
        const { requiredLength, actualLength } = errorValue as {
          requiredLength: number;
          actualLength: number;
        };
        return `Minimum ${requiredLength} hosszúnak kell lennie. Jelenleg: ${actualLength}`;
      }
      case 'maxlength': {
        const { requiredLength, actualLength } = errorValue as {
          requiredLength: number;
          actualLength: number;
        };
        return `Maximum ${requiredLength} hosszúnak kell lennie. Jelenleg: ${actualLength}`;
      }
      case 'shouldBeAfter': {
        return `Ellenőrizd, hogy a kezdő dátum korábban legyen, mint a végdátum.`;
      }
      case 'pattern': {
        if (
          (errorValue as { requiredPattern: string }).requiredPattern ===
          urlRegex.toString()
        ) {
          return `Hibás url formátum.`;
        }
        return 'Hibás formátum';
      }
      default:
        return `Ismeretlen hiba (${errorType})`;
    }
  }
}
