import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DeleteInterceptor } from './delete.interceptor';
import { TabStorageService } from './storage.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    TabStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeleteInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
