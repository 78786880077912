<web-admin-edit-status-indicator
  [status]="status"
></web-admin-edit-status-indicator>
<form [formGroup]="form" (ngSubmit)="submit()">
  <h1>Esemény szerkesztése</h1>
  <web-admin-event-form [formControl]="control">
    <web-admin-post-edit-buttons
      [removable]="removable"
      [draft]="draft"
      (archive)="onArchiveClicked()"
      (delete)="onDeleteClicked()"
      (draftClicked)="onSaveAsDraftClicked()"
      in-bottom-card
    ></web-admin-post-edit-buttons>
  </web-admin-event-form>
</form>
