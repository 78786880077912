<form [formGroup]="formGroup">
  <shared-form-card class="campaign-form-card">
    <h2 class="campaign-settings">Kampány beállításai</h2>
    <mat-form-field class="campaign-name">
      <mat-label>Név</mat-label>
      <textarea
        matInput
        formControlName="name"
        maxlength="200"
        cdkTextareaAutosize
      ></textarea>
      <mat-error *showError></mat-error>
    </mat-form-field>
    <h2 class="campaign-publication-data">Publikálás adatai</h2>
    <div class="columns">
      <mat-form-field class="campaign-start">
        <mat-label>Kampány kezdete</mat-label>
        <date-time-input formControlName="startDate"></date-time-input>
        <mat-error *showError></mat-error>
      </mat-form-field>
      <mat-form-field class="campaign-end">
        <mat-label>Kampány vége</mat-label>
        <date-time-input formControlName="endDate"></date-time-input>
        <mat-error *showError></mat-error>
      </mat-form-field>
    </div>
  </shared-form-card>

  <shared-form-card
    class="campaign-form-card"
    *ngIf="!campaignEditMode && controls.lifeStages.value.length"
  >
    <h2>Életszakasz beállítása</h2>
    <shared-multi-input
      formControlName="lifeStages"
      [controlGenerator]="lifeStageControlGenerator"
    >
      <div
        class="columns"
        *sharedMultiInputTemplate="let control; let index = index"
        [formGroupPass]="control"
      >
        <mat-form-field>
          <mat-label>Életszakasz neve</mat-label>
          <input matInput formControlName="title" data-test="title" />
          <mat-error *showError></mat-error>
        </mat-form-field>
        <mat-form-field class="campaign-end">
          <mat-label>Életszakasz kezdete</mat-label>
          <date-time-input formControlName="scheduledAt"></date-time-input>
          <mat-error *showError></mat-error>
        </mat-form-field>
      </div>
    </shared-multi-input>
  </shared-form-card>
</form>
