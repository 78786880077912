import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FormCardModule } from '../form-card/form-card.module';
import { FilePickerComponent } from './file-picker/file-picker.component';

@NgModule({
  declarations: [FilePickerComponent],
  imports: [CommonModule, FormCardModule, MatButtonModule],
  exports: [FilePickerComponent],
})
export class FileUploaderModule {}
