<shared-form-card [formGroup]="form">
  <h2>Kutatás beállításai</h2>
  <mat-checkbox formControlName="marketingContent">
    Marketing tartalom
  </mat-checkbox>
  <div class="columns">
    <mat-form-field>
      <mat-label>Megjelenés kezdete</mat-label>
      <date-time-input formControlName="startDate"></date-time-input>
      <mat-error *showError></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Megjelenés vége</mat-label>
      <date-time-input formControlName="endDate"></date-time-input>
      <mat-error *showError></mat-error>
    </mat-form-field>
  </div>
</shared-form-card>

<shared-form-card class="full-width" [formGroup]="form">
  <h2>Bejegyzés</h2>
  <p class="hint">Bejegyzés képe</p>
  <web-admin-file-picker
    formControlName="imageId"
    [erasable]="false"
    titleText="Töltsd fel a kutatás indexképét"
  ></web-admin-file-picker>
  <mat-form-field>
    <mat-label>Kérdőív címe</mat-label>
    <textarea
      matInput
      formControlName="title"
      maxlength="60"
      cdkTextareaAutosize
    ></textarea>
    <mat-hint align="end"> {{ title?.value?.length || 0 }}/{{ 60 }} </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Kérdőív ajánló szövege</mat-label>
    <textarea
      matInput
      formControlName="description"
      maxlength="200"
      cdkTextareaAutosize
    ></textarea>
    <mat-hint align="end">
      {{ description?.value?.length || 0 }}/{{ 200 }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <h2 class="title-questions">Kérdések</h2>
  <ng-container
    formArrayName="steps"
    *ngFor="let questionForm of steps; let i = index"
  >
    <shared-form-card class="full-width inner-card" [formGroup]="questionForm">
      <div class="header">
        <h2>{{ i + 1 }}. kérdés</h2>
        <a
          mat-button
          type="button"
          color="warn"
          (click)="removeQuestion(i)"
          *ngIf="steps.length > 1"
        >
          <mat-icon>delete</mat-icon>
          Kérdés törlése
        </a>
      </div>
      <p class="hint">Kérdés képe <span>(nem kötelező)</span></p>
      <web-admin-file-picker
        formControlName="imageId"
        [erasable]="true"
        titleText="Töltsd fel a kérdés indexképét"
      ></web-admin-file-picker>
      <div class="question-type">
        <mat-form-field>
          <mat-label>Kérdés típusa</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of questionTypes" [value]="type.key">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox
          formControlName="multiple"
          *ngIf="questionForm.get('type').value === 'SINGLE'"
        >
          Több válasz is adható
        </mat-checkbox>
      </div>
      <mat-form-field>
        <mat-label>Kérdés szövege</mat-label>
        <textarea
          matInput
          formControlName="question"
          cdkTextareaAutosize
        ></textarea>
        <mat-error *showError></mat-error>
      </mat-form-field>
      <ng-container [ngSwitch]="questionForm.get('type').value">
        <ng-container *ngSwitchCase="'SLIDER'">
          <p class="hint scale">Állítsd be a csúszkán:</p>
          <strong>Legkevésbé - Nagyon</strong>
        </ng-container>
        <ng-container *ngSwitchCase="'SINGLE'">
          <ng-container
            formArrayName="multiple"
            *ngFor="let choices of getAnswers(questionForm); let j = index"
          >
            <div class="answer-box">
              <mat-form-field class="answer">
                <mat-label>Add meg az {{ j + 1 }}. válasz</mat-label>
                <textarea
                  matInput
                  [formControl]="choices"
                  maxlength="100"
                  cdkTextareaAutosize
                ></textarea>
                <mat-hint align="end">
                  {{ choices.value?.length || 0 }}/{{ 100 }}
                </mat-hint>
                <mat-error *showError></mat-error>
              </mat-form-field>
              <img
                src="../../../../assets/survey/x-delete.svg"
                alt="Válasz törlése"
                (click)="removeAnswer(i, j)"
              />
            </div>
          </ng-container>
          <mat-checkbox
            formControlName="none"
            *ngIf="
              questionForm.get('type').value === 'SINGLE' &&
              questionForm.get('multiple').value
            "
          >
            “Egyik sem” opció hozzáadása
          </mat-checkbox>
          <br />
          <a
            mat-button
            type="button"
            color="primary"
            class="add"
            (click)="addAnswer(i)"
            *ngIf="getAnswers(questionForm).length < 10"
          >
            <mat-icon>add</mat-icon>
            Másik válasz opció
          </a>
        </ng-container>
      </ng-container>
    </shared-form-card>
  </ng-container>
  <a
    mat-stroked-button
    type="button"
    color="primary"
    class="add"
    (click)="addQuestion()"
  >
    <mat-icon>add</mat-icon>
    Még egy kérdés hozzáadása
  </a>
</shared-form-card>

<shared-form-card class="full-width coupon" [formGroup]="form">
  <div class="coupon-header">
    <div>
      <h2>Kupon hozzáadása</h2>
      <span>(opcionális, a köszönő oldalon fog megjelenni)</span>
    </div>
    <button
      mat-flat-button
      class="delete-text"
      *ngIf="edit && couponId"
      (click)="couponDelete(); $event.preventDefault()"
    >
      <img alt="Delete-ikon" src="assets/icons/delete.svg" />
      Kupon törlése
    </button>
  </div>
  <web-admin-file-picker
    formControlName="couponImageId"
    [erasable]="true"
    titleText="Töltsd fel a kupon indexképét"
  ></web-admin-file-picker>
  <mat-form-field class="additional">
    <mat-label>Kupon leírása </mat-label>
    <textarea
      formControlName="couponDescription"
      matInput
      rows="11"
      cdkTextareaAutosize
    ></textarea>
  </mat-form-field>
  <div class="coupon-buttons">
    <div class="custom-file">
      <div
        *ngIf="couponCodesAlreadyUploaded$ | async"
        class="codes-already-uploaded"
      >
        Kupon kódok már fel lettek töltve.
      </div>
      <label class="custom-file-label" for="customFile">
        <img alt="Upload-XLS" src="assets/icons/xls.svg" />
        {{
          fileInputLabel ||
            ((couponCodesAlreadyUploaded$ | async)
              ? 'Kódok cseréje'
              : 'Kódok feltöltése')
        }}
        <input
          type="file"
          class="custom-file-input"
          id="customFile"
          name="myfile"
          (change)="onFileSelect($event)"
          #UploadFileInput
        />
      </label>
    </div>
    <button
      mat-raised-button
      color="primary"
      *ngIf="edit"
      (click)="this.saveCoupon(); $event.preventDefault()"
    >
      Kódok mentése
    </button>
  </div>
</shared-form-card>
