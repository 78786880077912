import { GameCardType } from '@otp-junior/admin-client';

export const mapGameCardType = (type: GameCardType): string => {
  switch (type) {
    case GameCardType.LtdExpense:
      return 'Hosszútávú_lakhatás';
    case GameCardType.Select:
      return 'Döntés kártya';
    case GameCardType.Multiselect:
      return 'Többelemű választás';
    case GameCardType.Education:
      return 'Edukációs kártya';
    case GameCardType.Surprise:
      return 'Meglepetés kártya';
    case GameCardType.Single:
      return 'Váratlan elem';
  }
};
