<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>OTP Junior</mat-card-title>
      <mat-card-subtitle>web admin</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input matInput type="email" [formControl]="controls.email" />
        <mat-error *showError></mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Jelszó</mat-label>
        <input matInput type="password" [formControl]="controls.password" />
        <mat-error *showError></mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-flat-button color="primary">Login</button>
    </mat-card-actions>
  </mat-card>
</form>
