<header>
  <h1>Analitika</h1>
  <div>
    <div class="box">
      Regisztrált felhasználók:
      <strong data-test="usersRegistered">
        {{ usersRegistered$ | async }} fő
      </strong>
    </div>
    <div class="box">
      Marketing nyilatkozatot elfogadók:
      <strong data-test="usersAcceptedMarketingConsent">
        {{ usersAcceptedMarketingConsent$ | async }} fő
      </strong>
    </div>
    <div class="box">
      Játékosok száma:
      <strong data-test="usersAcceptedMarketingConsent">
        {{ numberOfGamePlayers$ | async }} fő
      </strong>
    </div>
  </div>
</header>
<div class="nav">
  <nav mat-tab-nav-bar>
    <a
      *ngFor="let tab of tabs"
      mat-tab-link
      [routerLink]="tab.route"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ tab.title }}
    </a>
  </nav>
  <web-admin-export-button
    (exportLoading)="onExportLoading($event)"
    [campaignId]="campaignId$ | async"
  ></web-admin-export-button>
</div>
<div class="loading" *ngIf="exportLoading">
  <mat-spinner></mat-spinner>
</div>
<router-outlet></router-outlet>
