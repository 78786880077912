/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectorRef,
  Directive,
  OnInit,
  Optional,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { merge } from 'rxjs';
import { ErrorMessageProvider } from './error-message-provider';

@Directive({
  selector: '[showError]',
})
export class ShowErrorDirective implements OnInit {
  private text: Text;
  public constructor(
    private readonly templateReference: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly renderer: Renderer2,
    private readonly matFormField: MatFormField,
    private readonly errorMessageProvider: ErrorMessageProvider,
    @Optional() private readonly ngForm: NgForm,
    @Optional() private readonly formGroupDirective: FormGroupDirective,
    private readonly changeDetectorReference: ChangeDetectorRef
  ) {}
  public ngOnInit(): void {
    const matFormFieldControl = this.matFormField._control;
    const ngControl = matFormFieldControl.ngControl;
    const observables = [matFormFieldControl.stateChanges];
    if (this.ngForm) {
      observables.push(this.ngForm.ngSubmit);
    }
    if (this.formGroupDirective) {
      observables.push(this.formGroupDirective.ngSubmit);
    }
    merge(...observables).subscribe(() => {
      if (matFormFieldControl.errorState && ngControl.errors) {
        const errorKeys = Object.keys(ngControl.errors);
        if (!this.text && errorKeys.length > 0) {
          this.viewContainer.createEmbeddedView(this.templateReference);
          const elementReference = (this.viewContainer.get(0) as any)
            .rootNodes[0];
          this.text = this.renderer.createText('');
          this.renderer.appendChild(elementReference, this.text);
        }
        const firstKey = errorKeys[0];
        const nextTextContent = this.errorMessageProvider.getErrorMessagesFor(
          firstKey,
          ngControl.errors[firstKey]
        );
        if (nextTextContent !== this.text.textContent) {
          this.text.textContent = nextTextContent;
          this.changeDetectorReference.detectChanges();
        }
        return;
      }
      if (this.text) {
        this.viewContainer.clear();
        this.text = undefined;
        this.changeDetectorReference.detectChanges();
      }
    });
  }
}
