import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'web-admin-post-category-title',
  templateUrl: './post-category-title.component.html',
  styleUrls: ['./post-category-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCategoryTitleComponent {
  @Input() categoryTitle: string;
}
