<mat-drawer-container>
  <mat-drawer #drawer mode="over">
    <mat-nav-list>
      <mat-list-item *ngFor="let link of links" [routerLink]="link.link">
        <mat-icon mat-list-icon>{{ link.icon }}</mat-icon>
        <a matLine>{{ link.name }}</a>
      </mat-list-item>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <mat-toolbar
      color="primary"
      [style.background-color]="navbarBackgroundColor"
    >
      <button
        *ngIf="isSmallScreen$ | async"
        mat-icon-button
        aria-label="Menü gomb ikonnal"
        (click)="drawer.toggle()"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <img src="assets/OTPJunior-Logo.svg" alt="OTP Junior Logo" />
      <ng-container *ngIf="(isSmallScreen$ | async) === false">
        <a *ngFor="let link of links" mat-button [routerLink]="link.link">
          {{ link.name }}
        </a>
      </ng-container>
      <span class="spacer"></span>
      <span class="name">{{ name$ | async }}</span>
    </mat-toolbar>
    <main>
      <router-outlet class="router-outlet"></router-outlet>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
