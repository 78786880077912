<h1>Nyilatkozatok</h1>
<div class="filters">
  <mat-form-field appearance="standard">
    <mat-select [formControl]="filterControl">
      <mat-option [value]="declarationListType.Empty" data-test="all"
        >Összes típus</mat-option
      >
      <mat-option *ngFor="let type of types" [value]="type">
        {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button color="primary" (click)="onNewDeclarationClick()">
    Új nyilatkozat
  </button>
</div>
<ng-container *ngIf="(loading$ | async) === false">
  <ng-container *ngIf="list$ | async as list">
    <ng-container *ngIf="list.length > 0; else notFound">
      <table aria-label="Nyilatkozatok lista" mat-table [dataSource]="list">
        <ng-container matColumnDef="type">
          <th id="type-header" mat-header-cell *matHeaderCellDef>Típus</th>
          <td mat-cell *matCellDef="let element">
            <strong>{{ element.type }}</strong>
          </td>
        </ng-container>
        <ng-container matColumnDef="availabilityStartDate">
          <th
            id="availability-start-date-header"
            mat-header-cell
            *matHeaderCellDef
          >
            Érvényesség kezdete
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.availabilityStartDate">
              <strong>{{
                element.availabilityStartDate | date: 'short'
              }}</strong
              >-tól
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th id="status-header" mat-header-cell *matHeaderCellDef>Státusz</th>
          <td mat-cell *matCellDef="let element">
            <div
              *ngIf="element.status === declarationListStatus.Timed"
              class="status-cell"
            >
              <img
                src="assets/declaration/status-icon/scheduled.svg"
                alt="Időzített icon"
              />
              {{ element.status }}
            </div>
            <div
              *ngIf="element.status === declarationListStatus.Active"
              class="status-cell"
            >
              <img
                src="assets/declaration/status-icon/active.svg"
                alt="Aktív icon"
              />
              {{ element.status }}
            </div>
            <ng-container
              *ngIf="element.status === declarationListStatus.Empty"
            >
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="download">
          <th id="download-header" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <a
              mat-button
              color="primary"
              (click)="
                onDownloadClick(
                  element.url,
                  element.type,
                  element.availabilityStartDate
                )
              "
            >
              Letöltés
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th id="delete-header" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <a
              *ngIf="element.status === declarationListStatus.Timed"
              mat-button
              color="error"
              (click)="onDeleteClick(element.id)"
            >
              <img src="assets/icons/delete.svg" alt="Törlés icon" />
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns$ | async"
          [class.archive]="row.status === 'ARCHIVE'"
        ></tr>
      </table>
      <mat-paginator
        [pageIndex]="pageNumber$ | async"
        [length]="count$ | async"
        [pageSize]="10"
        [pageSizeOptions]="[10]"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #notFound>
  <shared-list-empty> Nincsenek nyilatkozatok. </shared-list-empty>
</ng-template>
