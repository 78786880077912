import { Injectable } from '@angular/core';
import {
  JobDataService,
  JobFilter,
  JobStatus,
  JobSummaryDto,
} from '@otp-junior/admin-client';
import { ListService } from '@web-admin/shared-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobListService {
  public items$: Observable<JobSummaryDto[]>;
  public totalElements$: Observable<number>;
  public loading$: Observable<boolean>;
  public pageNumber$: Observable<number>;
  private readonly listService: ListService<JobFilter, JobSummaryDto>;

  public constructor(private readonly dataService: JobDataService) {
    this.listService = new ListService<JobFilter, JobSummaryDto>(
      {
        status: JobStatus.Active,
      },
      (filter, page) =>
        this.dataService.searchJobs({
          filter,
          page,
          pageSize: 10,
        })
    );
    this.items$ = this.listService.items$;
    this.totalElements$ = this.listService.totalElements$;
    this.loading$ = this.listService.loading$;
    this.pageNumber$ = this.listService.pageNumber$;
  }

  public setFilter(filter: JobFilter): void {
    this.listService.setFilter(filter);
  }

  public setPage(page: number): void {
    this.listService.setPage(page);
  }
}
