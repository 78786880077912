import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewsFeedEntryType } from '@otp-junior/admin-client';
import { AuthGuard } from '../../core/auth.guard';
import { PageLayoutComponent } from '../layout/page-layout/page-layout.component';
import { GameAnalyticsListComponent } from './game-analytics-list/game-analytics-list.component';
import { GameAnalyticsType } from './game-analytics-type.enum';
import { AnalyticsListComponent } from './list/list.component';
import { AnalyticsTabsComponent } from './tabs/tabs.component';

const routes: Routes = [
  {
    path: 'analytics',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: PageLayoutComponent,
    children: [
      {
        path: '',
        component: AnalyticsTabsComponent,
        children: [
          {
            path: 'article',
            component: AnalyticsListComponent,
            data: {
              type: NewsFeedEntryType.Article,
            },
          },
          {
            path: 'video',
            component: AnalyticsListComponent,
            data: {
              type: NewsFeedEntryType.Video,
            },
          },
          {
            path: 'vote',
            component: AnalyticsListComponent,
            data: {
              type: NewsFeedEntryType.Vote,
            },
          },
          {
            path: 'event',
            component: AnalyticsListComponent,
            data: {
              type: NewsFeedEntryType.Event,
            },
          },
          {
            path: 'discount',
            component: AnalyticsListComponent,
            data: {
              type: NewsFeedEntryType.Discount,
            },
          },
          {
            path: 'survey',
            component: AnalyticsListComponent,
            data: {
              type: NewsFeedEntryType.Survey,
            },
          },
          {
            path: 'quiz',
            component: AnalyticsListComponent,
            data: {
              type: NewsFeedEntryType.Quiz,
            },
          },
          {
            path: 'game',
            component: GameAnalyticsListComponent,
            data: {
              type: GameAnalyticsType.Game,
            },
          },
          {
            path: 'game_players',
            component: GameAnalyticsListComponent,
            data: {
              type: GameAnalyticsType.GamePlayers,
            },
          },
          {
            path: 'game_quiz',
            component: GameAnalyticsListComponent,
            data: {
              type: GameAnalyticsType.GameQuiz,
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnalyticsRoutingModule {}
