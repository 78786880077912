<div class="main">
  <h3 class="title">Eredmények összegzése</h3>

  <div class="box" *ngFor="let item of detailsData; let i = index">
    <div class="question">
      <h4>{{ i + 1 }}. {{ item.question }}</h4>
    </div>

    <div class="iterate-box">
      <div class="box-left">
        <p class="survey-question" *ngFor="let steps of item.choices">
          {{ steps.answer }} ( {{ steps.responsePercentage }}% )
        </p>
      </div>
      <div class="box-right">
        <div *ngFor="let steps of item.choices" class="right-progress-box">
          <mat-progress-bar
            [value]="steps.responsePercentage"
            mode="determinate"
          ></mat-progress-bar>
        </div>
      </div>
    </div>
  </div>

  <div *ngFor="let items of freeTextSteps; let i = index" class="freetext">
    <div class="question">
      <h4>{{ i + 1 }}. {{ items.question }}</h4>
    </div>
    <div class="scrollbar-helper-div">
      <div class="messages">
        <div class="individual-msg" *ngFor="let item of items.choices">
          <div class="img-div">
            <img
              src="../../../../assets/survey/message_bubbles.svg"
              alt="message_bubbles"
            />
          </div>
          <p class="msg-description">
            {{ item.answer }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
