import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminDataService, AdminLog } from '@otp-junior/admin-client';
import { ModalService } from '@web-admin/shared-lib';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'web-admin-admin-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminListComponent implements OnInit, OnDestroy {
  public items$: Observable<AdminLog[]>;
  public readonly displayedColumns: string[] = [
    'email',
    'lastSuccessfulLogin',
    'lastUnsuccessfulLogin',
    'activate',
  ];
  private subscription: Subscription;

  constructor(
    private readonly dataService: AdminDataService,
    private readonly modalService: ModalService,
    private readonly snackBar: MatSnackBar,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.items$ = this.dataService
      .getExpiredAdmins()
      .pipe(catchError((error) => this.handleError(error)));
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  public onActivateClicked(admin: AdminLog): void {
    this.subscription = this.modalService
      .openConfirmModal({
        title: `Aktiválás: ${admin.email}`,
        description: 'Biztosan aktiválod ezt az adminisztrátort?',
        confirmText: 'Igen',
      })
      .afterClosed()
      .pipe(
        filter((x) => !!x),
        switchMap(() => this.dataService.removeExpirationFromAdmin(admin.id)),
        tap((response) => {
          if (response === null) {
            this.snackBar.open('Sikeres aktiválás.', 'OK', {
              panelClass: ['snack-bar-container', 'success'],
              duration: 3000,
            });
          }
        }),
        switchMap(() => this.dataService.getExpiredAdmins()),
        catchError((error) => this.handleError(error))
      )
      .subscribe((items) => {
        if (!(items instanceof HttpErrorResponse)) {
          this.items$ = of(items);
          this.cd.detectChanges();
        }
      });
  }

  private handleError<T>(error: T): Observable<T> {
    this.snackBar.open('Hiba történt. Próbáld újra!', 'OK', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 30000,
    });
    return of(error);
  }
}
