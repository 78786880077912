import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import {
  AnalyticsDataService,
  NewsFeedEntryStatistics,
  NewsFeedEntryStatisticsFilterSort,
  NewsFeedEntryType,
  NewsFeedStatisticsFilter,
} from '@otp-junior/admin-client';
import {
  ListService,
  mapMaterialSortByToSortBy,
  mapMaterialSortDirectionToSortDirection,
  SortDirection,
} from '@web-admin/shared-lib';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TabStorageService } from '../../../core/storage.service';
import { CurrentTypeService } from '../current-type.service';

@Component({
  selector: 'web-admin-analytics-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrentTypeService],
})
export class AnalyticsListComponent implements OnInit, OnDestroy {
  public sortArguments$: BehaviorSubject<{
    sortBy?: string;
    sortDirection?: SortDirection;
  }> = new BehaviorSubject(undefined);
  private readonly destroy$ = new Subject<void>();

  public displayedColumns: string[] = [];
  public listService: ListService<
    NewsFeedStatisticsFilter,
    NewsFeedEntryStatistics
  >;
  public constructor(
    private readonly analyticsDataService: AnalyticsDataService,
    private readonly currentTypeService: CurrentTypeService,
    private readonly router: Router,
    private readonly storageService: TabStorageService
  ) {
    const type: NewsFeedEntryType = currentTypeService.type;
    this.setDisplayedColumns(type);
    this.listService = new ListService<
      NewsFeedStatisticsFilter,
      NewsFeedEntryStatistics
    >(
      {
        type,
      },
      (filter, page, sortBy, sortDirection) => {
        return this.analyticsDataService.filterNewsFeedEntryStatistics(
          {
            filter,
            page: page ?? 0,
            pageSize: 10,
          },
          sortBy,
          sortDirection
        );
      }
    );
  }

  public ngOnInit(): void {
    const previousTabData = this.storageService.getTabData();
    const sortingRequest: { [key: string]: string } = {
      sortBy: previousTabData?.sortingRequest?.sortBy,
      sortDirection: previousTabData?.sortingRequest?.sortDirection,
    };
    this.currentTypeService.type$
      .pipe(takeUntil(this.destroy$))
      .subscribe((type) => {
        if (previousTabData?.type !== type) {
          this.storageService.storeTabData({ tab: type.toLowerCase(), type });
          this.setDisplayedColumns(type);
          this.listService.setFilter({ type });
          this.listService.setPage(0);
        } else {
          this.listService.setFilter({ type });
          this.listService.setPage(previousTabData.page ?? 0);
          this.sortArguments$.next(sortingRequest);
          this.listService.setSort({
            ...sortingRequest,
            sortBy: mapMaterialSortByToSortBy(
              sortingRequest.sortBy
            ) as NewsFeedEntryStatisticsFilterSort,
          });
        }
      });
  }

  public onPageChange(event: PageEvent): void {
    this.storageService.storeTabData({
      tab: this.currentTypeService.type.toLowerCase(),
      type: this.currentTypeService.type,
      page: event.pageIndex,
    });
    this.listService.setPage(event.pageIndex);
  }

  public routeToResult(id: number, type: string): void {
    type == 'SurveyStatisticsDto' ? (type = 'survey') : (type = 'quiz');
    if (type == 'survey') {
      this.router.navigate([`/${type}/result/${id}`], { replaceUrl: true });
    } else if (type == 'quiz') {
      this.router.navigate([`/${type}/result/${type}/${id}`], {
        replaceUrl: true,
      });
    }
  }

  private setDisplayedColumns(type: NewsFeedEntryType) {
    switch (type) {
      case NewsFeedEntryType.Article:
        return (this.displayedColumns = [
          'title',
          'publishDate',
          'viewCount',
          'read75PercentCount',
          'saveCount',
          'likeCount',
          'heartCount',
          'dislikeCount',
        ]);
      case NewsFeedEntryType.Video:
        return (this.displayedColumns = [
          'title',
          'publishDate',
          'playCount',
          'saveCount',
          'likeCount',
          'heartCount',
          'dislikeCount',
        ]);
      case NewsFeedEntryType.Vote:
        return (this.displayedColumns = [
          'title',
          'publishDate',
          'voteCountOptionA',
          'voteCountOptionB',
          'voteCount',
        ]);
      case NewsFeedEntryType.Event:
        return (this.displayedColumns = [
          'title',
          'publishDate',
          'attendeeCount',
          'saveCount',
          'likeCount',
          'heartCount',
          'dislikeCount',
        ]);
      case NewsFeedEntryType.Discount:
        return (this.displayedColumns = [
          'title',
          'publishDate',
          'viewCount',
          'likeCount',
          'heartCount',
          'dislikeCount',
        ]);
      case NewsFeedEntryType.Survey:
        return (this.displayedColumns = [
          'title',
          'publishDate',
          'viewCountSurvey',
          'filled75PercentCount',
          'results',
        ]);
      case NewsFeedEntryType.Quiz:
        return (this.displayedColumns = [
          'title',
          'publishDate',
          'viewCountQuiz',
          'filledAllTheWay',
          'results',
        ]);
      case NewsFeedEntryType.GameQuiz:
        return (this.displayedColumns = [
          'playerName',
          'playerEmail',
          'totalPoints',
          'stage',
          'quizAnswers',
          'quizTime',
          'numberOfInvitations',
        ]);
    }
  }

  public onSortChange(sort: Sort): void {
    const sortBy = sort.direction ? sort.active : undefined;
    const sortDirection = mapMaterialSortDirectionToSortDirection(
      sort.direction
    );
    this.sortArguments$.next({ sortBy, sortDirection });
    this.storageService.storeTabData({
      tab: this.currentTypeService.type.toLowerCase(),
      type: this.currentTypeService.type,
      sortingRequest: {
        sortBy,
        sortDirection,
      },
    });

    this.listService.setSort({
      sortBy: mapMaterialSortByToSortBy(
        sortBy
      ) as NewsFeedEntryStatisticsFilterSort,
      sortDirection,
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
