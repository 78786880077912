import { Directive, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  NewsFeedEntry,
  NewsFeedEntryStatus,
  PostDataService,
} from '@otp-junior/admin-client';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

const isOfType = (objectType: NewsFeedEntry['objectType']) => <
  T extends NewsFeedEntry
>(
  data: NewsFeedEntry
): data is T => data.objectType === objectType;

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractEdit<TEntry extends NewsFeedEntry>
  implements OnInit, OnDestroy {
  protected abstract objectType: TEntry['objectType'];
  public control = new FormControl();
  public form = new FormGroup({ data: this.control });
  public status: NewsFeedEntryStatus;
  public removable: boolean;
  public draft: boolean;
  public id: number;
  private readonly subscription = new Subscription();

  public constructor(
    protected readonly postDataService: PostDataService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.activatedRoute.paramMap
        .pipe(
          map((parameterMap) => parameterMap.get('id')),
          filter((id) => !!id),
          switchMap((id) => this.postDataService.getNewsFeedEntryById(+id)),
          filter<NewsFeedEntry, TEntry>(isOfType(this.objectType))
        )
        .subscribe((data) => {
          this.id = data.id;
          this.status = data.status;
          this.draft = data.status === NewsFeedEntryStatus.Draft;
          this.removable =
            data.status === NewsFeedEntryStatus.Archive || this.draft;
          this.form.setValue({
            data,
          });
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  public submit(): void {
    const data = this.form.value.data;
    if (!data || this.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }

    this.sendEdit(data).subscribe(() => this.form.markAsPristine());
  }

  public onSaveAsDraftClicked(): void {
    const data = this.form.value.data;
    this.sendEdit(data, true).subscribe(() => this.form.markAsPristine());
  }

  public onArchiveClicked(): void {
    this.postDataService
      .updateStatus(this.id, {
        status: NewsFeedEntryStatus.Archive,
      })
      .subscribe(() => this.form.markAsPristine());
  }

  public onDeleteClicked(): void {
    this.postDataService.deleteNewsFeedEntry(this.id).subscribe();
  }

  public sendEdit(data: TEntry, draft = false): Observable<NewsFeedEntry> {
    return this.postDataService.updateNewsFeedEntry(this.id, {
      objectType: this.objectType,
      draft,
      // readonly should be sent as null
      /* eslint-disable unicorn/no-null */
      type: null,
      id: null,
      status: null,
      /* eslint-enable unicorn/no-null */
      ...data,
    });
  }
}
