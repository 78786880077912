<ng-container [ngSwitch]="status">
  <div *ngSwitchCase="NewsFeedEntryStatus.Active" class="active">
    <strong>Ez a bejegyzés AKTÍV.</strong> Szerkesztésed mentés után megjelenik
    az éles oldalon.
  </div>
  <div *ngSwitchCase="NewsFeedEntryStatus.Archive" class="archive">
    <strong>Ez a bejegyzés ARCHIVÁLT.</strong>
  </div>
  <div *ngSwitchCase="NewsFeedEntryStatus.Archive" class="draft">
    <strong>Ez a bejegyzés PISZKOZAT.</strong> Szerkesztheted és publikálhatod.
  </div>
  <div *ngSwitchCase="NewsFeedEntryStatus.Scheduled" class="scheduled">
    <strong>Ez a hirdetés IDŐZÍTETT.</strong> Szerkesztheted.
  </div>
</ng-container>
