<table
  mat-table
  matSort
  [matSortInitialState]="sortArguments$ | async"
  (matSortChange)="onSortChange($event)"
  [dataSource]="listService.items$"
  aria-label="Analytics table"
>
  <ng-container matColumnDef="title">
    <th mat-header-cell id="headerCell-title" *matHeaderCellDef mat-sort-header>
      Cím
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.title }}
    </td>
  </ng-container>
  <ng-container matColumnDef="publishDate">
    <th
      mat-header-cell
      id="headerCell-publish"
      *matHeaderCellDef
      mat-sort-header
    >
      Publikálás ideje
    </th>
    <td mat-cell *matCellDef="let element">
      <strong>{{ element.publishDate | date: 'short' }}</strong
      >-tól
    </td>
  </ng-container>
  <ng-container matColumnDef="viewCount">
    <th mat-header-cell id="headerCell-view" *matHeaderCellDef>Megnyitva</th>
    <td mat-cell *matCellDef="let element">
      {{ element.viewCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="playCount">
    <th mat-header-cell id="headerCell-play" *matHeaderCellDef>Elindítva</th>
    <td mat-cell *matCellDef="let element">
      {{ element.viewCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="attendeeCount">
    <th mat-header-cell id="headerCell-attendee" *matHeaderCellDef>
      Jelentkezők száma
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.attendeeCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="voteCount">
    <th mat-header-cell id="headerCell-vote" *matHeaderCellDef>
      Összes szavazat
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.voteCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="voteCountOptionA">
    <th mat-header-cell id="headerCell-voteA" *matHeaderCellDef>
      “A” válaszra
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.voteCountOptionA }}
    </td>
  </ng-container>
  <ng-container matColumnDef="voteCountOptionB">
    <th mat-header-cell id="headerCell-voteB" *matHeaderCellDef>
      “B” válaszra
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.voteCountOptionB }}
    </td>
  </ng-container>
  <ng-container matColumnDef="read75PercentCount">
    <th mat-header-cell id="headerCell-read75" *matHeaderCellDef>
      75%-ig olvasott
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.read75PercentCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="viewCountSurvey">
    <th mat-header-cell id="headerCell-filled75" *matHeaderCellDef>Elkezdve</th>
    <td mat-cell *matCellDef="let element">
      {{ element.viewCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="filledAllTheWay">
    <th mat-header-cell id="headerCell-filledAllTheWay" *matHeaderCellDef>
      Kitöltve
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.filledCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="viewCountQuiz">
    <th mat-header-cell id="headerCell-viewCountQuiz" *matHeaderCellDef>
      Elkezdve
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.viewCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="filled75PercentCount">
    <th mat-header-cell id="headerCell-filled75PercentCount" *matHeaderCellDef>
      Kitöltve
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.filled75PercentCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="saveCount">
    <th mat-header-cell id="headerCell-save" *matHeaderCellDef>Mentve</th>
    <td mat-cell *matCellDef="let element">
      {{ element.saveCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="results">
    <th mat-header-cell id="headerCell-result" *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="right">
      <a
        mat-button
        color="primary"
        (click)="routeToResult(element.postId, element.objectType)"
      >
        <img src="assets/survey/green-draft.svg" alt="Eredmények icon" />
        Eredmények
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="likeCount">
    <th mat-header-cell id="headerCell-like" *matHeaderCellDef>
      <img src="assets/icons/like.svg" alt="Lájkok" />
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.likeCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="heartCount">
    <th mat-header-cell id="headerCell-heart" *matHeaderCellDef>
      <img src="assets/icons/heart.svg" alt="Szivek" />
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.heartCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dislikeCount">
    <th mat-header-cell id="headerCell-dislike" *matHeaderCellDef>
      <img src="assets/icons/dislike.svg" alt="Dislájkok" />
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.dislikeCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="playerName">
    <th mat-header-cell id="headerCell-playerName" *matHeaderCellDef>
      Játékos neve
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.playerName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="playerEmail">
    <th mat-header-cell id="headerCell-playerEmail" *matHeaderCellDef>
      Játékos email címe
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.playerEmail }}
    </td>
  </ng-container>
  <ng-container matColumnDef="totalPoints">
    <th mat-header-cell id="headerCell-totalPoints" *matHeaderCellDef>
      Összpontszám
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.totalPoints }}
    </td>
  </ng-container>
  <ng-container matColumnDef="stage">
    <th mat-header-cell id="headerCell-stage" *matHeaderCellDef>Pálya</th>
    <td mat-cell *matCellDef="let element">
      {{ element.stage }}
    </td>
  </ng-container>
  <ng-container matColumnDef="quizAnswers">
    <th mat-header-cell id="headerCell-quizAnswers" *matHeaderCellDef>
      Kvíz helyes válaszok
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.quizAnswers }}
    </td>
  </ng-container>
  <ng-container matColumnDef="quizTime">
    <th mat-header-cell id="headerCell-quizTime" *matHeaderCellDef>Kvíz idő</th>
    <td mat-cell *matCellDef="let element">
      {{ element.quizTime }}
    </td>
  </ng-container>
  <ng-container matColumnDef="numberOfInvitations">
    <th mat-header-cell id="headerCell-numberOfInvitations" *matHeaderCellDef>
      Küldött meghívó
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.numberOfInvitations }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator
  [length]="listService.totalElements$ | async"
  [pageSize]="10"
  [pageSizeOptions]="[10]"
  [pageIndex]="listService.pageNumber$ | async"
  (page)="onPageChange($event)"
></mat-paginator>
