import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import {
  DateTimeInputModule,
  FileUploaderModule,
  FilterPickerModule,
  FormCardModule,
  ListEmptyModule,
  MaterialErrorModule,
  MatTabFormModule,
  MultiInputModule,
} from '@web-admin/shared-lib';
import { CreateCampaignComponent } from './campaign/create/create.component';
import { EditLifestageComponent } from './campaign/edit-lifestage/edit-lifestage.component';
import { EditCampaignComponent } from './campaign/edit/edit.component';
import { CampaignFormComponent } from './campaign/form/form.component';
import { GameRoutingModule } from './game-routing.module';
import { CreateInterceptor } from './interceptors/create.interceptor';
import { EditInterceptor } from './interceptors/edit.interceptor';
import { CampaignListComponent } from './list/campaign-list/campaign-list.component';
import { GameQuizListComponent } from './list/game-quiz-list/game-quiz-list.component';
import { LifeStageListComponent } from './list/life-stage-list/life-stage-list.component';
import { GameListFilterComponent } from './list/list-filter/list-filter.component';
import { GameListComponent } from './list/list.component';
import { CreateGameQuizComponent } from './quiz/create/create.component';
import { EditGameQuizComponent } from './quiz/edit/edit.component';
import { GameQuizFormComponent } from './quiz/form/form.component';
import { ResultComponent } from './result/result.component';
import { CampaignService } from './services/campaign.service';
import { GameQuizService } from './services/game-quiz.service';

@NgModule({
  declarations: [
    GameListComponent,
    GameListFilterComponent,
    CampaignListComponent,
    GameQuizListComponent,
    CreateCampaignComponent,
    CampaignFormComponent,
    EditCampaignComponent,
    CreateGameQuizComponent,
    GameQuizFormComponent,
    EditGameQuizComponent,
    EditLifestageComponent,
    LifeStageListComponent,
    ResultComponent,
  ],
  imports: [
    CommonModule,
    GameRoutingModule,
    MatPaginatorModule,
    ListEmptyModule,
    MatTableModule,
    MatButtonModule,
    MatSelectModule,
    MatButtonModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatTabFormModule,
    MatFormFieldModule,
    MatIconModule,
    DateTimeInputModule,
    FileUploaderModule,
    MatInputModule,
    MatCheckboxModule,
    FormCardModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    FilterPickerModule,
    MultiInputModule,
    MaterialErrorModule,
  ],
  providers: [
    GameQuizService,
    CampaignService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CreateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EditInterceptor,
      multi: true,
    },
  ],
})
export class GameModule {}
