export interface LexiconListVM {
  totalElements: number;
  items: LexiconVM[];
}

export interface LexiconVM {
  id: number;
  word: string;
  date: string;
  associatedContentCount: number;
  viewCount: number;
  status: LexiconWordStatus;
}

export enum LexiconWordStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
}
