<button
  mat-flat-button
  class="export-button"
  color="secondary"
  mat-stroked-button
  type="button"
  (click)="onExportClicked()"
>
  <img alt="Export-XLS" src="../../../../assets/icons/xls.svg" />
  <strong>Export XLS</strong>
</button>
