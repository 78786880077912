import { Component, Input } from '@angular/core';
import { NotificationStatus } from '@otp-junior/admin-client';

@Component({
  selector: 'web-admin-notification-status-icon',
  templateUrl: './notification-status-icon.component.html',
  styleUrls: ['./notification-status-icon.component.scss'],
})
export class NotificationStatusIconComponent {
  @Input() status: NotificationStatus;
  public srcMap: Record<NotificationStatus, string> = {
    PENDING: 'assets/notification/status-icon/scheduled.svg',
    SCHEDULED: 'assets/notification/status-icon/scheduled.svg',
    SENT: 'assets/notification/status-icon/sent.svg',
    FAILED: 'assets/notification/status-icon/failed.svg',
  };
  public textMap: Record<NotificationStatus, string> = {
    PENDING: 'Időzített',
    SCHEDULED: 'Időzített',
    SENT: 'Kiküldve',
    FAILED: 'Hiba',
  };
}
