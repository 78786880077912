<form [formGroup]="form" (ngSubmit)="submit()">
  <h1>Push üzenet szerkesztése</h1>
  <web-admin-notification-form [formControl]="control" (switchLinkToCreate)="createSwitchLink = ($event)">
    <div in-bottom-card>
      <div>
        <a
          mat-stroked-button
          type="button"
          data-test="discard"
          color="primary"
          routerLink="/notification"
        >
          Elvetés
        </a>
        <button
          *ngIf="removable"
          mat-stroked-button
          type="button"
          data-test="delete"
          color="warn"
          (click)="onDeleteClicked()"
        >
          Végleges törlés
        </button>
      </div>
      <button mat-raised-button data-test="submit" color="primary">
        Módosítás mentése
      </button>
    </div>
  </web-admin-notification-form>
</form>
