export interface DeclarationListElementVM {
  type: DeclarationListType;
  id: number;
  availabilityStartDate: string;
  status: DeclarationListStatus;
  url: string;
}

export enum DeclarationListType {
  Marketing = 'Marketing',
  Contract = 'Általános Szerződési Feltételek',
  Participation = 'Részvételi szabályzat',
  GameRules = 'Játékszabályzat',
  Empty = '',
}

export enum DeclarationListStatus {
  Timed = 'Időzített',
  Active = 'Aktív',
  Empty = '',
}
