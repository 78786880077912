<h1>Push üzenetek</h1>
<web-admin-notification-list-filter [formControl]="filterControl">
  <a mat-flat-button color="primary" routerLink="create" data-test="create">
    <img src="assets/icons/plus.svg" alt="" /> Új push üzenet
  </a>
</web-admin-notification-list-filter>
<ng-container *ngIf="(loading$ | async) === false">
  <ng-container *ngIf="list$ | async as list">
    <ng-container *ngIf="list.length > 0; else notFound">
      <table aria-label="Push üzenet lista" mat-table [dataSource]="list">
        <ng-container matColumnDef="title">
          <th id="title-header" mat-header-cell *matHeaderCellDef>Cím</th>
          <td mat-cell *matCellDef="let element">
            <strong>{{ element.title }}</strong>
          </td>
        </ng-container>
        <ng-container matColumnDef="message">
          <th id="message-header" mat-header-cell *matHeaderCellDef>Szöveg</th>
          <td mat-cell *matCellDef="let element">
            <strong>{{ element.message }}</strong>
          </td>
        </ng-container>
        <ng-container matColumnDef="scheduledAt">
          <th id="scheduled-header" mat-header-cell *matHeaderCellDef>Küldés ideje</th>
          <td mat-cell *matCellDef="let element">
            {{ element.scheduledAt | date: 'short' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th id="status-header" mat-header-cell *matHeaderCellDef class="right">Státusz</th>
          <td mat-cell *matCellDef="let element">
            <web-admin-notification-status-icon
              [status]="element.status"
            ></web-admin-notification-status-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th id="edit-header" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="right">
            <a
              mat-button
              color="primary"
              [routerLink]="element.id"
              *ngIf="element.status !== 'SENT'; else deleteButton"
            >
              <img src="assets/icons/edit.svg" alt="Szerkesztés icon"/>
              Szerkesztés
            </a>
            <ng-template #deleteButton>
              <button
                mat-button
                color="warn"
                (click)="onDeleteClicked(element)"
                data-test="delete"
              >
                Törlés
              </button>
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageIndex]="pageNumber$ | async"
        [length]="count$ | async"
        [pageSize]="10"
        [pageSizeOptions]="[10]"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #notFound>
  <shared-list-empty>
    Nincsenek értesítések ebben a státuszban.
  </shared-list-empty>
</ng-template>
