import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalData } from './confirm-modal/confirm-modal-data';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public constructor(public dialog: MatDialog) {}

  public openConfirmModal(
    data: ConfirmModalData
  ): MatDialogRef<ConfirmModalComponent, boolean> {
    return this.dialog.open(ConfirmModalComponent, {
      data,
    });
  }
}
