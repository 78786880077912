import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

export interface ChoiceStatisticsDt {
  answer: string;
  responseCount?: number;
  responsePercentage?: number;
}

export interface detailsDataStructure {
  choices: ChoiceStatisticsDt[];
  question: string;
  responseCount: number;
  stepType: string;
}

@Component({
  selector: 'web-admin-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyDetailsComponent implements OnInit {
  @Input() public detailsData: Array<detailsDataStructure>;
  public freeTextSteps: Array<detailsDataStructure>;

  constructor() {
    this.freeTextSteps = [];
  }
  ngOnInit(): void {
    for (let index = 0; index < this.detailsData.length; index++) {
      if (this.detailsData[index].stepType === 'FREETEXT') {
        this.freeTextSteps.push(this.detailsData[index]);
        this.detailsData.splice(index, 1);
      }
    }
  }
}
