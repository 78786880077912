import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  public get currentDate(): Date {
    return new Date();
  }
}
