<form [formGroup]="form" (ngSubmit)="submit()">
  <h1>Új push üzenet küldése</h1>
  <web-admin-notification-form [formControl]="control" (switchLinkToCreate)="createSwitchLink = ($event)">
    <div in-bottom-card>
      <a mat-stroked-button type="button" data-test="discard" color="primary" routerLink="/notification">
        Elvetés
      </a>
      <button mat-raised-button data-test="submit" color="primary">
        Küldés
      </button>
    </div>
  </web-admin-notification-form>
</form>
