import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/auth.guard';
import { LeaveGuard } from '../../core/leave.guard';
import { PageLayoutComponent } from '../layout/page-layout/page-layout.component';
import { PostCreateArticleComponent } from './create/article/article.component';
import { PostCreateDiscountComponent } from './create/discount/discount.component';
import { PostCreateEventComponent } from './create/event/event.component';
import { PostCreateVideoComponent } from './create/video/video.component';
import { PostCreateVoteComponent } from './create/vote/vote.component';
import { PostEditArticleComponent } from './edit/article/article.component';
import { PostEditDiscountComponent } from './edit/discount/discount.component';
import { PostEditEventComponent } from './edit/event/event.component';
import { PostEditVideoComponent } from './edit/video/video.component';
import { PostEditVoteComponent } from './edit/vote/vote.component';
import { PostListComponent } from './list/list.component';

const routes: Routes = [
  {
    path: 'post',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: PageLayoutComponent,
    children: [
      {
        path: 'article',
        children: [
          {
            path: ':id',
            component: PostEditArticleComponent,
            canDeactivate: [LeaveGuard],
          },
          {
            path: '',
            component: PostCreateArticleComponent,
            canDeactivate: [LeaveGuard],
          },
        ],
      },
      // TODO
      // {
      //   path: 'survey',
      //   loadChildren: () =>
      //     import('../survey/survey.module').then((m) => m.SurveyModule),
      // },
      {
        path: 'video',
        children: [
          {
            path: ':id',
            component: PostEditVideoComponent,
            canDeactivate: [LeaveGuard],
          },
          {
            path: '',
            component: PostCreateVideoComponent,
            canDeactivate: [LeaveGuard],
          },
        ],
      },
      {
        path: 'vote',
        children: [
          {
            path: ':id',
            component: PostEditVoteComponent,
            canDeactivate: [LeaveGuard],
          },
          {
            path: '',
            component: PostCreateVoteComponent,
            canDeactivate: [LeaveGuard],
          },
        ],
      },
      {
        path: 'event',
        children: [
          {
            path: ':id',
            component: PostEditEventComponent,
            canDeactivate: [LeaveGuard],
          },
          {
            path: '',
            component: PostCreateEventComponent,
            canDeactivate: [LeaveGuard],
          },
        ],
      },
      {
        path: 'discount',
        children: [
          {
            path: ':id',
            component: PostEditDiscountComponent,
            canDeactivate: [LeaveGuard],
          },
          {
            path: '',
            component: PostCreateDiscountComponent,
            canDeactivate: [LeaveGuard],
          },
        ],
      },
      {
        path: '',
        component: PostListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PostRoutingModule {}
