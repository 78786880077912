/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface coupondDataStructure {
  title: string;
  description: string;
  startDate: Date;
  responseCount: string;
}

@Component({
  selector: 'web-admin-survey-coupon-details',
  templateUrl: './survey-coupon-details.component.html',
  styleUrls: ['./survey-coupon-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyCouponDetailsComponent {
  @Input() public couponData: coupondDataStructure;
  constructor() {
    // no-op
  }
}
