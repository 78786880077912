import { asyncScheduler, concat, OperatorFunction, SchedulerLike } from 'rxjs';
import { debounceTime, publish, take } from 'rxjs/operators';

export const debounceTimeAfter = <T = unknown>(
  amount: number,
  dueTime: number,
  scheduler: SchedulerLike = asyncScheduler
): OperatorFunction<T, T> => {
  return publish((value$) =>
    concat(
      value$.pipe(take(amount)),
      value$.pipe(debounceTime(dueTime, scheduler))
    )
  );
};

export const debounceTimeAfterFirst = <T = unknown>(
  dueTime: number,
  scheduler: SchedulerLike = asyncScheduler
): OperatorFunction<T, T> => {
  return debounceTimeAfter(1, dueTime, scheduler);
};
