import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { ModalService } from '@web-admin/shared-lib';
import { Observable } from 'rxjs';
import { LexiconItemComponent } from '../form/lexicon-item.component';

@Injectable({
  providedIn: 'root',
})
export class LexiconItemComponentLeaveGuard
  implements CanDeactivate<{ lexiconItemFormComponent: LexiconItemComponent }> {
  constructor(private readonly modalService: ModalService) {}
  canDeactivate({
    lexiconItemFormComponent,
  }: {
    lexiconItemFormComponent: LexiconItemComponent;
  }): boolean | Observable<boolean | UrlTree> {
    if (lexiconItemFormComponent.form.pristine) {
      return true;
    }
    return this.modalService
      .openConfirmModal({
        confirmText: 'Elvetem',
        description: 'A módosításaid végleg törlődnek.',
        title: 'Biztos, hogy elveted?',
        color: 'warn',
      })
      .afterClosed();
  }
}
