import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../core/auth.service';

@Component({
  selector: 'web-admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public controls = {
    email: new FormControl(undefined, [Validators.required]),
    password: new FormControl(undefined, [Validators.required]),
  };
  public formGroup = new FormGroup(this.controls);

  constructor(public authService: AuthService) {}

  public onSubmit(): void {
    this.authService.login(
      this.controls.email.value,
      this.controls.password.value
    );
  }
}
