import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GameQuizDto } from '@otp-junior/admin-client';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HandleOptions } from './handle-options';

@Injectable()
export class CreateInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router
  ) {}

  private readonly campaignOptions: HandleOptions = {
    success: {
      message: 'Kampány létrehozás sikeres.',
    },
    returnRoute: '/game/campaign',
  };

  private readonly gameQuizOptions: HandleOptions = {
    success: {
      message: 'Játék kvíz létrehozás sikeres.',
    },
    error: {
      message: 'Nem sikerült a játék kvízt létrehozni. Próbáld meg újra.',
    },
    returnRoute: '/game/quiz',
  };

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.method === 'POST') {
      if (/admin\/gamecampaigns$/.test(request.url)) {
        return this.handleRequest(request, next, this.campaignOptions);
      } else if (
        /admin\/news-feed-entry$/.test(request.url) &&
        (request.body as GameQuizDto).objectType === 'GameQuizDto'
      ) {
        return this.handleRequest(request, next, this.gameQuizOptions);
      }
    }

    return next.handle(request);
  }

  private handleRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    options: HandleOptions
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((result) => {
        if (result.type === HttpEventType.Response) {
          this.snackBar.open(
            options.success.message,
            options.success.actionLabel || 'OK',
            {
              panelClass: ['snack-bar-container', 'success'],
              duration: 3000,
            }
          );
          this.router.navigateByUrl(options.returnRoute);
        }
      })
    );
  }
}
