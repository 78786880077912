/* eslint-disable unicorn/no-useless-undefined */

import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  Self,
} from '@angular/core';
import { FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import {
  AngularEditorConfig,
  AngularEditorService,
} from '@kolkov/angular-editor';
import { LexiconDataService } from '@otp-junior/admin-client';
import {
  AttachmentService,
  ConfirmModalData,
  debounceTimeAfterFirst,
  DropdownListItem,
  DropdownVM,
  ModalService,
  MultiInputComponent,
} from '@web-admin/shared-lib';
import { Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { AppConfig } from '../../../../config/app.config';
import { mapLexiconWordListItemResponseToDropdownItem } from '../../../lexicon/mappers/map-to-lexiconvm';
import { ArticleVM } from '../../create/article/article.interface';
import { PostAbstractFormComponent } from '../abstract-form.component';
import { RemoveCommonDto } from '../remove-common-dto.type';

const maxTitleLength = 60;
const maxShareTextLength = 200;
const maxDescriptionLength = 100;
const debounceTime = 300;
const minWordLength = 3;

@Component({
  selector: 'web-admin-article-form',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  providers: [AngularEditorService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleFormComponent extends PostAbstractFormComponent<ArticleVM> {
  public maxTitleLength = maxTitleLength;
  public maxShareTextLength = maxShareTextLength;
  public maxDescriptionLength = maxDescriptionLength;
  public readonly editorConfig: AngularEditorConfig = this.attachmentService.getAngularEditorConfig(
    `${this.appConfig.getConfig('backendBaseUrl')}/admin/image`,
    this.angularEditorService,
    this.appConfig.getConfig('imageUploadLimitInBytes')
  );
  public constructor(
    @Self() @Optional() readonly ngControl: NgControl,
    private readonly lexiconDataService: LexiconDataService,
    private readonly appConfig: AppConfig,
    private readonly modalService: ModalService,
    private readonly angularEditorService: AngularEditorService,
    private readonly attachmentService: AttachmentService
  ) {
    super(ngControl, {
      categoryId: new FormControl(undefined, [Validators.required]),
      marketingContent: new FormControl(false),
      highlighted: new FormControl(false),
      pinned: new FormControl(false),
      imageId: new FormControl(undefined, [Validators.required]),
      title: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxTitleLength),
      ]),
      description: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxDescriptionLength),
      ]),
      lexiconWords: new FormControl([]),
      sharingText: new FormControl(undefined, [
        Validators.maxLength(maxShareTextLength),
      ]),
      hasSharingImage: new FormControl(false),
      hasSharingText: new FormControl(false),
      sharingImageId: new FormControl(undefined),
      htmlContent: new FormControl(undefined, [Validators.required]),
      startDate: new FormControl(undefined, [Validators.required]),
    });
  }

  public searchSubject$: Subject<string> = new Subject();
  public options$ = this.searchSubject$.asObservable().pipe(
    debounceTimeAfterFirst(debounceTime),
    switchMap((searchWord: string) => {
      if (searchWord.length < minWordLength) {
        return of({ isLoading: false } as DropdownVM);
      }

      return this.lexiconDataService
        .searchLexiconWords(searchWord)
        .pipe(
          map((response) => {
            const content = response
              .filter(
                (dropdownItem) =>
                  !this.form.value.lexiconWords.some(
                    (connectingLexiconWord: {
                      connectingLexiconWord: DropdownListItem;
                    }) => {
                      return (
                        connectingLexiconWord?.connectingLexiconWord?.id ===
                        dropdownItem.id
                      );
                    }
                  )
              )
              .map((element) => {
                return mapLexiconWordListItemResponseToDropdownItem(element);
              });
            return {
              ...response,
              isLoading: false,
              content: content,
            };
          })
        )
        .pipe(startWith({ isLoading: true, content: [] }));
    })
  );

  public onCategoryCreated(id: number): void {
    this.controls.categoryId.setValue(id);
  }

  public connectedLexiconWordControlGenerator(): FormGroup {
    return new FormGroup({
      connectingLexiconWord: new FormControl(undefined, [Validators.required]),
    });
  }

  public onSearchFor(searchWord: string): void {
    this.searchSubject$.next(searchWord);
  }

  public removeConnectedLexiconItem(
    multiInput: MultiInputComponent<FormGroup, unknown>,
    index: number
  ): void {
    this.openConfirmationModal$({
      title: 'Biztos, hogy törlöd a kapcsolódó Lexikon bejegyzést?',
      description:
        'A bejegyzés nem fog többé megjelenni a kapcsolt szó oldalán.',
      confirmText: 'Törlöm',
    })
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          multiInput.removeControl(index);
        }
      });
  }

  private openConfirmationModal$(
    modalOptions: ConfirmModalData
  ): Observable<boolean> {
    return this.modalService.openConfirmModal(modalOptions).afterClosed();
  }

  public addSharingImage(): void {
    this.controls.hasSharingImage.setValue(true);
  }

  public removeSharingImage(): void {
    this.controls.hasSharingImage.reset(false);
    this.controls.hasSharingText.reset(false);
    this.controls.sharingText.reset();
    this.controls.sharingImageId.reset();
  }

  protected mapToForm(data: ArticleVM): RemoveCommonDto<ArticleVM> {
    return {
      categoryId: data.categoryId,
      htmlContent: data.htmlContent,
      imageId: data.imageId,
      marketingContent: data.marketingContent,
      pinned: data.pinned,
      highlighted: data.highlighted,
      startDate: data.startDate,
      title: data.title,
      description: data.description,
      lexiconWords: data.lexiconWords,
      hasSharingImage: data.hasSharingImage,
      hasSharingText: data.hasSharingText,
      sharingText: data.sharingText,
      sharingImageId: data.sharingImageId,
    };
  }
}
