import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import {
  JobDataService,
  JobStatus,
  JobSummaryDto,
} from '@otp-junior/admin-client';
import { Observable } from 'rxjs';
import { JobListService } from '../list.service';

@Component({
  selector: 'web-admin-job-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobListComponent implements OnInit {
  public filterControl = new FormControl();
  public count$: Observable<number>;
  public loading$: Observable<boolean>;
  public list$: Observable<JobSummaryDto[]>;
  public pageNumber$: Observable<number>;
  public displayedColumns = [
    'icon',
    'title',
    'pin',
    'publish',
    'reactions',
    'status',
    'edit',
  ];

  public constructor(
    private readonly listService: JobListService,
    private readonly jobDataService: JobDataService
  ) {}

  public ngOnInit(): void {
    this.filterControl.valueChanges.subscribe((filter) => {
      this.listService.setFilter(filter);
      this.setDisplayedColumns(filter.status);
    });
    this.list$ = this.listService.items$;
    this.count$ = this.listService.totalElements$;
    this.loading$ = this.listService.loading$;
    this.pageNumber$ = this.listService.pageNumber$;
  }

  public setDisplayedColumns(status: JobStatus): void {
    if (status === JobStatus.Active) {
      this.displayedColumns = [
        'icon',
        'title',
        'pin',
        'publish',
        'reactions',
        'status',
        'edit',
      ];
    } else {
      this.displayedColumns = [
        'icon',
        'title',
        'publish',
        'reactions',
        'status',
        'edit',
      ];
    }
  }

  public onPageChange(event: PageEvent): void {
    this.listService.setPage(event.pageIndex);
  }

  public getRouterLink(
    dto: Pick<JobSummaryDto, 'employmentType' | 'id'>
  ): string {
    return `${dto.employmentType.toLowerCase()}/${dto.id}`;
  }

  public onPinClicked({ pinned, id: jobId }: JobSummaryDto): void {
    (pinned
      ? this.jobDataService.unpinJob()
      : this.jobDataService.pinJob({ jobId })
    ).subscribe(() =>
      this.listService.setFilter(
        this.filterControl.value || { status: JobStatus.Active }
      )
    );
  }
}
