import {
  LexiconWordListItemResponse,
  LexiconWordResponse,
  LexiconWordStatus as ApiLexiconWordStatus,
  NewsFeedEntryUndetailed,
  UpdateLexiconWordRequest,
} from '@otp-junior/admin-client';
import { CreateLexiconWordRequest } from '@otp-junior/admin-client/model/models';
import { DropdownListItem } from '@web-admin/shared-lib';
import { LexiconItemForm } from '../form/lexicon-item.vm';
import { LexiconVM, LexiconWordStatus } from '../models/lexicon.vm';

export const mapToLexiconVM = (
  lexiconItem: LexiconWordListItemResponse
): LexiconVM => ({
  id: lexiconItem.id,
  word: lexiconItem.word,
  date: lexiconItem.date,
  associatedContentCount: lexiconItem.connectingPostsCount,
  viewCount: lexiconItem.viewCount,
  status: mapLexiconStatus(lexiconItem.status),
});

export const mapLexiconStatus = (
  status: ApiLexiconWordStatus
): LexiconWordStatus => {
  switch (status) {
    case ApiLexiconWordStatus.Active:
      return LexiconWordStatus.ACTIVE;
    case ApiLexiconWordStatus.Draft:
      return LexiconWordStatus.DRAFT;
  }
};

export const mapToApiLexiconStatus = (
  status: LexiconWordStatus
): ApiLexiconWordStatus => {
  switch (status) {
    case LexiconWordStatus.ACTIVE:
      return ApiLexiconWordStatus.Active;
    case LexiconWordStatus.DRAFT:
      return ApiLexiconWordStatus.Draft;
  }
};

export const mapNewsFeedEntryUndetailedToLexiconDropdownItem = (
  newsFeedEntry: NewsFeedEntryUndetailed
): DropdownListItem => {
  return {
    id: newsFeedEntry.id,
    name: newsFeedEntry.title,
    type: newsFeedEntry.type,
  };
};

export const mapLexiconWordListItemResponseToDropdownItem = (
  lexiconWordListItem: LexiconWordListItemResponse
): DropdownListItem => {
  return {
    id: lexiconWordListItem.id,
    name: lexiconWordListItem.word,
  };
};

export const mapLexiconWordResponseToCreateOrUpdateLexiconWordRequest = (
  word: LexiconItemForm
): CreateLexiconWordRequest | UpdateLexiconWordRequest => {
  return {
    word: word.word,
    definition: word.definition,
    status: mapToApiLexiconStatus(word.status),
    connectingPosts: word.connectingPosts
      .filter((connectingPost) => !!connectingPost.connectingPost)
      .map((connectingPost) => connectingPost.connectingPost.id),
  };
};

export const mapLexiconWordResponseToLexiconItemForm = (
  word: LexiconWordResponse
): LexiconItemForm => {
  return {
    word: word.word,
    definition: word.definition,
    status: mapLexiconStatus(word.status),
    date: new Date(word.date),
    connectingPosts: word.connectingPosts?.map((post) => {
      return {
        connectingPost: mapNewsFeedEntryUndetailedToLexiconDropdownItem(post),
      };
    }),
  };
};
