import { AbstractControl, ValidationErrors } from '@angular/forms';

export const shouldBeAfter = (otherControl: AbstractControl) => {
  return (control: AbstractControl): ValidationErrors => {
    if (new Date(otherControl.value) < new Date(control.value)) {
      // eslint-disable-next-line unicorn/no-null
      return null;
    }
    return {
      shouldBeAfter: new Date(otherControl.value),
    };
  };
};
