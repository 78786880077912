import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
} from '@angular/forms';
import { JobFilter, JobStatus } from '@otp-junior/admin-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'web-admin-job-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobListFilterComponent implements ControlValueAccessor {
  public controls = {
    status: new FormControl(JobStatus.Active),
  };
  public form = new FormGroup(this.controls);

  public statusList = [
    {
      name: 'Aktív állások',
      value: JobStatus.Active,
    },
    {
      name: 'Időzített állások',
      value: JobStatus.Scheduled,
    },
    {
      name: 'Archivált állások',
      value: JobStatus.Archive,
    },
  ];
  private readonly subscription = new Subscription();

  constructor(@Self() @Optional() private readonly ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  public writeValue(object: JobFilter): void {
    if (object) {
      this.form.setValue(object, { emitEvent: false });
    }
  }
  public registerOnChange(function_: (value: JobFilter) => void): void {
    this.subscription.add(this.form.valueChanges.subscribe(function_));
  }
  public registerOnTouched(function_: () => void): void {
    this.subscription.add(this.form.statusChanges.subscribe(function_));
  }
  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && !this.form.disabled) {
      this.form.disable();
    }
    if (!isDisabled && !this.form.enabled) {
      this.form.enable();
    }
  }
}
