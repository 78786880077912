import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import {
  DateTimeInputModule,
  FormCardModule,
  ListEmptyModule,
  MaterialErrorModule,
  MatTabFormModule,
} from '@web-admin/shared-lib';
import { NotificationCreateComponent } from './create/create.component';
import { NotificationEditComponent } from './edit/edit.component';
import { NotificationFormComponent } from './form/form.component';
import { CreateInterceptor } from './interceptors/create.interceptor';
import { EditInterceptor } from './interceptors/edit.interceptor';
import { NotificationListFilterComponent } from './list-filter/list-filter.component';
import { NotificationListComponent } from './list/list.component';
import { NotificationRoutingModule } from './notification-routing.module';
import { NotificationStatusIconComponent } from './notification-status-icon/notification-status-icon.component';

@NgModule({
  declarations: [
    NotificationListComponent,
    NotificationListFilterComponent,
    NotificationCreateComponent,
    NotificationFormComponent,
    NotificationEditComponent,
    NotificationStatusIconComponent,
  ],
  imports: [
    CommonModule,
    NotificationRoutingModule,
    MatPaginatorModule,
    MatRadioModule,
    MatTableModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatTabFormModule,
    ListEmptyModule,
    FormCardModule,
    MaterialErrorModule,
    MatSnackBarModule,
    DateTimeInputModule,
    MatSelectModule,
    FormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CreateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EditInterceptor,
      multi: true,
    },
  ],
})
export class NotificationModule {}
