import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CampaignListStatus } from '../../models/campaign.vm';
import { LifeStageListVM } from '../../models/life-stage.vm';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'web-admin-life-stage-list',
  templateUrl: './life-stage-list.component.html',
  styleUrls: ['./life-stage-list.component.scss'],
})
export class LifeStageListComponent implements OnInit {
  private readonly page$ = new BehaviorSubject<number>(0);

  public lifeStages$: Observable<LifeStageListVM>;

  public campaignListStatus = CampaignListStatus;
  public readonly displayedColumns: string[] = [
    'campaignName',
    'title',
    'startDate',
    'status',
    'edit',
  ];

  constructor(
    private readonly campaignService: CampaignService,
    private readonly snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.lifeStages$ = this.page$.asObservable().pipe(
      switchMap((page) => this.campaignService.getLifeStages$(page, 10)),
      catchError((error) => this.handleError(error))
    );
  }

  public onPageChange(pageEvent: PageEvent): void {
    this.page$.next(pageEvent.pageIndex);
  }

  private handleError<T>(error: T): Observable<T> {
    this.snackBar.open('Hiba történt. Próbáld újra!', 'OK', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 30000,
    });
    return of(error);
  }
}
