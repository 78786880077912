import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PostDataService, VideoDto } from '@otp-junior/admin-client';
import { AbstractCreate } from '../abstract-create';

@Component({
  selector: 'web-admin-post-create-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class PostCreateVideoComponent extends AbstractCreate<VideoDto> {
  protected objectType: 'VideoDto' = 'VideoDto';
  public constructor(
    readonly postDataService: PostDataService,
    readonly snackBar: MatSnackBar
  ) {
    super(postDataService, snackBar);
  }
}
