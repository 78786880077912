/* eslint-disable sonarjs/no-duplicate-string */
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ConsentDataService,
  ConsentDto,
  ConsentFilter,
  ConsentFilterResponse,
  ConsentStatus,
  ConsentType,
} from '@otp-junior/admin-client';
import { ListService } from '@web-admin/shared-lib';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  DeclarationListElementVM,
  DeclarationListStatus,
  DeclarationListType,
} from './list-element.vm';

@Injectable({
  providedIn: 'root',
})
export class DeclarationListService {
  public items$: Observable<DeclarationListElementVM[]>;
  public totalElements$: Observable<number>;
  public loading$: Observable<boolean>;
  public filterState$: Observable<{ type: DeclarationListType }>;
  public pageNumber$: Observable<number>;
  private readonly listService: ListService<
    ConsentFilter,
    ConsentDto,
    ConsentFilterResponse
  >;

  public constructor(
    private readonly consentDataService: ConsentDataService,
    private readonly snackBar: MatSnackBar
  ) {
    this.listService = new ListService<ConsentFilter, ConsentDto>(
      undefined,
      (filter, page) =>
        this.consentDataService.searchConsents({
          filter,
          page,
          pageSize: 10,
        })
    );

    this.items$ = this.listService.items$.pipe(
      map((consents) =>
        consents.map(
          (consent): DeclarationListElementVM => ({
            type: this.mapConsentType(consent.type),
            id: consent.id,
            availabilityStartDate: consent.effectiveDate,
            status: this.mapConsentStatus(consent.status),
            url: consent.url,
          })
        )
      )
    );
    this.totalElements$ = this.listService.totalElements$;
    this.loading$ = this.listService.loading$;
    this.filterState$ = this.listService.filter$.pipe(
      map((filter) => ({
        type: this.mapConsentType(filter?.type),
      }))
    );
    this.pageNumber$ = this.listService.pageNumber$;
  }

  public setFilter(filter: DeclarationListType): void {
    this.listService.setFilter(
      filter === DeclarationListType.Empty
        ? undefined
        : { type: this.mapDeclarationType(filter) }
    );
  }

  public setPage(page: number): void {
    this.listService.setPage(page);
  }

  public uploadConsent(
    type: DeclarationListType,
    startDateTime: string,
    fileUrl: string
  ): void {
    this.consentDataService
      .createConsent(
        {
          effectiveDate: startDateTime,
          type: this.mapDeclarationType(type),
          url: fileUrl,
          id: undefined,
          status: undefined,
        },
        'response'
      )
      .pipe(
        catchError((error) => {
          this.snackBar.open(
            'Nem sikerült feltölteni a nyilatkozatot. Próbáld meg újra.',
            'OK',
            {
              panelClass: ['snack-bar-container', 'failure'],
              duration: 3000,
            }
          );
          return of(error);
        })
      )
      .subscribe((response) => {
        if (response.ok) {
          this.snackBar.open('Sikeresen feltöltötted a nyilatkozatot.', 'OK', {
            panelClass: ['snack-bar-container', 'success'],
            duration: 3000,
          });
          this.listService.reload();
        }
      });
  }

  public deleteConsent(id: number): void {
    this.consentDataService
      .deleteConsent(id, 'response')
      .pipe(
        catchError((error) => {
          this.snackBar.open(
            'Nem sikerült törölni a nyilatkozatot. Próbáld meg újra.',
            'OK',
            {
              panelClass: ['snack-bar-container', 'failure'],
              duration: 3000,
            }
          );
          return of(error);
        })
      )
      .subscribe((response) => {
        if (response.ok) {
          this.snackBar.open('Sikeresen törölted a nyilatkozatot.', 'OK', {
            panelClass: ['snack-bar-container', 'success'],
            duration: 3000,
          });
          this.listService.reload();
        }
      });
  }

  private mapConsentType(type: ConsentType): DeclarationListType {
    switch (type) {
      case ConsentType.Marketing:
        return DeclarationListType.Marketing;

      case ConsentType.DataPrivacy:
        return DeclarationListType.Contract;

      case ConsentType.ParticipationRules:
        return DeclarationListType.Participation;

      case ConsentType.GameRules:
        return DeclarationListType.GameRules;

      default:
        return DeclarationListType.Empty;
    }
  }

  private mapDeclarationType(type: DeclarationListType): ConsentType {
    switch (type) {
      case DeclarationListType.Marketing:
        return ConsentType.Marketing;

      case DeclarationListType.Contract:
        return ConsentType.DataPrivacy;

      case DeclarationListType.Participation:
        return ConsentType.ParticipationRules;

      case DeclarationListType.GameRules:
        return ConsentType.GameRules;

      default:
        return ConsentType.Marketing;
    }
  }

  private mapConsentStatus(status: ConsentStatus): DeclarationListStatus {
    switch (status) {
      case ConsentStatus.Scheduled:
        return DeclarationListStatus.Timed;

      case ConsentStatus.Active:
        return DeclarationListStatus.Active;

      case ConsentStatus.Archived:
        return DeclarationListStatus.Empty;

      default:
        return DeclarationListStatus.Timed;
    }
  }
}
