<shared-form-card>
  <h2>Bejegyzés beállításai</h2>
  <div class="checkbox-container">
    <mat-checkbox [formControl]="controls.marketingContent">
      Marketing tartalom
    </mat-checkbox>
    <mat-checkbox [formControl]="controls.highlighted">
      Kiemelt tartalom
    </mat-checkbox>
    <mat-checkbox [formControl]="controls.pinned">
      Rögzített tartalom
    </mat-checkbox>
  </div>
  <br />
  <mat-form-field>
    <mat-label>Publikálás dátuma</mat-label>
    <date-time-input
      [formControl]="controls.startDate"
      data-test="startDate"
    ></date-time-input>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <web-admin-file-picker
    [formControl]="controls.imageId"
    [erasable]="true"
    titleText="Videó indexképe"
  ></web-admin-file-picker>
</shared-form-card>

<shared-form-card *ngIf="controls.sharingImage.value">
  <div class="card-header">
    <h2>Bejegyzés beállításai</h2>
    <button
      (click)="removeSharingImage()"
      mat-icon-button
      color="warn"
      type="button"
      matSuffix
      [attr.data-test]="'sharing-image-remove'"
    >
      <img src="assets/icons/delete.svg" alt="" />
      Törlés
    </button>
  </div>
  <web-admin-file-picker
    [formControl]="controls.sharingImageId"
    titleText="Töltsd fel a bejegyzés indexképét"
  ></web-admin-file-picker>
  <mat-form-field class="sharing-image-text">
    <mat-label>Megosztás szövege</mat-label>
    <input matInput [formControl]="controls.sharingText" data-test="title" />
    <mat-hint align="end">
      {{ controls.sharingText.value?.length || 0 }}/{{ maxShareTextLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
</shared-form-card>
<button
  *ngIf="!controls.sharingImage.value"
  (click)="addSharingImage()"
  type="button"
  mat-button
  color="primary"
  class="sharing-image"
  data-test="add-content"
>
  <img
    src="assets/icons/plus_green.svg"
    alt="megosztási kep és szöveg hozzáadása"
    class="add-content-image"
  />
  <b>Megosztási kép és szöveg hozzáadása</b>
</button>
<shared-form-card class="full-width">
  <h2>Videó feltöltése</h2>
  <mat-form-field>
    <mat-label>Videó címe</mat-label>
    <input matInput [formControl]="controls.title" data-test="title" />
    <mat-hint align="end">
      {{ controls.title.value?.length || 0 }}/{{ maxTitleLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Videó linkje (YouTube)</mat-label>
    <input matInput [formControl]="controls.videoUrl" data-test="videoUrl" />
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Rövid leírás</mat-label>
    <textarea
      matInput
      [formControl]="controls.description"
      data-test="description"
      cdkTextareaAutosize
    ></textarea>
    <mat-hint align="end">
      {{ controls.description.value?.length || 0 }}/{{ maxDescriptionLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <ng-content selector="[in-bottom-card]"></ng-content>
</shared-form-card>
