/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  ArticleDto,
  LexiconDataService,
  NewsFeedEntry,
  NewsFeedEntryStatus,
  PostDataService,
} from '@otp-junior/admin-client';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { mapLexiconWordListItemResponseToDropdownItem } from '../../../lexicon/mappers/map-to-lexiconvm';
import { ArticleVM } from '../../create/article/article.interface';
import { AbstractEdit } from '../abstract-edit';

const isOfType = (objectType: NewsFeedEntry['objectType']) => <
  T extends NewsFeedEntry
>(
  data: NewsFeedEntry
): data is T => data.objectType === objectType;

@Component({
  selector: 'web-admin-post-edit-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostEditArticleComponent
  extends AbstractEdit<ArticleDto>
  implements OnInit {
  protected objectType: 'ArticleDto' = 'ArticleDto';

  public constructor(
    readonly postDataService: PostDataService,
    readonly lexiconDataService: LexiconDataService,
    readonly activatedRoute: ActivatedRoute,
    readonly snackBar: MatSnackBar
  ) {
    super(postDataService, activatedRoute, snackBar);
  }

  public ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        map((parameterMap) => {
          return parameterMap.get('id');
        }),
        filter((id) => !!id),
        switchMap((id) => {
          return this.postDataService.getNewsFeedEntryById(+id);
        }),
        filter<NewsFeedEntry, ArticleDto>(isOfType(this.objectType)),
        switchMap((data) => {
          return this.lexiconDataService
            .getLexiconWords(
              undefined,
              undefined,
              undefined,
              undefined,
              data.lexiconWordIds?.length ? data.lexiconWordIds : [-1]
            )
            .pipe(
              map(
                (lexiconWords): ArticleVM => {
                  return {
                    ...data,
                    hasSharingImage: !!data.sharingImageId,
                    hasSharingText: !!data.sharingText,
                    lexiconWords: lexiconWords
                      ? lexiconWords.content.map((word) => {
                          return {
                            connectingLexiconWord: mapLexiconWordListItemResponseToDropdownItem(
                              word
                            ),
                          };
                        })
                      : [],
                  };
                }
              )
            );
        })
      )
      .subscribe((data) => {
        this.id = data.id;
        this.status = data.status;
        this.draft = data.status === NewsFeedEntryStatus.Draft;
        this.removable =
          data.status === NewsFeedEntryStatus.Archive || this.draft;
        this.form.setValue({
          data,
        });
      });
  }

  // We override this method to map custom parameters not existing in others
  public sendEdit(
    data: Omit<ArticleVM, 'hasSharingImage'>,
    draft = false
  ): Observable<NewsFeedEntry> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { lexiconWords, ...restOfData } = data;
    return this.postDataService.updateNewsFeedEntry(this.id, {
      objectType: this.objectType,
      draft,
      // readonly should be sent as null
      /* eslint-disable unicorn/no-null */
      type: null,
      id: null,
      status: null,
      lexiconWordIds: lexiconWords.map((word) => word.connectingLexiconWord.id),
      /* eslint-enable unicorn/no-null */
      ...restOfData,
    });
  }
}
