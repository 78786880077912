import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { DiscountDto, PostDataService } from '@otp-junior/admin-client';
import { AbstractEdit } from '../abstract-edit';

@Component({
  selector: 'web-admin-post-edit-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
})
export class PostEditDiscountComponent extends AbstractEdit<DiscountDto> {
  protected objectType: 'DiscountDto' = 'DiscountDto';

  public constructor(
    readonly postDataService: PostDataService,
    readonly activatedRoute: ActivatedRoute,
    readonly snackBar: MatSnackBar
  ) {
    super(postDataService, activatedRoute, snackBar);
  }
}
