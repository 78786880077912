/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsDataService } from '@otp-junior/admin-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { coupondDataStructure } from '../survey-coupon-details/survey-coupon-details.component';
import { detailsDataStructure } from '../survey-details/survey-details.component';

@Component({
  selector: 'web-admin-survey-result',
  templateUrl: './survey-result.component.html',
  styleUrls: ['./survey-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyResultComponent implements OnInit {
  public id: number;
  public couponData: coupondDataStructure;
  public detailsData: Array<detailsDataStructure>;
  private readonly _isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isloadedObs: Observable<boolean> = this._isLoaded.asObservable();
  constructor(
    private readonly route: ActivatedRoute,
    protected readonly analyticsDataService: AnalyticsDataService
  ) {
    this.id = (this.route.snapshot.paramMap.get('type') as unknown) as number;
  }
  ngOnInit(): void {
    this.analyticsDataService.getSurveyAnalytics(this.id).subscribe((data) => {
      this.withoutProperty(data, 'steps');
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public withoutProperty(data: any, objkey: string): void {
    const { [objkey]: unused, ...rest } = data;
    this._isLoaded.next(true);
    this.detailsData = unused;
    this.couponData = rest;
  }

  public onExportClicked(): void {
    this.analyticsDataService
      .exportSurveyResultStatistics(this.id)
      .subscribe((response: Blob) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        document.body.append(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'export.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  }
}
