<div [formGroup]="form">
  <div class="first-row">
    <div mat-tab-nav-bar [formControl]="controls.status">
      <a
        mat-tab-link
        *ngFor="let status of statusList"
        [value]="status.value"
        [attr.data-test]="'status-' + status.value"
      >
        {{ status.name }}
      </a>
    </div>
    <ng-content></ng-content>
  </div>
</div>
