<div class="main-coupon-deatils" *ngIf="couponData as data">
  <div class="header">
    <h3>Kutatás</h3>
    <div class="fillers">
      <p class="count">Kitöltők:</p>
      <p class="count-number">{{ data.responseCount }} Fő</p>
    </div>
  </div>
  <div class="publicationDate">
    <img
      src="../../../../assets/survey/pen_and_paper.svg"
      color="primary"
      alt=""
    />
    <p>Publikálva:</p>
    <p>{{ data.startDate | date }}</p>
  </div>
  <div class="coupon-details">
    <div>
      <img src="../../../../assets/survey/coupon.svg" color="primary" alt="" />
      <h2>{{ data.title }}</h2>
    </div>
    <p>
      {{ data.description }}
    </p>
  </div>
</div>
