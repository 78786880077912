import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { typeList } from '../helpers/type-name.list';

@Component({
  selector: 'web-admin-create-post-drop-down',
  templateUrl: './create-post-drop-down.component.html',
  styleUrls: ['./create-post-drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePostDropDownComponent {
  public typeList = typeList;
  constructor(public router: Router) {}
  public routeToCreate(routeFragment: string): void {
    routeFragment = routeFragment.toLocaleLowerCase();
    if (
      routeFragment === 'survey' ||
      routeFragment === 'quiz' ||
      routeFragment === 'game_quiz'
    ) {
      this.router.navigate([`${routeFragment}/create`], { replaceUrl: true });
    } else {
      this.router.navigate([`post/${routeFragment}`], { replaceUrl: true });
    }
  }
}
