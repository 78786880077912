import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { GameQuizStatus } from '../../models/game-quiz-details.vm';
import { GameQuizService } from '../../services/game-quiz.service';
import { GameQuizListVM } from '../list-element.vm';

@Component({
  selector: 'web-admin-game-quiz-list',
  templateUrl: './game-quiz-list.component.html',
  styleUrls: ['./game-quiz-list.component.scss'],
})
export class GameQuizListComponent implements OnInit {
  private readonly page$ = new BehaviorSubject<number>(0);

  public gameQuizItems$: Observable<GameQuizListVM>;

  public GameListStatus = GameQuizStatus;
  public readonly displayedColumns: string[] = [
    'campaignName',
    'level',
    'status',
    'edit',
  ];

  constructor(
    private readonly gameQuizService: GameQuizService,
    private readonly snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.gameQuizItems$ = this.page$.asObservable().pipe(
      switchMap((page) => this.gameQuizService.getGameQuizzes$(page, 10)),
      catchError((error) => this.handleError(error))
    );
  }

  public onPageChange(pageEvent: PageEvent): void {
    this.page$.next(pageEvent.pageIndex);
  }

  private handleError<T>(error: T): Observable<T> {
    this.snackBar.open('Hiba történt. Próbáld újra!', 'OK', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 30000,
    });
    return of(error);
  }
}
