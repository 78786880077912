import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NewsFeedEntryStatus } from '@otp-junior/admin-client';

@Component({
  selector: 'web-admin-post-status-icon',
  templateUrl: './post-status-icon.component.html',
  styleUrls: ['./post-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostStatusIconComponent {
  @Input() status: NewsFeedEntryStatus;
  public srcMap: Record<NewsFeedEntryStatus, string> = {
    ACTIVE: 'assets/post/status-icon/active.svg',
    ARCHIVE: 'assets/post/status-icon/archive.svg',
    DRAFT: 'assets/post/status-icon/draft.svg',
    SCHEDULED: 'assets/post/status-icon/scheduled.svg',
  };
  public textMap: Record<NewsFeedEntryStatus, string> = {
    ACTIVE: 'Aktív',
    ARCHIVE: 'Archív',
    DRAFT: 'Piszkozat',
    SCHEDULED: 'Időzített',
  };
}
