import { Injectable } from '@angular/core';
import {
  NotificationDataService,
  NotificationDto,
  NotificationFilter,
  NotificationFilterStatusEnum,
} from '@otp-junior/admin-client';
import { ListService } from '@web-admin/shared-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationListService {
  public items$: Observable<NotificationDto[]>;
  public totalElements$: Observable<number>;
  public loading$: Observable<boolean>;
  public pageNumber$: Observable<number>;
  private readonly listService: ListService<
    NotificationFilter,
    NotificationDto
  >;

  public constructor(private readonly dataService: NotificationDataService) {
    this.listService = new ListService<NotificationFilter, NotificationDto>(
      {
        status: NotificationFilterStatusEnum.ScheduledAndPending,
      },
      (filter, page) =>
        this.dataService.searchNotifications({
          filter,
          page,
          pageSize: 10,
        })
    );
    this.items$ = this.listService.items$;
    this.totalElements$ = this.listService.totalElements$;
    this.loading$ = this.listService.loading$;
    this.pageNumber$ = this.listService.pageNumber$;
  }

  public setFilter(filter: NotificationFilter): void {
    this.listService.setFilter(filter);
  }

  public setPage(page: number): void {
    this.listService.setPage(page);
  }
}
