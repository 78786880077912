<div>
  <a
    mat-stroked-button
    type="button"
    data-test="discard"
    color="warn"
    routerLink="/post"
  >
    Elvetés
  </a>
  <button
    mat-stroked-button
    type="button"
    data-test="draft"
    color="primary"
    (click)="onDraftClick()"
  >
    Piszkozat mentése
  </button>
</div>
<button mat-raised-button data-test="submit" color="primary">Publikálom</button>
