<div class="header-container">
  <h1 class="campaign-header">OTP Lexikon</h1>
  <div class="campaign-boxes">
    <ng-container *ngIf="todaysWord$ | async as wordOfTheDay">
      <div class="box">
        A nap szava:
        <strong data-test="todaysWord"> {{ wordOfTheDay.word }} </strong>
      </div>
    </ng-container>
    <app-filter-picker
      class="search-field"
      [label]="'Keresés'"
      icon="search"
      [options$]="options$"
      [formControl]="controls.search"
      (searchFor)="onSearchFor($event)"
      (optionSelected)="onPickOption($event)"
    >
    </app-filter-picker>
  </div>
</div>

<web-admin-lexicon-list-filter>
  <web-admin-lexicon-import-button></web-admin-lexicon-import-button>
  <web-admin-lexicon-export-button></web-admin-lexicon-export-button>
  <a
    *ngIf="isActiveUrl('lexicon/lexicon')"
    mat-flat-button
    color="primary"
    routerLink="lexicon/create"
    data-test="create"
  >
    Új szó hozzáadása
  </a>
</web-admin-lexicon-list-filter>

<router-outlet></router-outlet>
