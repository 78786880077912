import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ModalService } from '@web-admin/shared-lib';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class CreateInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly modalService: ModalService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.method !== 'POST' || !/admin\/job$/.test(request.url)) {
      return next.handle(request);
    }
    return this.modalService
      .openConfirmModal({
        title: 'Biztos, hogy feltöltöd az állást?',
        description:
          'Ha mented a bejegyzést a megadott időponttól az éles appban is szerepelni fog.',
        confirmText: 'Feltöltés',
      })
      .afterClosed()
      .pipe(
        filter((x) => x),
        switchMap(() =>
          next.handle(request).pipe(
            tap((result) => {
              if (result.type === HttpEventType.Response) {
                this.snackBar.open('Sikeresen feltöltötted az állást', 'OK', {
                  panelClass: ['snack-bar-container', 'success'],
                  duration: 3000,
                });
                this.router.navigateByUrl('/job');
              }
            }),
            catchError((error) => {
              this.snackBar.open(
                'Nem sikerült feltölteni az állást. Próbáld meg újra.',
                'OK',
                {
                  panelClass: ['snack-bar-container', 'failure'],
                  duration: 3000,
                }
              );
              return of(error);
            })
          )
        )
      );
  }
}
