import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/auth.guard';
import { PageLayoutComponent } from '../layout/page-layout/page-layout.component';
import { CreateCampaignComponent } from './campaign/create/create.component';
import { EditLifestageComponent } from './campaign/edit-lifestage/edit-lifestage.component';
import { EditCampaignComponent } from './campaign/edit/edit.component';
import { CampaignListComponent } from './list/campaign-list/campaign-list.component';
import { GameQuizListComponent } from './list/game-quiz-list/game-quiz-list.component';
import { LifeStageListComponent } from './list/life-stage-list/life-stage-list.component';
import { GameListComponent } from './list/list.component';
import { CreateGameQuizComponent } from './quiz/create/create.component';
import { EditGameQuizComponent } from './quiz/edit/edit.component';
import { CampaignLevelsResolver } from './resolvers/campaign-levels.resolver';
import { ResultComponent } from './result/result.component';

const routes: Routes = [
  {
    path: 'game',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: PageLayoutComponent,
    children: [
      {
        path: '',
        component: GameListComponent,
        children: [
          { path: '', redirectTo: 'campaign', pathMatch: 'full' },
          { path: 'campaign', component: CampaignListComponent },
          { path: 'quiz', component: GameQuizListComponent },
          { path: 'life-stage', component: LifeStageListComponent },
        ],
      },
      {
        path: 'result/:id',
        component: ResultComponent,
      },
      {
        path: 'campaign/create',
        component: CreateCampaignComponent,
      },
      {
        path: 'quiz/create',
        component: CreateGameQuizComponent,
        resolve: {
          levels: CampaignLevelsResolver,
        },
      },
      {
        path: 'campaign/edit/:id',
        component: EditCampaignComponent,
      },
      {
        path: 'life-stage/edit/:lifeStageId/campaign/:id',
        component: EditLifestageComponent,
      },
      {
        path: 'quiz/edit/:id',
        component: EditGameQuizComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CampaignLevelsResolver],
})
export class GameRoutingModule {}
