import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CampaignLevelVM } from '../../models/game-level.vm';
import { GameQuizService } from '../../services/game-quiz.service';
import { GameQuizFormComponent } from '../form/form.component';

@Component({
  selector: 'web-admin-create-game-quiz',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateGameQuizComponent implements OnInit {
  public campaignLevels$: Observable<CampaignLevelVM[]>;

  @ViewChild(GameQuizFormComponent)
  gameQuizFormComponent: GameQuizFormComponent;

  constructor(
    protected readonly gameQuizService: GameQuizService,
    protected readonly snackBar: MatSnackBar,
    protected readonly route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.campaignLevels$ = this.route.data.pipe(map((data) => data.levels));
  }

  public submit(): void {
    const data = this.gameQuizFormComponent.formGroup.value;
    this.gameQuizFormComponent.formGroup.markAllAsTouched();

    if (data && this.gameQuizFormComponent.formGroup.valid) {
      this.gameQuizService.createGameQuiz(data).pipe(take(1)).subscribe();
    } else {
      this.openSnackBar('Töltsd ki az összes mezőt!');
    }
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'X', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 10000,
    });
  }
}
