import { Location } from '@angular/common';
import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NewsFeedEntry } from '@otp-junior/admin-client';
import { ModalService } from '@web-admin/shared-lib';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

function isUpdateNewsFeedEntryRequest(
  request: HttpRequest<unknown>
): request is HttpRequest<NewsFeedEntry> {
  return (
    (request.method === 'PUT' &&
      /admin\/news-feed-entry\/\d+$/.test(request.url)) ||
    (request.method === 'POST' &&
      /admin\/news-feed-entry\/(\d+)\/status/.test(request.url))
  );
}

@Injectable()
export class EditInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly location: Location
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      !isUpdateNewsFeedEntryRequest(request) ||
      this.location.path().includes('/game/quiz/edit/')
    ) {
      return next.handle(request);
    }
    return this.showModalIfNeeded(request).pipe(
      filter((x) => x),
      switchMap(() =>
        next.handle(request).pipe(
          tap((result) => {
            if (result.type === HttpEventType.Response) {
              this.snackBar.open('Sikeresen módosítottad a bejegyzést', 'Ok', {
                panelClass: ['snack-bar-container', 'success'],
                duration: 3000,
              });
              this.router.navigateByUrl('/post');
            }
          }),
          catchError((error) => {
            this.snackBar.open(
              'Nem sikerült módosítani a bejegyzést. Próbáld meg újra.',
              'Ok',
              {
                panelClass: ['snack-bar-container', 'failure'],
                duration: 3000,
              }
            );
            return of(error);
          })
        )
      )
    );
  }

  private showModalIfNeeded(request: HttpRequest<NewsFeedEntry>) {
    if (request.body.status === 'ARCHIVE') {
      return this.modalService
        .openConfirmModal({
          title: 'Biztos, hogy archiválod a bejegyzést?',
          description:
            'Ha archiválod a bejegyzést az nem lesz látható az éles appban.',
          confirmText: 'Archiválás',
        })
        .afterClosed();
    }
    if (request.body.draft) {
      return of(true);
    }
    return this.modalService
      .openConfirmModal({
        title: 'Biztos, hogy feltöltöd a bejegyzést?',
        description:
          'Ha mented a bejegyzést a megadott időponttól az éles appban is szerepelni fog.',
        confirmText: 'Módosítás',
      })
      .afterClosed();
  }
}
