import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewsFeedEntry, PostDataService } from '@otp-junior/admin-client';
import { Observable } from 'rxjs';
import { AbstractCreate } from '../abstract-create';
import { ArticleVM } from './article.interface';

@Component({
  selector: 'web-admin-post-create-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCreateArticleComponent extends AbstractCreate<ArticleVM> {
  protected objectType: 'ArticleDto' = 'ArticleDto';

  public constructor(
    readonly postDataService: PostDataService,
    readonly snackBar: MatSnackBar
  ) {
    super(postDataService, snackBar);
  }

  // We override this method to add the custom parameters not existing in others
  protected sendCreation(
    data: ArticleVM,
    draft = false
  ): Observable<NewsFeedEntry> {
    const { lexiconWords, ...restOfData } = data;
    return this.postDataService.createNewsFeedEntry({
      objectType: this.objectType,
      draft,
      // readonly should be sent as null
      /* eslint-disable unicorn/no-null */
      type: null,
      id: null,
      status: null,
      lexiconWordIds: lexiconWords.map((word) => word.connectingLexiconWord.id),
      /* eslint-enable unicorn/no-null */
      ...restOfData,
    });
  }
}
