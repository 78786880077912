import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { EventDto, PostDataService } from '@otp-junior/admin-client';
import { AbstractEdit } from '../abstract-edit';

@Component({
  selector: 'web-admin-post-edit-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostEditEventComponent extends AbstractEdit<EventDto> {
  protected objectType: 'EventDto' = 'EventDto';

  public constructor(
    readonly postDataService: PostDataService,
    readonly activatedRoute: ActivatedRoute,
    readonly snackBar: MatSnackBar
  ) {
    super(postDataService, activatedRoute, snackBar);
  }
}
