import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TypeIconComponent } from './components/type-icon/type-icon.component';
import { FilterPickerComponent } from './filter-picker.component';
import { HighlightValueInTextPipe } from './highlight-value-in-text.pipe';

@NgModule({
  declarations: [
    FilterPickerComponent,
    HighlightValueInTextPipe,
    TypeIconComponent,
  ],
  imports: [
    OverlayModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  exports: [FilterPickerComponent],
})
export class FilterPickerModule {}
