<div class="uploader">
  <img src="assets/icons/upload_document.svg" alt="Feltöltés icon" />
  <div class="properties">
    <h3>
      {{
        (uploadedFileName$ | async)
          ? (uploadedFileName$ | async)
          : 'Töltsd fel a nyilatkozatot'
      }}
    </h3>
    <div class="details" *ngIf="(uploadedFileName$ | async) === ''">
      <strong>Feltölthető:</strong> pdf formátum <br />
      <strong>Maximális fájlméret:</strong> {{ maxSizeInMB }}MB
    </div>
  </div>
</div>
<div class="buttons">
  <button mat-button color="primary" (click)="input.click()">
    <input type="file" (change)="onUploadFile()" #input />
    {{ (uploadedFileName$ | async) ? 'Fájl cseréje' : 'Fájl tallózása' }}
  </button>
  <button
    *ngIf="uploadedFileName$ | async"
    mat-button
    color="warn"
    class="uploadedFileDeleteButton"
    (click)="onUploadedFileDeleteClick()"
  >
    Fájl törlése
  </button>
</div>
