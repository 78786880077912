import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DiscountDto, PostDataService } from '@otp-junior/admin-client';
import { AbstractCreate } from '../abstract-create';

@Component({
  selector: 'web-admin-post-create-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCreateDiscountComponent extends AbstractCreate<DiscountDto> {
  protected objectType: 'DiscountDto' = 'DiscountDto';

  public constructor(
    readonly postDataService: PostDataService,
    readonly snackBar: MatSnackBar
  ) {
    super(postDataService, snackBar);
  }
}
