<ng-container *ngFor="let control of array.controls; let index = index">
  <ng-container
    *ngTemplateOutlet="
      template?.template;
      context: {
        $implicit: control,
        index: index
      }
    "
  ></ng-container>
</ng-container>
