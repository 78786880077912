<h1>Meghirdetett állások</h1>
<web-admin-job-list-filter [formControl]="filterControl">
  <web-admin-create-job-drop-down></web-admin-create-job-drop-down>
</web-admin-job-list-filter>
<ng-container *ngIf="(loading$ | async) === false">
  <ng-container *ngIf="list$ | async as list">
    <ng-container *ngIf="list.length > 0; else notFound">
      <table mat-table [dataSource]="list" aria-label="Munkák lista">
        <ng-container matColumnDef="icon">
          <th id="icon-header" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <web-admin-job-type-icon
              [type]="element.type"
            ></web-admin-job-type-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th id="title-header" mat-header-cell *matHeaderCellDef>Cím</th>
          <td mat-cell *matCellDef="let element">
            <strong>{{ element.name }}</strong>
          </td>
        </ng-container>
        <ng-container matColumnDef="pin">
          <th id="pin-header" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="pin">
            <web-admin-job-pin
              [pinned]="element.pinned"
              (click)="onPinClicked(element)"
            ></web-admin-job-pin>
          </td>
        </ng-container>
        <ng-container matColumnDef="publish">
          <th id="publish-header" mat-header-cell *matHeaderCellDef>Publikálás ideje</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.startDate">
              {{ element.startDate | date: 'short' }}-tól
            </ng-container>
            <ng-container *ngIf="element.endDate">
              {{ element.endDate | date: 'short' }}-ig
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="reactions">
          <th id="reactions-header" mat-header-cell *matHeaderCellDef class="right">Elérés</th>
          <td mat-cell *matCellDef="let element">
            {{ element.viewCount }} megtekintés
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th id="status-header" mat-header-cell *matHeaderCellDef class="right">Státusz</th>
          <td mat-cell *matCellDef="let element">
            <web-admin-job-status-icon
              [status]="element.status"
            ></web-admin-job-status-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th id="edit-header" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="right">
            <a mat-button color="primary" [routerLink]="getRouterLink(element)">
              <img src="assets/icons/edit.svg" alt="Szerkesztés icon"/>
              Szerkesztés
            </a>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageIndex]="pageNumber$ | async"
        [length]="count$ | async"
        [pageSize]="10"
        [pageSizeOptions]="[10]"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #notFound>
  <shared-list-empty>Nincsenek állások ebben a státuszban.</shared-list-empty>
</ng-template>
