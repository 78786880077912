import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CampaignLevelVM, GameLevelVM } from '../../models/game-level.vm';
import {
  GameQuizDetailsVM,
  GameQuizQuestion,
} from '../../models/game-quiz-details.vm';

export const snackBar = 'snack-bar-container';

export interface GameQuizForm {
  campaignId: number;
  questions: GameQuizFormQuestion[];
  levelId: number;
}

export interface GameQuizFormQuestion {
  imageId?: string;
  question: string;
  choices: string[];
}

@Component({
  selector: 'web-admin-game-quiz-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameQuizFormComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  @Input() public campaignLevels: CampaignLevelVM[];
  @Input() public gameQuizDetails?: GameQuizDetailsVM;

  @Output() public levelChange = new EventEmitter<number>();

  public formGroup: FormGroup;
  public isEditing = false;

  public ngOnInit(): void {
    this.isEditing = !!this.gameQuizDetails;

    this.formGroup = new FormGroup({
      campaignId: new FormControl(
        {
          value: this.gameQuizDetails
            ? this.gameQuizDetails.campaignId
            : undefined,
          disabled: !!this.gameQuizDetails,
        },
        Validators.required
      ),
      levelId: new FormControl(
        {
          value: this.gameQuizDetails
            ? this.gameQuizDetails.levelId
            : undefined,
          disabled: !!this.gameQuizDetails,
        },
        Validators.required
      ),
      questions: new FormArray([]),
    });

    if (this.gameQuizDetails) {
      this.addQuestions(this.gameQuizDetails.questions);
    } else {
      this.addQuestion();
    }

    this.campaignControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        // eslint-disable-next-line unicorn/no-useless-undefined
        this.levelIdControl.setValue(undefined);
      });
  }

  public addQuestions(questions?: GameQuizQuestion[]): void {
    for (const question of questions) this.addQuestion(question);
  }

  public addQuestion(question?: GameQuizQuestion): void {
    (this.formGroup.get('questions') as FormArray).push(
      new FormGroup({
        imageId: new FormControl(question ? question.imageId : undefined),
        question: new FormControl(question ? question.question : '', [
          Validators.required,
        ]),
        choices: new FormArray([
          new FormControl(question ? question.choices[0].value : '', [
            Validators.required,
          ]),
          new FormControl(question ? question.choices[1].value : '', [
            Validators.required,
          ]),
          // eslint-disable-next-line no-magic-numbers
          new FormControl(question ? question.choices[2].value : '', [
            Validators.required,
          ]),
          // eslint-disable-next-line no-magic-numbers
          new FormControl(question ? question.choices[3].value : '', [
            Validators.required,
          ]),
        ]),
      })
    );
  }

  public getLevelsByCampaign(campaignId: number): GameLevelVM[] {
    return this.campaignLevels?.find((cl) => cl.campaignId === campaignId)
      ?.gameLevels;
  }

  public removeQuestion(id: number): void {
    (this.formGroup.get('questions') as FormArray).removeAt(id);
  }

  public getAnswers(questionForm: FormGroup): FormControl[] {
    return (questionForm.get('choices') as FormArray).controls as FormControl[];
  }

  public get campaignControl(): FormControl {
    return this.formGroup?.get('campaignId') as FormControl;
  }

  public get levelIdControl(): FormControl {
    return this.formGroup.get('levelId') as FormControl;
  }

  public get questions(): FormGroup[] {
    return (this.formGroup.get('questions') as FormArray)
      .controls as FormGroup[];
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
