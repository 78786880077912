import {
  ChangeDetectorRef,
  Directive,
  HostListener,
  Inject,
  Input,
} from '@angular/core';
import { MatTabLink } from '@angular/material/tabs';
import { Changeable, CHANGEABLE } from './changeable';

@Directive({
  selector: '[mat-tab-link][value]',
})
export class MatTabFormDirective {
  @HostListener('click') public onClick(): void {
    this.matTabForm.changed(this.value);
  }
  @Input() public value: unknown;
  public constructor(
    @Inject(CHANGEABLE) public matTabForm: Changeable,
    public matTabLink: MatTabLink,
    public cdr: ChangeDetectorRef
  ) {}
}
