/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable unicorn/no-null */
/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CouponLibraryDataService,
  NewsFeedEntryStatus,
  PostDataService,
  StepType,
} from '@otp-junior/admin-client';
import { shouldBeAfter } from '@web-admin/shared-lib';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

export interface SurveyQuestion {
  type: StepType;
  question: string;
  choices: string[];
  imageId?: string;
  multiple?: boolean;
  none?: boolean;
}

export enum QuestionType {
  SINGLE = 'Felelet választós',
  SLIDER = 'Skála',
  FREETEXT = 'Szabad szavas',
}

export const snackBar = 'snack-bar-container';

@Component({
  selector: 'web-admin-survey-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit {
  @Input() public edit: number;
  public form: FormGroup;
  public questionTypes: { key: string; label: string }[];
  protected readonly subscription = new Subscription();
  public fileInputLabel: string;
  public status: NewsFeedEntryStatus;
  public couponId: number;
  public couponCodesAlreadyUploaded$ = new BehaviorSubject<boolean>(false);
  public fileUploadForm: FormGroup;
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;

  constructor(
    protected readonly snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public readonly couponApi: CouponLibraryDataService,
    protected readonly postDataService: PostDataService
  ) {
    this.fileUploadForm = this.formBuilder.group({
      myfile: [''],
    });
    this.form = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      marketingContent: new FormControl(false),
      startDate: new FormControl(undefined, [Validators.required]),
      endDate: new FormControl(undefined),
      steps: new FormArray([]),
      imageId: new FormControl(undefined, [Validators.required]),
      couponImageId: new FormControl(undefined),
      couponDescription: new FormControl(undefined),
    });
    this.addQuestion();
    this.questionTypes = Object.entries(QuestionType).map(([key, value]) => ({
      key,
      label: value,
    }));
    this.form.controls.endDate.setValidators([
      Validators.required,
      shouldBeAfter(this.form.controls.startDate),
    ]);
    this.subscription.add(
      this.form.controls.startDate.valueChanges.subscribe(() =>
        this.form.controls.endDate.updateValueAndValidity()
      )
    );
  }

  ngOnInit(): void {
    if (this.edit) {
      this.postDataService.getNewsFeedEntryById(this.edit).subscribe((data) => {
        const incomingData: any = data;
        this.status = data.status;
        this.form.controls.marketingContent.setValue(
          incomingData.marketingContent
        );
        this.form.controls.startDate.setValue(incomingData.startDate);
        this.form.controls.endDate.setValue(incomingData.endDate);
        this.form.controls.imageId.setValue(incomingData.imageId);
        this.form.controls.title.setValue(incomingData.title);
        this.form.controls.description.setValue(incomingData.description);
        if (incomingData.coupon) {
          this.couponId = incomingData.coupon.id;
          this.form.controls.couponDescription.setValue(
            incomingData.coupon.description
          );
          this.form.controls.couponImageId.setValue(
            incomingData.coupon.imageId
          );
          this.couponApi
            .getCoupon(incomingData.coupon.id)
            // eslint-disable-next-line rxjs/no-nested-subscribe
            .subscribe((result) => {
              this.fileUploadForm.get('myfile').setValue(result);
              this.couponCodesAlreadyUploaded$.next(
                result.hasUnusedCodes || false
              );
            });
        }
        this.removeQuestion(0);
        for (let index = 0; index < incomingData.steps.length; index++) {
          this.addQuestion(incomingData.steps[index], index);
        }
      });
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public addQuestion(data?: any, indexNumber?: number): void {
    (this.form.get('steps') as FormArray).push(
      new FormGroup({
        type: new FormControl(data ? this.typeDecide(data.type) : 'SINGLE'),
        // eslint-disable-next-line unicorn/no-null
        imageId: new FormControl(data ? data.imageId : null),
        // eslint-disable-next-line unicorn/no-null
        question: new FormControl(data ? data.question : null, [
          Validators.required,
        ]),
        choices: new FormArray([
          new FormControl(data && data.choices[0] ? data.choices[0].value : ''),
          new FormControl(data && data.choices[1] ? data.choices[1].value : ''),
        ]), //TODO validation
        multiple: new FormControl(data && data.type == 'MULTI' ? true : false),
        none: new FormControl(
          data && data.choices.length > 0
            ? this.noneDecide(data.choices)
            : false
        ),
      })
    );
    if (data && data.choices.length > 2) {
      for (let index = 2; index < data.choices.length; index++) {
        data.choices[index].value == 'Egyik sem.'
          ? data.choices.splice(-1, 1)
          : this.addAnswer(indexNumber, data.choices[index]);
      }
    }
  }

  public typeDecide(type: string): string {
    return type == 'MULTI' || type == 'STRING' ? 'SINGLE' : type;
  }

  public noneDecide(choices: any[]): boolean {
    for (const choice of choices) {
      if (choice.value == 'Egyik sem.') {
        return true;
      }
    }
  }

  public couponDelete() {
    this.couponApi
      .updateCoupon(this.couponId, {
        id: this.couponId,
        description: null,
        imageId: null,
        surveyId: this.edit,
      })
      .subscribe();
    this.couponApi.deleteCoupon(this.couponId).subscribe(() => {
      this.couponId = undefined;
      this.snackBar.open('Sikeres kupontörlés.', 'X', {
        duration: 3000,
        panelClass: [snackBar, 'success'],
      });
      this.couponCodesAlreadyUploaded$.next(false);
      window.location.reload();
    });
  }

  public saveCoupon() {
    const description =
      this.form.controls.couponDescription.value == ''
        ? undefined
        : this.form.controls.couponDescription.value;
    const file = this.fileUploadForm.get('myfile').value;

    if (!this.fileInputLabel) {
      this.snackBar.open('Még nem töltöttél fel fájlt a kódokkal!', 'X', {
        duration: 3000,
        panelClass: [snackBar, 'failure'],
      });
      return;
    }

    if (this.couponId) {
      this.couponApi
        .uploadCouponCodes(this.couponId, file, 'response')
        .pipe(
          catchError((error) => {
            this.snackBar.open('A kuponkódok feltöltése sikertelen.', 'X', {
              duration: 3000,
              panelClass: [snackBar, 'failure'],
            });
            return of(error);
          })
        )
        .subscribe((response) => {
          if (response.ok) {
            this.snackBar.open('Sikeres kuponkód feltöltés.', 'X', {
              duration: 3000,
              panelClass: [snackBar, 'success'],
            });
          }
        });
      return;
    }

    this.couponApi
      .createCoupon({
        id: undefined,
        description: description,
        imageId: this.form.controls.couponImageId.value,
        surveyId: this.edit,
      })
      .pipe(
        tap((response) => {
          this.couponId = response.id;
        }),
        switchMap((response) =>
          this.couponApi.uploadCouponCodes(response.id, file, 'response')
        ),
        catchError((error) => {
          this.snackBar.open('A kuponkódok feltöltése sikertelen.', 'X', {
            duration: 3000,
            panelClass: [snackBar, 'failure'],
          });
          return of(error);
        })
      )
      .subscribe((response) => {
        if (response.ok) {
          this.snackBar.open('Sikeres kuponkód feltöltés.', 'X', {
            duration: 3000,
            panelClass: [snackBar, 'success'],
          });
        }
      });
  }

  public addAnswer(
    index: number,
    value?: { value: string; type: string }
  ): void {
    const maxAnswerCount = 10;
    const formArray = (this.form.get('steps') as FormArray)
      .at(index)
      .get('choices') as FormArray;
    if (formArray.length < maxAnswerCount) {
      formArray.push(new FormControl(value ? value.value : '')); //TODO validation
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getForm(): any {
    return this.form.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onFileSelect(event: any) {
    const af = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type == af[0] || file.type == af[1]) {
        this.fileInputLabel = file.name;
        this.fileUploadForm.get('myfile').setValue(file);
      } else {
        this.snackBar.open('A file típusának excelnek kell lennie.', 'X', {
          duration: 3000,
          panelClass: [snackBar, 'failure'],
        });
      }
    }
    this.onCouponSubmit();
  }

  public onCouponSubmit() {
    return {
      imageId: this.form.controls.couponImageId.value,
      description: this.form.controls.couponDescription.value,
    };
  }
  public onCouponExcel() {
    return this.fileUploadForm.get('myfile').value;
  }

  public removeAnswer(index: number, answerIndex: number): void {
    const minAnswerCount = 2;
    const formArray = (this.form.get('steps') as FormArray)
      .at(index)
      .get('choices') as FormArray;
    if (formArray.length > minAnswerCount) {
      formArray.removeAt(answerIndex); //TODO validation
    } else {
      this.snackBar.open(
        'Legalább két válasz lehetőségnek maradnia kell!',
        'X',
        {
          duration: 3000,
          panelClass: [snackBar, 'failure'],
        }
      );
    }
  }

  public removeQuestion(id: number): void {
    (this.form.get('steps') as FormArray).removeAt(id);
  }

  public get title(): FormControl {
    return this.form.get('title') as FormControl;
  }

  public get description(): FormControl {
    return this.form.get('description') as FormControl;
  }

  public get marketingContent(): FormControl {
    return this.form.get('marketingContent') as FormControl;
  }

  public get startDate(): FormControl {
    return this.form.get('startDate') as FormControl;
  }

  public get endDate(): FormControl {
    return this.form.get('endDate') as FormControl;
  }

  public get steps(): FormGroup[] {
    return (this.form.get('steps') as FormArray).controls as FormGroup[];
  }

  public getAnswers(questionForm: FormGroup): FormControl[] {
    return (questionForm.get('choices') as FormArray).controls as FormControl[];
  }
}
