import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeclarationListType } from '../list-element.vm';
import { DeclarationListService } from '../list.service';

export interface NewDeclarationModalData {
  title: string;
}

@Component({
  selector: 'web-admin-new-declaration-modal',
  templateUrl: './new-declaration-modal.component.html',
  styleUrls: ['./new-declaration-modal.component.scss'],
})
export class NewDeclarationModalComponent {
  public readonly data: NewDeclarationModalData;

  public typeControl = new FormControl(undefined, [Validators.required]);
  public startDateTimeControl = new FormControl(undefined, [
    Validators.required,
  ]);
  public fileInputControl = new FormControl(undefined, [Validators.required]);

  public types = Object.values(DeclarationListType).filter((value) => !!value);

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: NewDeclarationModalData,
    private readonly listService: DeclarationListService,
    public dialogReference: MatDialogRef<NewDeclarationModalComponent>,
    private readonly snackBar: MatSnackBar
  ) {
    this.data = data;
  }

  public close(value: boolean): void {
    this.dialogReference.close(value);
  }

  public upload(): void {
    if (
      this.typeControl.invalid ||
      this.startDateTimeControl.invalid ||
      this.fileInputControl.invalid
    ) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }
    this.listService.uploadConsent(
      this.typeControl.value,
      this.startDateTimeControl.value,
      this.fileInputControl.value
    );
    this.close(true);
  }
}
