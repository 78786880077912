import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EmploymentType, JobDataService } from '@otp-junior/admin-client';
import { JobFormData } from '../form/form.component';
import { routeTypeMap } from '../helpers/route-type.mapper';
import { typeList } from '../helpers/type-name.list';

@Component({
  selector: 'web-admin-job-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobCreateComponent {
  public isVolunteer: boolean;
  public control = new FormControl();
  public form = new FormGroup({ data: this.control });
  public jobTypeName: string;
  public createSwitchLink: string;
  public constructor(
    protected readonly jobDataService: JobDataService,
    private readonly router: Router,
    protected readonly snackBar: MatSnackBar
  ) {
    const jobType = this.calculateEmploymentType();
    this.isVolunteer = jobType === EmploymentType.Volunteer;
    this.jobTypeName = typeList
      .find((x) => x.value === jobType)
      .name.toLocaleLowerCase();
  }
  public submit(): void {
    const data = this.form.value.data;
    if (this.createSwitchLink === 'link') {
      data.emailBody = undefined;
      data.emailSubject = undefined;
      data.emailTo = undefined;
    } else if (this.createSwitchLink === 'email') {
      data.applicationUrl = undefined;
    }
    if (!data || this.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }
    this.sendCreation(data).subscribe(() => this.form.markAsPristine());
  }

  private sendCreation(data: JobFormData) {
    return this.jobDataService.createJob({
      // readonly should be sent as null
      /* eslint-disable unicorn/no-null */
      id: null,
      status: null,
      pinned: null,
      viewCount: null,
      /* eslint-enable unicorn/no-null */
      employmentType: this.calculateEmploymentType(),
      ...data,
    });
  }

  private calculateEmploymentType(): EmploymentType {
    return routeTypeMap(this.router.url);
  }
}
