import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/auth.guard';
import { LeaveGuard } from '../../core/leave.guard';
import { PageLayoutComponent } from '../layout/page-layout/page-layout.component';
import { QuizCreateComponent } from './create/create.component';
import { QuizEditComponent } from './edit/edit.component';
import { ResultComponent } from './result/result.component';

const routes: Routes = [
  {
    path: 'quiz',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: PageLayoutComponent,
    children: [
      {
        path: 'create',
        component: QuizCreateComponent,
        canDeactivate: [LeaveGuard],
      },
      {
        path: 'result/:type/:id',
        component: ResultComponent,
      },
      {
        path: 'edit/:type',
        component: QuizEditComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuizRoutingModule {}
