import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AdminDataService,
  MockLoginDataService,
} from '@otp-junior/admin-client';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { AppConfig } from '../config/app.config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public constructor(
    private readonly adminDataService: AdminDataService,
    private readonly mockLoginDataService: MockLoginDataService,
    private readonly appConfig: AppConfig,
    private readonly router: Router
  ) {
    this.redirectToExternalLoginIfNeeded();
  }

  public redirectToExternalLoginIfNeeded(): void {
    if (!this.appConfig.getConfig('mockLogin')) {
      this.isLoggedIn.subscribe((isLoggedIn) => {
        if (!isLoggedIn) {
          this.redirectToExternalLogin();
        }
      });
    }
  }

  public login(email: string, password: string): void {
    if (this.appConfig.getConfig('mockLogin')) {
      this.mockLoginDataService
        .mockLogin(email, password)
        .pipe(
          mapTo(true),
          catchError(() => {
            return of(false);
          })
        )
        .subscribe((success) => {
          if (success) {
            this.router.navigateByUrl('/analytics');
          }
        });
    } else {
      this.redirectToExternalLogin();
    }
  }

  public get isLoggedIn(): Observable<boolean> {
    return this.adminDataService.getCurrentAdminProfile().pipe(
      mapTo(true),
      catchError(() => of(false))
    );
  }

  private redirectToExternalLogin() {
    window.location.href = this.appConfig.getConfig('samlRedirectUrl');
  }
}
