<div>
  <div class="first-row">
    <div mat-tab-nav-bar>
      <a
        mat-tab-link
        *ngFor="let tab of tabs"
        [routerLink]="tab.route"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
        [attr.data-test]="'tab-' + tab.name"
      >
        {{ tab.name }}
      </a>
    </div>
    <div class="content-container">
      <ng-content></ng-content>
    </div>
  </div>
</div>
