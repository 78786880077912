import {
  Campaign,
  CampaignStatusEnum,
  LifeStageRequest,
  LifeStageResponse,
} from '@otp-junior/admin-client';
import { CampaignListStatus, CampaignVM } from '../models/campaign.vm';

export const mapToCampaignVM = (
  campaign: Campaign,
  lifeStageId?: number
): CampaignVM => ({
  id: campaign.id,
  name: campaign.name,
  startDate: campaign.startDate,
  endDate: campaign.endDate,
  status: mapCampaignStatus(campaign.status),
  lifeStages: campaign.lifeStages.filter(
    (lifeStage) => lifeStage.id === lifeStageId
  ),
});

export const mapLifeStageToLifeStageRequest = (
  lifeStage: LifeStageResponse
): LifeStageRequest => {
  return {
    id: lifeStage.id,
    scheduledAt: lifeStage.scheduledAt,
  };
};

export const mapCampaignStatus = (
  status: CampaignStatusEnum
): CampaignListStatus => {
  switch (status) {
    case CampaignStatusEnum.Active:
      return CampaignListStatus.ACTIVE;
    case CampaignStatusEnum.Scheduled:
      return CampaignListStatus.SCHEDULED;
    case CampaignStatusEnum.Archive:
      return CampaignListStatus.ARCHIVE;
  }
};
