import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PostCategoryDto } from '@otp-junior/admin-client';
import { CreateCategoryModalComponent } from '../modal/modal.component';

@Component({
  selector: 'web-admin-create-category-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class CreateCategoryButtonComponent {
  @Output() created = new EventEmitter<number>();
  public constructor(private readonly dialog: MatDialog) {}

  public onClick(): void {
    this.dialog
      .open<CreateCategoryModalComponent, unknown, PostCategoryDto>(
        CreateCategoryModalComponent
      )
      .afterClosed()
      .subscribe((data) => this.created.next(data.id));
  }
}
