<form [formGroup]="formGroup">
  <shared-form-card>
    <h2>Kvíz beállítása</h2>
    <div class="question-type">
      <mat-form-field class="campaign-selector">
        <mat-label>Válaszd ki kampányt</mat-label>
        <mat-select formControlName="campaignId">
          <mat-option
            *ngFor="let campaign of campaignLevels"
            [value]="campaign.campaignId"
          >
            {{ campaign.campaignTitle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="question-type">
      <mat-form-field class="stage-selector">
        <mat-label>Válaszd ki a pályát</mat-label>
        <mat-select formControlName="levelId">
          <mat-option
            *ngFor="let level of getLevelsByCampaign(campaignControl.value)"
            [value]="level.id"
          >
            {{ level.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </shared-form-card>

  <shared-form-card>
    <h2>Bejegyzés</h2>
    <ng-container
      formArrayName="questions"
      *ngFor="let questionForm of questions; let i = index"
    >
      <shared-form-card
        class="full-width inner-card"
        [formGroup]="questionForm"
      >
        <div class="header">
          <h2>{{ i + 1 }}. kérdés</h2>
          <a
            mat-button
            type="button"
            color="warn"
            (click)="removeQuestion(i)"
            *ngIf="questions.length > 1"
          >
            <mat-icon>delete</mat-icon>
            Kérdés törlése
          </a>
        </div>
        <p class="hint">Kérdés képe <span>(nem kötelező)</span></p>
        <web-admin-file-picker
          formControlName="imageId"
          [erasable]="true"
          titleText="Töltsd fel a kérdés indexképét"
        ></web-admin-file-picker>
        <mat-form-field>
          <mat-label>Kérdés szövege</mat-label>
          <textarea
            matInput
            formControlName="question"
            cdkTextareaAutosize
          ></textarea>
          <mat-error *showError></mat-error>
        </mat-form-field>

        <ng-container *ngIf="getAnswers(questionForm) as choices">
          <div class="answer-box">
            <mat-form-field class="answer">
              <mat-label class="label">
                <img
                  src="../../../../assets/survey/green-tick.svg"
                  alt="Zöld pipa"
                />
                Helyes válasz</mat-label
              >
              <textarea
                matInput
                [formControl]="choices[0]"
                maxlength="100"
                cdkTextareaAutosize
              ></textarea>
              <mat-hint align="end">
                {{ choices[0].value?.length || 0 }}/{{ 100 }}
              </mat-hint>
              <mat-error *showError></mat-error>
            </mat-form-field>
          </div>
          <div class="answer-box">
            <mat-form-field class="answer">
              <mat-label class="label">
                <img
                  src="../../../../assets/survey/red-cross.svg"
                  alt="Piros X"
                />Hibás válasz 1.</mat-label
              >
              <textarea
                matInput
                [formControl]="choices[1]"
                maxlength="100"
                cdkTextareaAutosize
              ></textarea>
              <mat-hint align="end">
                {{ choices[1].value?.length || 0 }}/{{ 100 }}
              </mat-hint>
              <mat-error *showError></mat-error>
            </mat-form-field>
          </div>
          <div class="answer-box">
            <mat-form-field class="answer">
              <mat-label class="label"
                ><img
                  src="../../../../assets/survey/red-cross.svg"
                  alt="Piros X"
                />Hibás válasz 2.</mat-label
              >
              <textarea
                matInput
                [formControl]="choices[2]"
                maxlength="100"
                cdkTextareaAutosize
              ></textarea>
              <mat-hint align="end">
                {{ choices[2].value?.length || 0 }}/{{ 100 }}
              </mat-hint>
              <mat-error *showError></mat-error>
            </mat-form-field>
          </div>
          <div class="answer-box">
            <mat-form-field class="answer">
              <mat-label class="label"
                ><img
                  src="../../../../assets/survey/red-cross.svg"
                  alt="Piros X"
                />Hibás válasz 3.</mat-label
              >
              <textarea
                matInput
                [formControl]="choices[3]"
                maxlength="100"
                cdkTextareaAutosize
              ></textarea>
              <mat-hint align="end">
                {{ choices[3].value?.length || 0 }}/{{ 100 }}
              </mat-hint>
              <mat-error *showError></mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </shared-form-card>
    </ng-container>
    <a
      mat-stroked-button
      type="button"
      color="primary"
      class="add"
      (click)="addQuestion()"
    >
      <mat-icon>add</mat-icon>
      Még egy kérdés hozzáadása
    </a>
  </shared-form-card>
</form>
