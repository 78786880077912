import { NgModule } from '@angular/core';
import { FormGroupPassDirective } from './form-group-pass.directive';
import { ShowErrorDirective } from './show-error.directive';

@NgModule({
  declarations: [ShowErrorDirective, FormGroupPassDirective],
  exports: [ShowErrorDirective, FormGroupPassDirective],
})
export class MaterialErrorModule {}
export * from './error-message-provider';
