import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortInitialStateDirective } from './mat-sort-initial-state.directive';

@NgModule({
  declarations: [MatSortInitialStateDirective],
  imports: [CommonModule],
  exports: [MatSortInitialStateDirective],
})
export class MaterialSortInitialStateModule {}
