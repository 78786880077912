/* eslint-disable sonarjs/no-duplicate-string */
import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LexiconDataService } from '@otp-junior/admin-client';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'web-admin-lexicon-import-button',
  templateUrl: './import-button.component.html',
  styleUrls: ['./import-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportButtonComponent {
  @ViewChild('input', { static: true })
  public input: ElementRef<HTMLInputElement>;
  @Output() public importLoading = new EventEmitter<boolean>();
  public uploading = false;
  public file?: Blob;

  public onChange: (value: Blob) => void;
  public onTouched: () => void;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public writeValue(file: Blob): void {
    this.file = file;
  }

  public registerOnChange(function_: (value: Blob) => void): void {
    this.onChange = function_;
  }
  public registerOnTouched(function_: () => void): void {
    this.onTouched = function_;
  }

  public constructor(
    @Self() @Optional() private readonly ngControl: NgControl,
    private readonly lexiconDataService: LexiconDataService,
    private readonly snackBar: MatSnackBar
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public onImportClicked(): void {
    this.importLoading.emit(true);
    this.uploading = true;
    const selectedFile = this.input.nativeElement.files.item(0);
    if (
      selectedFile &&
      selectedFile.type !== 'application/vnd.ms-excel' &&
      selectedFile.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.importLoading.emit(false);
      this.uploading = false;
      this.snackBar.open('A file típusának xls/xlsx-nek kell lennie.', 'X', {
        duration: 3000,
        panelClass: ['snack-bar-container', 'failure'],
      });
      return;
    }

    const file = this.uploadXlsFile(selectedFile);
    if (this.onChange) {
      this.onChange(selectedFile);
    }

    file.subscribe((response) => {
      this.importLoading.emit(false);
      this.uploading = false;
      if (response.ok) {
        this.snackBar.open('A fájl feltöltése sikeres.', 'X', {
          duration: 3000,
          panelClass: ['snack-bar-container', 'success'],
        });
      }
    });
  }

  private uploadXlsFile(file: File): Observable<HttpResponse<unknown>> {
    return this.lexiconDataService.importLexicon(file, 'response').pipe(
      catchError((error) => {
        this.snackBar.open('A fájl feltöltése sikertelen.', 'X', {
          duration: 3000,
          panelClass: ['snack-bar-container', 'failure'],
        });
        return of(error);
      })
    );
  }
}
