import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  DeclarationListElementVM,
  DeclarationListStatus,
  DeclarationListType,
} from '../list-element.vm';
import { DeclarationListService } from '../list.service';
import { NewDeclarationModalComponent } from '../new-declaration-modal/new-declaration-modal.component';

@Component({
  selector: 'web-admin-declaration-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeclarationListComponent implements OnInit {
  public count$: Observable<number>;
  public loading$: Observable<boolean>;
  public list$: Observable<DeclarationListElementVM[]>;
  public displayedColumns$: Observable<string[]>;
  public pageNumber$: Observable<number>;

  public filterControl = new FormControl();

  public declarationListStatus = DeclarationListStatus;
  public declarationListType = DeclarationListType;

  public types = Object.values(DeclarationListType).filter((value) => !!value);

  public constructor(
    private readonly listService: DeclarationListService,
    public dialog: MatDialog,
    public http: HttpClient
  ) {}

  public ngOnInit(): void {
    this.listService.filterState$
      .pipe(first())
      .subscribe((filter) =>
        this.filterControl.patchValue(filter.type, { emitEvent: false })
      );
    this.filterControl.valueChanges.subscribe((filter) =>
      this.listService.setFilter(filter)
    );
    this.list$ = this.listService.items$;
    this.count$ = this.listService.totalElements$;
    this.pageNumber$ = this.listService.pageNumber$;
    this.loading$ = this.listService.loading$;
    this.displayedColumns$ = of([
      'type',
      'availabilityStartDate',
      'status',
      'download',
      'delete',
    ]);
  }

  public onPageChange(event: PageEvent): void {
    this.listService.setPage(event.pageIndex);
  }

  public onDeleteClick(id: number): void {
    this.listService.deleteConsent(id);
  }

  public onDownloadClick(
    fileUrl: string,
    type: DeclarationListType,
    startDateTime: string
  ): void {
    this.http.get(fileUrl, { responseType: 'blob' }).subscribe((response) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      document.body.append(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = this.createFileName(type, startDateTime);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  public onNewDeclarationClick(): void {
    this.dialog.open(NewDeclarationModalComponent, {
      width: '600px',
      data: { title: 'title' },
    });
  }

  private createFileName(
    type: DeclarationListType,
    startDateTime: string
  ): string {
    return `${this.mapDeclarationTypeToFileName(type)}_${startDateTime}.pdf`;
  }

  private mapDeclarationTypeToFileName(type: DeclarationListType): string {
    switch (type) {
      case DeclarationListType.Marketing:
        return 'marketing';

      case DeclarationListType.Contract:
        return 'altalanos_szerzodesi_feltetelek';

      case DeclarationListType.Participation:
        return 'reszveteli_szabalyzat';

      case DeclarationListType.GameRules:
        return 'jatekszabalyzat';

      default:
        return '';
    }
  }
}
