/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CouponLibraryDataService,
  NewsFeedEntry,
  NewsFeedEntryStatus,
  PostDataService,
} from '@otp-junior/admin-client';
import { FormComponent } from '../form/form.component';

@Component({
  selector: 'web-admin-survey-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyCreateComponent<TEntry extends NewsFeedEntry> {
  protected objectType: TEntry['objectType'];
  public control = new FormControl();
  public form = new FormGroup({ data: this.control });
  public status: NewsFeedEntryStatus;
  @ViewChild(FormComponent) child: FormComponent;
  constructor(
    protected readonly snackBar: MatSnackBar,
    protected readonly postDataService: PostDataService,
    public readonly couponApi: CouponLibraryDataService
  ) {
    this.objectType = 'SurveyDto';
    this.status = NewsFeedEntryStatus.Draft;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public submit(): TEntry {
    const data = this.child.getForm();
    const couponData = this.child.onCouponSubmit();
    const couponFile = this.child.onCouponExcel();
    this.child.form.markAllAsTouched();
    if (!data || this.child.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }
    if (this.checkCoupon(couponFile, couponData.description) === false) {
      this.snackBar.open('Hiba a kupon feltöltés közben!', 'X', {
        duration: 3000,
        panelClass: ['snack-bar-container', 'failure'],
      });
      return;
    }
    new Date(data.startDate) > new Date()
      ? (this.status = NewsFeedEntryStatus.Scheduled)
      : (this.status = NewsFeedEntryStatus.Active);
    for (let index = 0; index < data.steps.length; index++) {
      for (
        let jindex = 0;
        jindex < data.steps[index].choices.length;
        jindex++
      ) {
        if (data.steps[index].choices[jindex].answer == '') {
          data.steps[index].choices.splice(jindex, 1);
        }
      }
      if (
        data.steps[index].type === 'SLIDER' ||
        data.steps[index].type === 'FREETEXT'
      ) {
        data.steps[index].choices = [];
      }
      if (data.steps[index].multiple) {
        data.steps[index].type = 'MULTI';
      }
      if (typeof data.steps[index].choices[0] == 'string') {
        data.steps[index].choices = data.steps[
          index
        ].choices.map((choice: string) => ({ value: choice }));
        if (data.steps[index].none) {
          data.steps[index].choices.push({ value: 'Egyik sem.', type: 'NONE' });
        }
      }
      delete data.steps[index].multiple;
      delete data.steps[index].none;
    }
    delete data.couponDescription;
    delete data.couponImageId;

    if (this.checkCoupon(couponFile, couponData.description)) {
      this.sendCreation(data).subscribe(() => {
        this.child.form.markAsPristine();
      });
    } else {
      this.createCoupon(data, couponData, couponFile);
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public onDraftClicked(): void {
    this.status == NewsFeedEntryStatus.Draft;
    const data = this.child.getForm();
    const couponData = this.child.onCouponSubmit();
    const couponFile = this.child.onCouponExcel();
    this.child.form.markAllAsTouched();
    if (!data || this.child.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
      });
      return;
    }
    if (this.checkCoupon(couponFile, couponData.description) === false) {
      this.snackBar.open('Hiba a kupon feltöltés közben!', 'X', {
        duration: 3000,
        panelClass: ['snack-bar-container', 'failure'],
      });
      return;
    } //TODO
    this.status == NewsFeedEntryStatus.Draft;
    for (let index = 0; index < data.steps.length; index++) {
      for (
        let jindex = 0;
        jindex < data.steps[index].choices.length;
        jindex++
      ) {
        if (data.steps[index].choices[jindex].answer == '') {
          data.steps[index].choices.splice(jindex, 1);
        }
      }
      if (
        data.steps[index].type === 'SLIDER' ||
        data.steps[index].type === 'FREETEXT'
      ) {
        data.steps[index].choices = [];
      }
      if (data.steps[index].multiple) {
        data.steps[index].type = 'MULTI';
      }
      data.steps[index].choices = data.steps[
        index
      ].choices.map((choice: string) => ({ value: choice }));
      if (data.steps[index].none) {
        data.steps[index].choices.push({ value: 'Egyik sem.', type: 'NONE' });
      }
      delete data.steps[index].multiple;
      delete data.steps[index].none;
    }
    delete data.couponDescription;
    delete data.couponImageId;
    if (this.checkCoupon(couponFile, couponData.description)) {
      this.sendCreation(data).subscribe(() => {
        this.child.form.markAsPristine();
      });
    } else {
      this.createCoupon(data, couponData, couponFile);
    }
  }

  public createCoupon(
    data: TEntry,
    couponData: { imageId: number; description: string },
    couponFile: File
  ): void {
    this.sendCreation(data).subscribe((response) => {
      this.couponApi
        .createCoupon({
          // eslint-disable-next-line unicorn/no-null
          id: null,
          surveyId: response.id,
          imageId: couponData.imageId,
          description: couponData.description,
        })
        // eslint-disable-next-line rxjs/no-nested-subscribe
        .subscribe((couponResponse) => {
          this.couponApi
            .uploadCouponCodes(couponResponse.id, couponFile)
            // eslint-disable-next-line rxjs/no-nested-subscribe
            .subscribe(() => this.child.form.markAsPristine());
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public checkCoupon(file: any, data: any): boolean {
    if (data === '') {
      data = undefined;
    }
    if (file.name == undefined && data == undefined) {
      return true;
    } else if (file.name == undefined || data == undefined) {
      return false;
    }
  }

  private sendCreation(data: TEntry, draft = true) {
    return this.postDataService.createNewsFeedEntry({
      objectType: this.objectType,
      type: 'SURVEY',
      status: this.status,
      draft: this.status == NewsFeedEntryStatus.Draft ? draft : !draft,
      ...data,
    });
  }
}
