/* eslint-disable no-magic-numbers */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageDto } from '@otp-junior/admin-client';
import { Observable, Subject, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { FileUploaderService } from '../file-uploader.service';

@Component({
  selector: 'web-admin-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePickerComponent
  implements OnInit, ControlValueAccessor, OnDestroy, AfterViewInit {
  private static id = 0;
  public id = `file-picker-${FilePickerComponent.id++}`;
  @Input() public titleText: string;
  @Input() public descriptionText: string;
  @Input() public erasable = false;
  @Input() public maxSizeInPixels = '364 x 180';
  @Input() public maxSizeInMB = 3;
  @ViewChild('input', { static: true })
  public input: ElementRef<HTMLInputElement>;
  private readonly value = new Subject<number>();
  public percentage$ = new Subject<number>();
  public onChange: (value: number) => void;
  public onTouched: () => void;
  public file$: Observable<ImageDto>;
  public uploading = false;
  public disabled = false;
  public lateId: number;

  public componentTouched = false;
  public get invalidFormControl(): boolean {
    return this.ngControl.invalid && this.componentTouched;
  }

  public constructor(
    @Self() @Optional() private readonly ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef,
    private readonly fileUploaderService: FileUploaderService,
    protected readonly snackBar: MatSnackBar
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    this.file$ = this.value.pipe(
      distinctUntilChanged(),
      switchMap((id) => this.fileUploaderService.getFileById(id)),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
    this.ngControl.control.markAsTouched = () => {
      this.componentTouched = true;
      this.cdr.markForCheck();
    };
  }

  public ngAfterViewInit(): void {
    if (this.lateId) {
      this.value.next(this.lateId);
    }
  }

  public ngOnDestroy(): void {
    this.value.complete();
    this.percentage$.complete();
  }

  public writeValue(object: number): void {
    if (object) {
      this.lateId = object;
      this.value.next(object);
    }
  }

  public registerOnChange(function_: (value: number) => void): void {
    this.onChange = function_;
  }
  public registerOnTouched(function_: () => void): void {
    this.onTouched = function_;
  }
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public handleFileInput(): void {
    const snackBarClass = 'snack-bar-container';
    const selectedFile = this.input.nativeElement.files.item(0);
    const acceptedType = ['image/jpeg', 'image/jpg', 'image/png'];
    if (
      selectedFile &&
      selectedFile.type != acceptedType[0] &&
      selectedFile.type != acceptedType[1] &&
      // eslint-disable-next-line no-magic-numbers
      selectedFile.type != acceptedType[2]
    ) {
      this.snackBar.open('A file típusának jpg/png/jpeg kell lennie.', 'X', {
        duration: 3000,
        panelClass: [snackBarClass, 'failure'],
      });
      return;
    }
    if (selectedFile) {
      if (selectedFile.size / 1000000 > this.maxSizeInMB) {
        this.snackBar.open(
          `A kép mérete túl nagy! Maximális megengedett fájlméret: ${this.maxSizeInMB} MB`,
          'OK',
          {
            panelClass: [snackBarClass, 'failure'],
            duration: 3000,
          }
        );
        return;
      }
      this.uploading = true;
      const { percentage, file } = this.fileUploaderService.uploadFile(
        selectedFile
      );
      percentage.subscribe((percentage) => this.percentage$.next(percentage));
      file
        .pipe(
          catchError((error) => {
            this.snackBar.open('A fájl feltöltése sikertelen!', 'X', {
              duration: 3000,
              panelClass: [snackBarClass, 'failure'],
            });
            this.uploading = false;
            this.percentage$.next(0);
            /* eslint-disable unicorn/no-null */
            return throwError(error);
          })
        )
        .subscribe((x) => {
          if (this.onChange) {
            this.onChange(x.id);
          }
          this.value.next(x.id);
          this.uploading = false;
        });
    }
  }

  public onImageReplaceClicked(): void {
    this.input.nativeElement.click();
  }

  public onEraseClicked(): void {
    // eslint-disable-next-line unicorn/no-useless-undefined
    this.file$ = undefined;
    // eslint-disable-next-line unicorn/no-useless-undefined
    this.input.nativeElement.value = '';
    this.file$ = this.value.pipe(
      distinctUntilChanged(),
      switchMap((id) => this.fileUploaderService.getFileById(id)),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
    // eslint-disable-next-line unicorn/no-useless-undefined
    this.onChange(undefined);
  }
}
