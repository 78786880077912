import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import {
  GameAnalytics,
  GameCampaignDataService,
  GamePlayersAnalytics,
  GameQuizAnalytics,
  SortDirection,
} from '@otp-junior/admin-client';
import {
  mapMaterialSortByToSortBy,
  mapMaterialSortDirectionToSortDirection,
  UnionSortBy,
} from '@web-admin/shared-lib';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { TabStorageService } from '../../../core/storage.service';
import { GameAnalyticsType } from '../game-analytics-type.enum';
import { GameAnalyticsService } from '../services/game-analytics.service';

@Component({
  selector: 'web-admin-game-analytics-list',
  templateUrl: './game-analytics-list.component.html',
  styleUrls: ['./game-analytics-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameAnalyticsListComponent implements OnInit, OnDestroy {
  public readonly page$ = new BehaviorSubject<number>(0);

  public availableCampaigns$: Observable<{ id: number; name: string }[]>;
  public analytics$: Observable<
    (GamePlayersAnalytics | GameQuizAnalytics | GameAnalytics)[]
  >;
  public count$: Observable<number>;
  public sortArguments$: Subject<{
    sortBy?: UnionSortBy;
    sortDirection?: SortDirection;
  }> = new Subject();
  public type: GameAnalyticsType;

  public campaignControl = new FormControl();

  public displayedColumns = [
    'gameCampaignName',
    'name',
    'email',
    'points',
    'level',
    'gameAnswerCounts',
    'spentTimeInSeconds',
    'moneyWonByQuiz',
  ];

  constructor(
    private readonly gameAnalyticsService: GameAnalyticsService,
    private readonly gameCampaignDataService: GameCampaignDataService,
    private readonly route: ActivatedRoute,
    private readonly storageService: TabStorageService
  ) {}

  public getRouterLink(element: {
    campaignId?: number;
    levelId?: number;
    postId?: number;
  }): (string | number)[] {
    switch (this.type) {
      case GameAnalyticsType.GameQuiz:
        return ['/quiz/result', 'game-quiz', element.postId];
      case GameAnalyticsType.Game:
        return ['/game/result', element.levelId];
      default:
        return ['/game/result', element.postId];
    }
  }

  public ngOnInit(): void {
    this.availableCampaigns$ = this.gameCampaignDataService
      .getGameCampaigns()
      .pipe(
        map((response) => {
          const tabData = this.storageService.getTabData();
          this.campaignControl.setValue(
            tabData.campaignId || response.content[0].id
          );

          if (tabData.tab === this.type.replace('-', '_')) {
            this.page$.next(tabData.page ?? 0);
          }

          this.storageService.storeTabData({
            tab: this.type,
            campaignId: this.campaignControl.value,
            page: this.page$.value,
            sortingRequest: tabData.sortingRequest ?? {
              sortBy: '',
              sortDirection: '',
            },
          });

          return response.content.map((campaign) => ({
            id: campaign.id,
            name: campaign.name,
          }));
        })
      );

    const data = combineLatest([
      this.route.data.pipe(map((data) => data.type as GameAnalyticsType)),
      this.page$.asObservable(),
      this.campaignControl.valueChanges,
      this.sortArguments$
        .asObservable()
        .pipe(startWith({ sortBy: undefined, sortDirection: undefined })),
    ]).pipe(
      switchMap(([type, page, campaignId, sortArguments]) => {
        const hasTheSameCampaignId =
          this.storageService.getTabData()?.campaignId === campaignId;
        this.displayedColumns = this.getDisplayedColumns(type);
        this.type = type;

        const pageToSet = hasTheSameCampaignId ? page : 0;

        this.storageService.storeTabData({
          tab: type,
          campaignId,
          page: pageToSet,
          sortingRequest: {
            sortBy: sortArguments?.sortBy,
            sortDirections: sortArguments?.sortDirection,
          },
        });

        if (type === GameAnalyticsType.GamePlayers) {
          if (campaignId !== 'all') {
            return this.gameAnalyticsService.getGamePlayersAnalytics$(
              pageToSet,
              10,
              +campaignId,
              sortArguments?.sortBy,
              sortArguments.sortDirection
            );
          }

          return this.gameAnalyticsService.getGamePlayersAnalytics$(
            pageToSet,
            10
          );
        } else if (type === GameAnalyticsType.GameQuiz) {
          if (campaignId !== 'all') {
            return this.gameAnalyticsService.getGameQuizAnalytics$(
              pageToSet,
              10,
              +campaignId
            );
          }

          return this.gameAnalyticsService.getGameQuizAnalytics$(pageToSet, 10);
        } else {
          if (campaignId !== 'all') {
            return this.gameAnalyticsService.getGameAnalytics$(
              pageToSet,
              10,
              +campaignId
            );
          }

          return this.gameAnalyticsService.getGameAnalytics$(pageToSet, 10);
        }
      }),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

    this.analytics$ = data.pipe(map((response) => response.content));
    this.count$ = data.pipe(map((response) => response.totalElements));
  }

  public ngOnDestroy(): void {
    this.gameAnalyticsService.resetCampaignId();
  }

  public onSortChange(sort: Sort): void {
    this.sortArguments$.next({
      sortBy: mapMaterialSortByToSortBy(sort.active),
      sortDirection: mapMaterialSortDirectionToSortDirection(sort.direction),
    });
  }

  private getDisplayedColumns(type: GameAnalyticsType): string[] {
    switch (type) {
      case GameAnalyticsType.GamePlayers:
        return [
          'gameCampaignName',
          'name',
          'email',
          'points',
          'level',
          'gameAnswerCounts',
          'spentTimeInSeconds',
          'moneyWonByQuiz',
        ];
      case GameAnalyticsType.GameQuiz:
        return [
          'campaignTitle',
          'lifeStageTitle',
          'levelTitle',
          'responseWithBetCount',
          'responseWithoutBetCount',
          'filledCount',
          'results',
        ];
      case GameAnalyticsType.Game:
        return [
          'campaignTitle',
          'lifeStageTitle',
          'levelTitle',
          'startedCount',
          'finishedCount',
          'results',
        ];
    }
  }

  public onPageChange(pageEvent: PageEvent): void {
    this.page$.next(pageEvent.pageIndex);
  }
}
