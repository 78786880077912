import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MaterialSortInitialStateModule } from '@web-admin/shared-lib';
import { AnalyticsRoutingModule } from './analytics-routing.module';
import { ExportButtonComponent } from './export-button/export-button.component';
import { GameAnalyticsListComponent } from './game-analytics-list/game-analytics-list.component';
import { AnalyticsListComponent } from './list/list.component';
import { GameAnalyticsService } from './services/game-analytics.service';
import { AnalyticsTabsComponent } from './tabs/tabs.component';

@NgModule({
  declarations: [
    AnalyticsTabsComponent,
    AnalyticsListComponent,
    ExportButtonComponent,
    GameAnalyticsListComponent,
  ],
  imports: [
    CommonModule,
    AnalyticsRoutingModule,
    MatTabsModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MaterialSortInitialStateModule,
  ],
  providers: [GameAnalyticsService],
})
export class AnalyticsModule {}
