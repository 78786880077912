import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { AppConfig } from '../../../config/app.config';

interface CityDto {
  id: string;
  name: string;
}

interface CitySearchable {
  id: number;
  name: string;
  lowerName: string;
}

const maxCitiesShown = 20;

@Component({
  selector: 'web-admin-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: ['./city-select.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: CitySelectComponent },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CitySelectComponent
  implements MatFormFieldControl<number>, ControlValueAccessor, OnDestroy {
  public static nextId = 0;

  @HostBinding()
  public id = `city-select-${CitySelectComponent.nextId++}`;
  public controlType = 'city-select';
  @ViewChild(MatInput, { static: true }) public input: MatInput;
  public cities$: Observable<CitySearchable[]>;
  public filteredCities$: Observable<CitySearchable[]>;
  public inputControl = new FormControl();
  private readonly subscription = new Subscription();

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfig: AppConfig,
    @Self() @Optional() public readonly ngControl: NgControl,
    @Optional() private readonly controlContainer: ControlContainer,
    private readonly errorStateMatcher: ErrorStateMatcher
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.cities$ = this.httpClient
      .get<CityDto[]>(`${appConfig.getConfig('backendBaseUrl')}/cities`)
      .pipe(
        map((cities) =>
          cities.map(
            (city) =>
              ({
                id: +city.id,
                name: city.name,
                lowerName: city.name.toLocaleLowerCase(),
              } as CitySearchable)
          )
        ),
        shareReplay({
          refCount: true,
          bufferSize: 1,
        })
      );
    this.filteredCities$ = this.cities$.pipe(
      switchMap((cities) =>
        this.inputControl.valueChanges.pipe(
          filter((searchText: string) => searchText.length > 1),
          map((searchText) => searchText.toLocaleLowerCase()),
          map((searchText) =>
            cities
              .filter((x) => x.lowerName.includes(searchText))
              .sort((a, b) => {
                const aStartsWith = a.lowerName.startsWith(searchText);
                const bStartsWith = b.lowerName.startsWith(searchText);
                if (aStartsWith && !bStartsWith) {
                  return -1;
                }
                if (bStartsWith && !aStartsWith) {
                  return 1;
                }
                return a.lowerName.localeCompare(b.lowerName);
              })
              .slice(0, maxCitiesShown)
          )
        )
      )
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public writeValue(id: number): void {
    this.cities$
      .pipe(
        first(),
        map((cities) => cities.find((city) => city.id === id)),
        filter((city) => !!city)
      )
      .subscribe((city) => {
        this.input.ngControl.valueAccessor.writeValue(city);
        this.input.value = city.name;
      });
  }
  public registerOnChange(function_: (value: string) => void): void {
    this.subscription.add(
      this.inputControl.valueChanges.subscribe((city: CityDto) =>
        function_(city.id)
      )
    );
  }
  public registerOnTouched(function_: () => void): void {
    this.subscription.add(
      this.inputControl.statusChanges.subscribe(() => function_())
    );
  }
  public setDisabledState(isDisabled: boolean): void {
    this.input.ngControl.valueAccessor.setDisabledState(isDisabled);
  }
  public get value(): number {
    return this.input.ngControl.value;
  }
  public get stateChanges(): Observable<void> {
    return this.input.stateChanges;
  }
  public set placeholder(value: string) {
    this.input.placeholder = value;
  }
  public get placeholder(): string {
    return this.input.placeholder;
  }
  public get focused(): boolean {
    return this.input.focused;
  }
  public get empty(): boolean {
    return this.input.empty;
  }
  public get shouldLabelFloat(): boolean {
    return this.input.shouldLabelFloat;
  }
  @Input() public set required(required: boolean) {
    this.input.required = required;
  }
  public get required(): boolean {
    return this.input.required;
  }
  public set disabled(disabled: boolean) {
    this.input.disabled = disabled;
  }
  public get disabled(): boolean {
    return this.input.disabled;
  }
  public get errorState(): boolean {
    return this.errorStateMatcher.isErrorState(
      this.ngControl.control as FormControl,
      this.controlContainer as NgForm | FormGroupDirective
    );
  }
  public setDescribedByIds(ids: string[]): void {
    this.input.setDescribedByIds(ids);
  }
  public onContainerClick(): void {
    this.input.onContainerClick();
  }

  public displayFn(city: CitySearchable): string {
    return city && city.name ? city.name : '';
  }
}
