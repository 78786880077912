<form (ngSubmit)="submit()">
  <h1>Új kutatás hozzáadása</h1>
  <web-admin-survey-form> </web-admin-survey-form>
  <div class="actions">
    <div class="left">
      <a
        mat-stroked-button
        type="button"
        data-test="discard"
        color="primary"
        routerLink="/post"
      >
        Elvetés
      </a>
      <a
        mat-stroked-button
        type="button"
        data-test="discard"
        color="primary"
        (click)="onDraftClicked()"
      >
        Piszkozat mentése
      </a>
    </div>
    <button mat-raised-button data-test="submit" color="primary">
      Publikálom
    </button>
  </div>
</form>
