import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Host,
  HostListener,
  Input,
  Optional,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  NgControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { MultiInputTemplateDirective } from '../multi-input-template.directive';

@Component({
  selector: 'shared-multi-input',
  templateUrl: './multi-input.component.html',
  styleUrls: ['./multi-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiInputComponent<
  TControl extends AbstractControl = FormControl,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TValue = any
> implements ControlValueAccessor {
  /**
   * FormArray implementation to use.
   */
  @Input() public set array(array: FormArray) {
    this.arraySubscriptions.unsubscribe();
    this.localArray = array;
    if (this.onChange) {
      this.localArray.valueChanges.subscribe((value) => this.onChange(value));
    }
  }
  public get array(): FormArray {
    return this.localArray;
  }
  @Input() public controlGenerator: () => TControl = () =>
    (new FormControl() as unknown) as TControl;
  @ContentChild(MultiInputTemplateDirective, { static: true })
  public template: MultiInputTemplateDirective<TControl>;
  @HostListener('focusout') public onFocusOut(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private localArray = new FormArray([]);
  private readonly arraySubscriptions = new Subscription();
  private onChange: (value: TValue[]) => void;
  private onTouched: () => void;

  public constructor(
    private readonly cdr: ChangeDetectorRef,
    @Host() @Optional() private readonly ngControl: NgControl
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  public writeValue(object: TValue[]): void {
    if (!Array.isArray(object)) {
      return;
    }
    while (object.length > this.localArray.length) {
      this.localArray.push(this.controlGenerator());
    }
    while (object.length < this.localArray.length) {
      this.localArray.removeAt(this.localArray.length - 1);
    }
    this.localArray.patchValue(object);
    this.cdr.markForCheck();
  }
  public registerOnChange(function_: (value: TValue[]) => void): void {
    this.onChange = function_;
    this.localArray.valueChanges.subscribe((value) => this.onChange(value));
  }
  public registerOnTouched(function_: () => void): void {
    this.onTouched = function_;
  }
  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && this.localArray.enabled) {
      this.localArray.disable();
    } else if (!isDisabled && this.localArray.disabled) {
      this.localArray.enable();
    }
  }
  public addControl(): void {
    this.localArray.push(this.controlGenerator());
    this.cdr.markForCheck();
  }
  public removeControl(index: number): void {
    this.localArray.removeAt(index);
    this.cdr.markForCheck();
  }
}
