<div [formGroup]="form">
  <div class="first-row">
    <div mat-tab-nav-bar [formControl]="controls.status">
      <a
        mat-tab-link
        *ngFor="let status of statusList"
        [value]="status.value"
        [attr.data-test]="'status-' + status.value"
        >{{ status.name }}</a
      >
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
  <mat-form-field appearance="standard">
    <mat-select [formControl]="controls.type">
      <mat-option value="" data-test="all">Összes típus</mat-option>
      <mat-option
        *ngFor="let type of typeList"
        [value]="type.value"
        [attr.data-test]="'type-' + type.value"
      >
        {{ type.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    *ngIf="showSelectors$ | async"
    appearance="standard"
    class="middle-form-field"
  >
    <mat-select [formControl]="controls.category">
      <mat-option value="" data-test="category">Összes kategória</mat-option>
      <mat-option
        *ngFor="let category of categories$ | async"
        [value]="category.name"
        [attr.data-test]="category.name"
      >
        {{ category.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="showSelectors$ | async" appearance="standard">
    <mat-select [formControl]="controls.order" [ngModel]="accessList[0].value">
      <mat-option
        [value]="accessList[0].value"
        [attr.data-test]="accessList[0].value"
      >
        {{ accessList[0].name }}
      </mat-option>
      <mat-option
        [value]="accessList[1].value"
        [attr.data-test]="accessList[1].value"
      >
        {{ accessList[1].name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
