/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AnalyticsDataService,
  GameQuizResultStatisticsDto,
} from '@otp-junior/admin-client';
import { Observable } from 'rxjs';

@Component({
  selector: 'web-admin-survey-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultComponent implements OnInit {
  public id: number;
  public type: string;
  public data$: Observable<GameQuizResultStatisticsDto>;

  constructor(
    private readonly route: ActivatedRoute,
    protected readonly analyticsDataService: AnalyticsDataService
  ) {
    this.id = (this.route.snapshot.paramMap.get('id') as unknown) as number;
    this.type = this.route.snapshot.paramMap.get('type');
  }

  public get title(): string {
    switch (this.type) {
      case 'quiz':
        return 'Kvíz eredményei';
      case 'game-quiz':
        return 'Játék kvíz eredményei';
    }
  }

  public get resultsTitle(): string {
    switch (this.type) {
      case 'quiz':
        return 'Kvíz kitöltői';
      case 'game-quiz':
        return 'Játék kvíz kitöltői';
    }
  }

  public ngOnInit(): void {
    this.data$ = this.getStreamByQuizType(this.type);
  }

  public onExportClicked(): void {
    this.analyticsDataService
      .exportQuizResultStatistics(this.id)
      .subscribe((response: Blob) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        document.body.append(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'export.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  }

  private getStreamByQuizType(
    type: string
  ): Observable<GameQuizResultStatisticsDto> {
    switch (type) {
      case 'quiz':
        return this.analyticsDataService.getQuizAnalytics(this.id);
      case 'game-quiz':
        return this.analyticsDataService.getGameQuizAnalyticsResult(this.id);
    }
  }
}
