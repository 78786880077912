/* eslint-disable unicorn/no-null */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import {
  AngularEditorConfig,
  AngularEditorService,
} from '@kolkov/angular-editor';
import { JobDto } from '@otp-junior/admin-client';
import { AttachmentService, shouldBeAfter } from '@web-admin/shared-lib';
import { AbstractFormComponent } from '@web-admin/shared-lib/base-class';
import { AppConfig } from '../../../config/app.config';
import { emailRegex } from '../../../utils/email-regex';
import { urlRegex } from '../../../utils/url-regex';

export type JobFormData = Omit<
  JobDto,
  'id' | 'status' | 'employmentType' | 'pinned' | 'viewCount'
>;

const nameMaxLength = 100;
const workingHoursMaxLength = 10000;
const taskMaxLength = 10000;
const offerMaxLength = 10000;

@Component({
  selector: 'web-admin-job-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [AngularEditorService],
})
export class JobFormComponent
  extends AbstractFormComponent<JobDto, JobFormData>
  implements OnInit {
  private _isVolunteer: boolean;
  @Input() public set isVolunteer(isVolunteer: boolean) {
    this._isVolunteer = isVolunteer;
    if (isVolunteer) {
      this.controls.wageHourly.clearValidators();
    } else {
      this.controls.wageHourly.setValidators([Validators.required]);
    }
  }
  public get isVolunteer(): boolean {
    return this._isVolunteer;
  }
  @Input() public isEdit: boolean;
  @Output() switchLinkToCreate? = new EventEmitter<string>();

  public nameMaxLength = nameMaxLength;
  public workingHoursMaxLength = workingHoursMaxLength;
  public taskMaxLength = taskMaxLength;
  public offerMaxLength = offerMaxLength;
  public switchLink: string;
  public readonly editorConfig: AngularEditorConfig = {
    height: 'auto',
    minHeight: '230px',
    maxHeight: 'auto',
    ...this.attachmentService.getAngularEditorConfig(
      `${this.appConfig.getConfig('backendBaseUrl')}/admin/image`,
      this.editorService,
      this.appConfig.getConfig('imageUploadLimitInBytes')
    ),
  };

  public constructor(
    @Self() @Optional() readonly ngControl: NgControl,
    private readonly appConfig: AppConfig,
    private readonly attachmentService: AttachmentService,
    private readonly editorService: AngularEditorService
  ) {
    super(ngControl, {
      adultsOnly: new FormControl(false),
      foreignLanguageRequired: new FormControl(false),
      jobCategoryId: new FormControl(undefined, [Validators.required]),
      wageHourly: new FormControl(undefined, []),
      cityId: new FormControl(undefined, [Validators.required]),
      startDate: new FormControl(undefined, [Validators.required]),
      endDate: new FormControl(undefined),
      applicationUrl: new FormControl(undefined, [
        Validators.required,
        Validators.pattern(urlRegex),
      ]),
      name: new FormControl(undefined, [
        Validators.maxLength(nameMaxLength),
        Validators.required,
      ]),
      conditions: new FormControl([{ title: '' }], [Validators.minLength(1)]),
      offer: new FormControl(undefined, [
        Validators.maxLength(offerMaxLength),
        Validators.required,
      ]),
      task: new FormControl(undefined, [
        Validators.maxLength(taskMaxLength),
        Validators.required,
      ]),
      workingHours: new FormControl(undefined, [
        Validators.maxLength(workingHoursMaxLength),
        Validators.required,
      ]),
      emailTo: new FormControl(undefined),
      emailSubject: new FormControl('Jelentkezés - {{jobName}}'),
      emailBody: new FormControl(`
      Tisztelt Címzett!
      <br>
      <br>
      Az OTP Junior Next alkalmazásban meghirdetett {{jobName}}
      <br>
      munkára szeretnék jelentkezni.
      <br>
      <br>
      Köszönettel,
      <br>
      {{lastName}} {{firstName}}`),
    });
    this.controls.endDate.setValidators([
      Validators.required,
      shouldBeAfter(this.controls.startDate),
    ]);
    this.subscription.add(
      this.controls.startDate.valueChanges.subscribe(() =>
        this.controls.endDate.updateValueAndValidity()
      )
    );
    this.switchLink = 'link';
  }

  ngOnInit(): void {
    this.onItemChange(this.switchLink);
  }

  protected mapToForm(data: JobDto): JobFormData {
    if (data.applicationUrl == undefined) {
      this.switchLink = 'email';
    }
    return {
      adultsOnly: data.adultsOnly,
      foreignLanguageRequired: data.foreignLanguageRequired,
      jobCategoryId: data.jobCategoryId,
      wageHourly: data.wageHourly,
      cityId: data.cityId,
      startDate: data.startDate,
      endDate: data.endDate,
      applicationUrl: data.applicationUrl,
      name: data.name,
      conditions: data.conditions,
      offer: data.offer,
      task: data.task,
      workingHours: data.workingHours,
      emailTo: data.emailTo,
      emailSubject: data.emailSubject,
      emailBody: data.emailBody,
    };
  }

  public conditionsControlGenerator(): FormGroup {
    return new FormGroup({
      title: new FormControl(undefined, [Validators.required]),
    });
  }

  public updateValidity(): void {
    this.controls.applicationUrl.updateValueAndValidity();
    this.controls.emailBody.updateValueAndValidity();
    this.controls.emailSubject.updateValueAndValidity();
    this.controls.emailTo.updateValueAndValidity();
  }

  public onItemChange(value: string): void {
    this.switchLink = value;
    if (this.switchLink === 'link') {
      this.form.controls.applicationUrl.setValidators([
        Validators.required,
        Validators.pattern(urlRegex),
      ]);
      this.form.controls.emailBody.clearValidators();
      this.form.controls.emailTo.clearValidators();
      this.form.controls.emailSubject.clearValidators();
      this.updateValidity();
    } else {
      this.form.controls.applicationUrl.clearValidators();
      this.form.controls.emailBody.setValidators([Validators.required]);
      this.form.controls.emailSubject.setValidators([Validators.required]);
      this.form.controls.emailTo.setValidators([
        Validators.required,
        Validators.pattern(emailRegex),
      ]);
      this.updateValidity();
    }
    this.switchLinkToCreate.emit(this.switchLink);
  }
}
