/* eslint-disable no-magic-numbers */

import {
  LexiconInfoResponse,
  LexiconWordListItemResponse,
  LexiconWordResponse,
  LexiconWordStatus,
  ListLexiconWordsPageResponse,
  NewsFeedEntryType,
  NewsFeedEntryUndetailed,
} from '@otp-junior/admin-client';
import { DefaultRequestMultipartBody, RequestParams, rest } from 'msw';

const PATH = '/admin/lexicon';
const WORDS_PATH = `${PATH}/words`;
const LEXICON_INFO_PATH = `${PATH}`;
const SEARCH_PATH = `${PATH}/search`;

const getMockLexiconItem = (id: number): LexiconWordListItemResponse => {
  return {
    date: '2021-01-01',
    id,
    status: LexiconWordStatus.Active,
    word: `Test${id}`,
    connectingPostsCount: 12,
    viewCount: id + 1,
  };
};

const getMockLexiconItemDetails = (id: number): LexiconWordResponse => {
  return {
    date: '2021-01-01',
    id,
    status: LexiconWordStatus.Active,
    word: `Test${id}`,
    connectingPosts: [
      {
        id: 0,
        title: 'Teszt0',
        type: NewsFeedEntryType.Article,
      },
      {
        id: 1,
        title: 'Teszt1',
        type: NewsFeedEntryType.Article,
      },
    ],
    definition: 'Hello world',
  };
};

export const lexiconHandlers = [
  rest.get<DefaultRequestMultipartBody, RequestParams, NewsFeedEntryUndetailed>(
    `${SEARCH_PATH}`,
    (request, response, context) => {
      const searchWord = request.url.searchParams.get('text');

      return response(
        context.delay(1000),
        context.json(
          Array.from({ length: 10 }, (_, index) =>
            getMockLexiconItem(index)
          ).filter((entry) => {
            return entry.word
              ?.toLowerCase()
              .includes(searchWord?.toLowerCase());
          })
        )
      );
    }
  ),

  rest.get<DefaultRequestMultipartBody, RequestParams, LexiconWordResponse>(
    `${WORDS_PATH}/:id`,
    (request, response, context) => {
      const id = Number(request.url.searchParams.get('id'));

      const content = getMockLexiconItemDetails(id);

      return response(context.delay(1000), context.json(content));
    }
  ),

  rest.put<DefaultRequestMultipartBody, RequestParams, LexiconWordResponse>(
    `${WORDS_PATH}/:id`,
    (request, response, context) => {
      const content = getMockLexiconItemDetails(1);

      return response(context.delay(1000), context.json(content));
    }
  ),

  rest.post<DefaultRequestMultipartBody, RequestParams, LexiconWordResponse>(
    `${WORDS_PATH}`,
    // eslint-disable-next-line sonarjs/no-identical-functions
    (request, response, context) => {
      const content = getMockLexiconItemDetails(1);

      return response(context.delay(1000), context.json(content));
    }
  ),

  rest.get<DefaultRequestMultipartBody, RequestParams, LexiconInfoResponse>(
    `${LEXICON_INFO_PATH}`,
    (request, response, context) => {
      const content = getMockLexiconItem(1);

      return response(
        context.delay(1000),
        context.json({
          totalElements: 100,
          wordOfTheDay: content,
        })
      );
    }
  ),

  rest.get<
    DefaultRequestMultipartBody,
    RequestParams,
    ListLexiconWordsPageResponse
    // eslint-disable-next-line sonarjs/cognitive-complexity
  >(`${WORDS_PATH}`, (request, response, context) => {
    const pageSize = Number(request.url.searchParams.get('pageSize'));
    const pageLimit = pageSize > 0 ? pageSize : 1;
    const offset = Number(request.url.searchParams.get('page'));
    const sortBy = request.url.searchParams.get('sort');
    const sortDirection = request.url.searchParams.get('direction');
    const content = Array.from({ length: pageLimit }, (_, index) =>
      getMockLexiconItem(offset + index)
    );
    return response(
      context.delay(1000),
      context.json({
        totalElements: 100,
        // Some basic sorting logic that is only meant to test the requests.
        content: content.sort((a, b) => {
          if (sortBy === 'WORD') {
            return sortDirection === 'ASC'
              ? a.word.localeCompare(b.word)
              : b.word.localeCompare(a.word);
          }
          if (sortBy === 'DATE') {
            return sortDirection === 'ASC'
              ? a.date.localeCompare(b.date)
              : b.date.localeCompare(a.date);
          }
          if (sortBy === 'OPEN_COUNT') {
            return sortDirection === 'DESC'
              ? a.viewCount - b.viewCount
              : b.viewCount - a.viewCount;
          }
          return 0;
        }),
      })
    );
  }),
];
