/* eslint-disable no-magic-numbers */

import {
  GameAnalytics,
  GameAnalyticsResponse,
  GameCardType,
  GameLevelAnalyticsResponse,
  GameLevelCardResult,
  NewsFeedEntryUndetailed,
} from '@otp-junior/admin-client';
import { GameQuizResultStatisticsDto } from '@otp-junior/admin-client/model/models';
import { DefaultRequestMultipartBody, RequestParams, rest } from 'msw';

const GAME_QUIZ_PATH = '/admin/analytics/gamequiz/:id';
const GAME_PATH = '/admin/analytics/game';
const GAME_RESULT_PATH = '/admin/analytics/game-level/:id';

const mockGameQuizStatistics: GameQuizResultStatisticsDto = {
  startDate: '2023-05-09T19:47:14.023Z',
  responseCount: 11,
  responseWithBetCount: 2,
  responseWithoutBetCount: 9,
  steps: [
    {
      question: 'Milyen szervezet biztosítja a bankban elhelyezett pénzedet?',
      responseCount: 11,
      choices: [
        {
          answer: 'OBA',
          responsePercentage: 45,
          isCorrect: true,
        },
        {
          answer: 'OBB',
          responsePercentage: 27,
          isCorrect: false,
        },
        {
          answer: 'IFA',
          responsePercentage: 9,
          isCorrect: false,
        },
        {
          answer: 'MNB',
          responsePercentage: 18,
          isCorrect: false,
        },
      ],
    },
    {
      question:
        'Mekkora összegig van biztosítva a bankban elhelyezett betéted az OBA által?',
      responseCount: 11,
      choices: [
        {
          answer: '100,000 €',
          responsePercentage: 64,
          isCorrect: true,
        },
        {
          answer: '10,000 €',
          responsePercentage: 9,
          isCorrect: false,
        },
        {
          answer: '100 000 Ft',
          responsePercentage: 18,
          isCorrect: false,
        },
        {
          answer: '1 000 000 Ft',
          responsePercentage: 9,
          isCorrect: false,
        },
      ],
    },
    {
      question: 'Mennyibe kerül a havi számlavezetés ha OTP Junioros vagy?',
      responseCount: 11,
      choices: [
        {
          answer: '0 Ft',
          responsePercentage: 45,
          isCorrect: true,
        },
        {
          answer: '200 Ft',
          responsePercentage: 18,
          isCorrect: false,
        },
        {
          answer: '100 Ft',
          responsePercentage: 18,
          isCorrect: false,
        },
        {
          answer: '10 Ft',
          responsePercentage: 18,
          isCorrect: false,
        },
      ],
    },
    {
      question: 'Hány százaléka a bankkártyáknak betéti kártya Magyarországon?',
      responseCount: 11,
      choices: [
        {
          answer: '98%',
          responsePercentage: 55,
          isCorrect: true,
        },
        {
          answer: '100%',
          responsePercentage: 9,
          isCorrect: false,
        },
        {
          answer: '51%',
          responsePercentage: 27,
          isCorrect: false,
        },
        {
          answer: '25%',
          responsePercentage: 9,
          isCorrect: false,
        },
      ],
    },
  ],
};

const getMockGameAnalytics = (id: number): GameAnalytics => {
  return {
    campaignId: id,
    campaignTitle: 'Test campaign',
    levelId: 1111,
    levelTitle: 'Test level',
    finishedCount: 10,
    lifeStageTitle: 'Test life stage',
    startedCount: 12,
  };
};

const gameLevelCardResult: GameLevelCardResult = {
  type: GameCardType.LtdExpense,
  prosperity: 1,
  title: 'Test card',
  percentage: 10,
  experience: 10,
  money: 10,
  life: 10,
  refusedCount: 10,
  actionTitle: 'Test action',
  acceptedCount: 10,
};

const gameLevelAnalyticsResponse: GameLevelAnalyticsResponse = {
  levelTitle: 'Test level',
  finishedCount: 10,
  startedCount: 12,
  campaignTitle: 'Test campaign',
  cardResults: [{ ...gameLevelCardResult }, { ...gameLevelCardResult }],
};

export const analyticsHandlers = [
  rest.get<DefaultRequestMultipartBody, RequestParams, NewsFeedEntryUndetailed>(
    `${GAME_RESULT_PATH}`,
    (request, response, context) => {
      return response(
        context.delay(100),
        context.json(gameLevelAnalyticsResponse)
      );
    }
  ),

  rest.get<DefaultRequestMultipartBody, RequestParams, NewsFeedEntryUndetailed>(
    `${GAME_QUIZ_PATH}`,
    (request, response, context) => {
      return response(context.delay(100), context.json(mockGameQuizStatistics));
    }
  ),

  rest.get<
    DefaultRequestMultipartBody,
    RequestParams,
    GameAnalyticsResponse
    // eslint-disable-next-line sonarjs/cognitive-complexity
  >(`${GAME_PATH}`, (request, response, context) => {
    const pageSize = Number(request.url.searchParams.get('pageSize'));
    const pageLimit = pageSize > 0 ? pageSize : 1;
    const offset = Number(request.url.searchParams.get('page'));
    const content = Array.from({ length: pageLimit }, (_, index) =>
      getMockGameAnalytics(offset + index)
    );
    return response(
      context.delay(100),
      context.json({
        totalElements: 100,
        // Some basic sorting logic that is only meant to test the requests.
        content: content,
      })
    );
  }),
];
