<shared-form-card>
  <h2>Push üzenet beállításai</h2>
  <mat-checkbox
    [formControl]="controls.marketingContent"
    data-test="marketingContent"
  >
    Marketing tartalom
  </mat-checkbox>
  <br />
  <mat-form-field>
    <mat-label>Kiküldés dátuma</mat-label>
    <date-time-input
      [formControl]="controls.scheduledAt"
      data-test="scheduledAt"
    ></date-time-input>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-checkbox [formControl]="controls.android" data-test="android">
    Android
  </mat-checkbox>
  <br />
  <mat-checkbox [formControl]="controls.ios" data-test="ios">
    iOS
  </mat-checkbox>
</shared-form-card>
<shared-form-card class="full-width">
  <h2>Push üzenet</h2>
  <mat-form-field>
    <mat-label>Push üzenet címe</mat-label>
    <input matInput [formControl]="controls.title" data-test="title" />
    <mat-hint align="end">
      {{ controls.title.value?.length || 0 }}/{{ maxTitleLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Push üzenet szövege</mat-label>
    <textarea
      matInput
      [formControl]="controls.message"
      data-test="message"
      cdkTextareaAutosize
    ></textarea>
    <mat-hint align="end">
      {{ controls.message.value?.length || 0 }}/{{ maxMessageLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <br />
  <mat-radio-group aria-label="Select an option" [(ngModel)]="switchLink">
    <div class="flex-div">
      <mat-radio-button value="deeplink" (change)="onItemChange('deeplink')"
        >URL-re linkeljen</mat-radio-button
      >
      <mat-radio-button value="job" (change)="onItemChange('job')"
        >Diákmunkára linkeljen</mat-radio-button
      >
      <mat-radio-button value="post" (change)="onItemChange('post')"
        >Hírfolyamra linkeljen</mat-radio-button
      >
    </div>
  </mat-radio-group>
  <br />
  <mat-form-field *ngIf="switchLink === 'deeplink'">
    <mat-label>Tartalom linkje</mat-label>
    <input matInput [formControl]="controls.deepLink" data-test="deepLink" />
    <mat-error *showError></mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="switchLink == 'job'">
    <mat-label>Diákmunka linkje</mat-label>
    <mat-select [formControl]="controls.jobId" data-test="jobId">
      <mat-option *ngFor="let job of job$ | async" [value]="job.id">
        {{ job.name }}
      </mat-option>
    </mat-select>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="switchLink == 'post'">
    <mat-label>Hírfolyam linkje</mat-label>
    <mat-select [formControl]="controls.postId" data-test="postId">
      <mat-option *ngFor="let post of allPost" [value]="post.id">
        {{ post.title }}
      </mat-option>
    </mat-select>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <ng-content selector="[in-bottom-card]"></ng-content>
</shared-form-card>
