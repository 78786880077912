import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';

interface Config {
  backendBaseUrl: string;
  mockLogin?: boolean;
  mockServiceWorker?: boolean;
  samlRedirectUrl?: string;
  headerBackgroundColor?: string;
  imageUploadLimitInBytes?: number;
}

@Injectable()
export class AppConfig {
  private config: Config;

  constructor(private readonly http: HttpClient) {}

  public getConfig<T extends keyof Config>(key: T): Config[T] {
    return this.config[key];
  }

  public load(): Promise<Config> {
    return new Promise((resolve, reject) => {
      this.http
        .get<Config>('assets/config/config.json')
        .pipe(
          tap((data) => {
            this.config = data;
            resolve(data);
          }),
          catchError((error) => {
            console.error('Configuration file "config.json" could not be read');
            reject(new Error('Server error'));
            return error || 'Server error';
          })
        )
        .subscribe();
    });
  }
}
