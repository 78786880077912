import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {
  DateTimeInputModule,
  FormCardModule,
  ListEmptyModule,
  MaterialErrorModule,
  MatTabFormModule,
  MultiInputModule,
} from '@web-admin/shared-lib';
import { CitySelectComponent } from './city-select/city-select.component';
import { CreateJobDropDownComponent } from './create-job-drop-down/create-job-drop-down.component';
import { JobCreateComponent } from './create/create.component';
import { JobEditStatusIndicatorComponent } from './edit-status-indicator/edit-status-indicator.component';
import { JobEditComponent } from './edit/edit.component';
import { JobFormComponent } from './form/form.component';
import { CreateInterceptor } from './interceptors/create.interceptor';
import { EditInterceptor } from './interceptors/edit.interceptor';
import { JobCategorySelectIsVolunteerDirective } from './job-category-select-is-volunteer.directive';
import { JobCategorySelectComponent } from './job-category-select/job-category-select.component';
import { JobRoutingModule } from './job-routing.module';
import { JobStatusIconComponent } from './job-status-icon/job-status-icon.component';
import { EmploymentTypeIconComponent } from './job-type-icon/job-type-icon.component';
import { JobListFilterComponent } from './list-filter/list-filter.component';
import { JobListComponent } from './list/list.component';
import { JobPinComponent } from './pin/pin.component';

@NgModule({
  declarations: [
    JobListComponent,
    JobListFilterComponent,
    JobStatusIconComponent,
    EmploymentTypeIconComponent,
    CreateJobDropDownComponent,
    JobCreateComponent,
    JobFormComponent,
    JobCategorySelectComponent,
    CitySelectComponent,
    JobEditComponent,
    JobEditStatusIndicatorComponent,
    JobPinComponent,
    JobCategorySelectIsVolunteerDirective,
  ],
  imports: [
    AngularEditorModule,
    CommonModule,
    JobRoutingModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTabFormModule,
    ListEmptyModule,
    MatPaginatorModule,
    MatTableModule,
    MatButtonModule,
    MatMenuModule,
    FormCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MultiInputModule,
    MatIconModule,
    MatAutocompleteModule,
    MaterialErrorModule,
    DateTimeInputModule,
    MatRadioModule,
    FormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CreateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EditInterceptor,
      multi: true,
    },
  ],
})
export class JobModule {}
