import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}
  canActivateChild(): Observable<UrlTree | boolean> {
    return this.canActivate();
  }
  canActivate(): Observable<UrlTree | boolean> {
    return this.authService.isLoggedIn.pipe(
      map((isLoggedIn) => (isLoggedIn ? true : this.router.parseUrl('/login')))
    );
  }
}
