<div class="campaign-edit">
  <h1>Életszakasz szerkesztése</h1>

  <ng-container *ngIf="campaign$ | async as campaign; else loading">
    <web-admin-campaign-form
      [campaign]="campaign"
      [lifeStageEditMode]="true"
    ></web-admin-campaign-form>
    <div class="actions">
      <div class="left">
        <a
          mat-stroked-button
          type="button"
          data-test="discard"
          color="primary"
          routerLink="/game/campaign"
        >
          Elvetés
        </a>
      </div>
      <button
        mat-raised-button
        data-test="submit"
        color="primary"
        (click)="submit()"
      >
        Módosítás
      </button>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
