import { Injectable } from '@angular/core';
import {
  NewsFeedEntryFilter,
  NewsFeedEntryFilterResponse,
  NewsFeedEntryStatus,
  NewsFeedEntrySummaryDto,
  PostDataService,
} from '@otp-junior/admin-client';
import { ListService } from '@web-admin/shared-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostListService {
  public items$: Observable<NewsFeedEntrySummaryDto[]>;
  public allItems$: Observable<NewsFeedEntrySummaryDto[]>;
  public totalElements$: Observable<number>;
  public loading$: Observable<boolean>;
  public filterState$: Observable<NewsFeedEntryFilter>;
  public pageNumber$: Observable<number>;
  private readonly listService: ListService<
    NewsFeedEntryFilter,
    NewsFeedEntrySummaryDto,
    NewsFeedEntryFilterResponse
  >;

  public constructor(private readonly postDataService: PostDataService) {
    this.listService = new ListService<
      NewsFeedEntryFilter,
      NewsFeedEntrySummaryDto
    >(
      {
        status: NewsFeedEntryStatus.Active,
      },
      (filter, page) =>
        this.postDataService.searchNewsFeedEntries({
          filter,
          page,
          pageSize: 100,
        })
    );
    this.allItems$ = this.listService.items$;
    this.listService = new ListService<
      NewsFeedEntryFilter,
      NewsFeedEntrySummaryDto
    >(
      {
        status: NewsFeedEntryStatus.Active,
      },
      (filter, page) =>
        this.postDataService.searchNewsFeedEntries({
          filter,
          page,
          pageSize: 10,
        })
    );
    this.items$ = this.listService.items$;
    this.totalElements$ = this.listService.totalElements$;
    this.loading$ = this.listService.loading$;
    this.filterState$ = this.listService.filter$;
    this.pageNumber$ = this.listService.pageNumber$;
  }

  public setFilter(filter: NewsFeedEntryFilter): void {
    this.listService.setFilter(filter);
  }

  public setPage(page: number): void {
    this.listService.setPage(page);
  }
}
