<div class="main">
  <div class="header">
    <h1>Kutatás eredményei</h1>
    <button
      mat-raised-button
      data-test="submit"
      color="primary"
      (click)="onExportClicked()"
    >
      <img alt="Export-XLS" src="assets/icons/xls-white.svg" />Export XLS
    </button>
  </div>
  <web-admin-survey-coupon-details
    *ngIf="isloadedObs | async"
    [couponData]="couponData"
  ></web-admin-survey-coupon-details>
  <web-admin-survey-details
    *ngIf="isloadedObs | async"
    [detailsData]="detailsData"
  ></web-admin-survey-details>
</div>
