import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'web-admin-post-edit-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class PostEditButtonsComponent {
  @Input() public removable: boolean;
  @Input() public draft: boolean;
  @Output() public archive = new EventEmitter();
  @Output() public delete = new EventEmitter();
  @Output() public draftClicked = new EventEmitter();
  public onArchiveClick(): void {
    this.archive.emit();
  }
  public onDeleteClick(): void {
    this.delete.emit();
  }
  public onDraftClick(): void {
    this.draftClicked.emit();
  }
}
