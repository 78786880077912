import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { PostDataService } from '@otp-junior/admin-client';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CampaignLevelVM } from '../../models/game-level.vm';
import {
  GameQuizDetailsVM,
  GameQuizStatus,
} from '../../models/game-quiz-details.vm';
import { CampaignService } from '../../services/campaign.service';
import { GameQuizService } from '../../services/game-quiz.service';
import { GameQuizFormComponent } from '../form/form.component';

@Component({
  selector: 'web-admin-edit-game-quiz',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGameQuizComponent implements OnInit {
  public id: number;

  public gameQuizAndLevels$: Observable<{
    gameQuizDetails: GameQuizDetailsVM;
    campaignLevels: CampaignLevelVM[];
  }>;

  @ViewChild(GameQuizFormComponent)
  public gameQuizFormComponent: GameQuizFormComponent;

  constructor(
    protected readonly gameQuizService: GameQuizService,
    protected readonly campaignService: CampaignService,
    protected readonly postDataService: PostDataService,
    protected readonly snackBar: MatSnackBar,
    private readonly route: ActivatedRoute
  ) {
    this.id = +this.route.snapshot.paramMap.get('id');
  }

  public ngOnInit(): void {
    this.gameQuizAndLevels$ = forkJoin({
      gameQuizDetails: this.gameQuizService.getGameQuiz(this.id).pipe(take(1)),
      campaignLevels: this.campaignService.getCampaignLevels$(false),
    });
  }

  public submit(): void {
    const form = this.gameQuizFormComponent.formGroup;
    this.gameQuizFormComponent.formGroup.markAllAsTouched();

    if (form.value && form.valid) {
      this.gameQuizService
        .updateGameQuiz(this.id, form.getRawValue())
        .pipe(take(1))
        .subscribe();
    } else {
      this.openSnackBar('Töltsd ki az összes mezőt!');
    }
  }

  public onDeleteClicked(status: GameQuizStatus): void {
    if (status === GameQuizStatus.ACTIVE) {
      this.openSnackBar('Nem törölhetsz aktív kvízt!');
    } else {
      this.postDataService
        .deleteNewsFeedEntry(this.id)
        .pipe(take(1))
        .subscribe();
    }
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'X', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 3000,
    });
  }
}
