<shared-form-card class="shared-form-card" [class.invalid]="invalidFormControl">
  <label [for]="id">
    <ng-container *ngIf="!uploading && (file$ | async); else pickFile">
      <img
        [src]="(file$ | async).imageUrl"
        class="uploaded"
        alt="Fájl feltöltve icon"
      />
      <div class="footer">
        <span *ngIf="descriptionText" class="description">
          {{ descriptionText }}
        </span>
        <div class="buttons">
          <button
            mat-button
            color="primary"
            type="button"
            (click)="onImageReplaceClicked()"
            data-test="replace-image"
          >
            Kép cseréje
          </button>
          <button
            mat-flat-button
            class="delete"
            type="button"
            *ngIf="erasable"
            (click)="onEraseClicked(); $event.preventDefault()"
            data-test="erase-image"
          >
            Kép törlése
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #pickFile>
      <div class="columns">
        <div class="rows">
          <div class="title">
            {{ uploading ? 'Egy pillanat türelmet...' : titleText }}
          </div>
          <div class="helper">
            <strong>Feltölthető:</strong> png/jpg formátum,
            {{ maxSizeInPixels }} pixel<br />
            <strong>Maximális képméret:</strong>
            {{ maxSizeInMB }}MB
          </div>
          <button
            mat-button
            class="replace-image"
            color="primary"
            type="button"
            data-test="replace-image"
            [disabled]="uploading"
            (click)="input.click()"
          >
            Kép tallózása
          </button>
        </div>
        <div class="image-overlay">
          <img src="assets/icons/image.svg" alt="Feltöltés icon" />
          <div
            *ngIf="uploading"
            [style.height]="(percentage$ | async) + '%'"
          ></div>
        </div>
      </div>
    </ng-template>
    <input
      type="file"
      (change)="handleFileInput()"
      #input
      [id]="id"
      [disabled]="disabled || uploading"
    />
  </label>
  <div *ngIf="invalidFormControl" class="invalid-label">Kötelező mező</div>
</shared-form-card>
