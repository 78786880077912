/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-null */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { DateTimeService } from '../date-time-service';

@Component({
  selector: 'date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DateTimeInputComponent,
    },
  ],
})
export class DateTimeInputComponent
  implements
    OnInit,
    OnDestroy,
    MatFormFieldControl<Date>,
    ControlValueAccessor {
  public static nextId = 0;

  @HostBinding()
  public id = `date-time-input-${DateTimeInputComponent.nextId++}`;
  public controlType = 'date-time-input';
  @ViewChild('date', { static: true }) public datepickerInput: MatInput;
  @ViewChild('dateInput', { static: true })
  public dateInput: ElementRef<HTMLInputElement>;
  @ViewChild('hourInput', { static: true })
  public hourInput: ElementRef<HTMLInputElement>;
  @ViewChild('minuteInput', { static: true })
  public minuteInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatDatepicker) public datepicker: MatDatepicker<Date>;
  public readonly controls = {
    date: new FormControl(),
    hours: new FormControl(),
    minutes: new FormControl(),
  };
  public readonly group = new FormGroup(this.controls, { updateOn: 'blur' });
  public currentDate = this.dateTimeService.currentDate;
  public onTouched: () => void;
  public onChange: (date: Date) => void;
  public constructor(
    @Optional() public readonly ngControl: NgControl,
    @Optional() private readonly controlContainer: ControlContainer,
    private readonly errorStateMatcher: ErrorStateMatcher,
    private readonly dateTimeService: DateTimeService
  ) {
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  public ngOnInit(): void {
    this.group.valueChanges.subscribe(({ date, hours, minutes }) => {
      const value = new Date(date);
      value.setHours(hours);
      value.setMinutes(minutes);
      this.writeValue(value);
      if (this.onChange) {
        this.onChange(value);
      }
    });
  }
  public ngOnDestroy(): void {
    this.stateChanges.complete();
  }
  public writeValue(date: Date): void {
    if (!date) {
      this.group.setValue(
        {
          date: null,
          hours: null,
          minutes: null,
        },
        { emitEvent: false }
      );
      this.value = null;
      return;
    }
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    this.group.setValue(
      {
        date,
        hours: preZero(date.getHours()),
        minutes: preZero(date.getMinutes()),
      },
      { emitEvent: false }
    );
    this.value = date;
    this.stateChanges.next();
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public registerOnChange(function_: any): void {
    this.onChange = function_;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public registerOnTouched(function_: any): void {
    this.onTouched = function_;
  }
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.group.disable();
    } else {
      this.group.enable();
    }
  }
  public value: Date;
  public stateChanges = new Subject<void>();
  public set placeholder(value: string) {
    this.datepickerInput.placeholder = value;
  }
  public get placeholder(): string {
    return this.datepickerInput.placeholder;
  }
  public get focused(): boolean {
    return this.datepickerInput.focused;
  }
  public get empty(): boolean {
    return this.datepickerInput.empty;
  }
  public get shouldLabelFloat(): boolean {
    return this.datepickerInput.shouldLabelFloat;
  }
  @Input() public required: boolean;
  public set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }
  public get disabled(): boolean {
    return this.group.disabled;
  }
  public get errorState(): boolean {
    return this.errorStateMatcher.isErrorState(
      this.ngControl.control as FormControl,
      this.controlContainer as NgForm | FormGroupDirective
    );
  }
  public setDescribedByIds(ids: string[]): void {
    this.datepickerInput.setDescribedByIds(ids);
  }
  public onContainerClick(): void {
    this.datepickerInput.onContainerClick();
  }

  public onTimeDivClicked(event: MouseEvent): void {
    event.stopPropagation();
  }

  public onDateKeydown(event: KeyboardEvent): void {
    if (event.key === 'ArrowRight') {
      const { selectionEnd, value } = event.target as HTMLInputElement;
      if (selectionEnd === value.length) {
        this.hourInput.nativeElement.focus();
      }
    }
  }
  public onHourKeydown(event: KeyboardEvent): void {
    const {
      selectionStart,
      selectionEnd,
      value,
    } = event.target as HTMLInputElement;
    if (event.key === 'ArrowRight' && selectionEnd === value.length) {
      this.minuteInput.nativeElement.focus();
    }
    if (event.key === 'ArrowLeft' && selectionStart === 0) {
      this.dateInput.nativeElement.focus();
    }
  }
  public onMinuteKeydown(event: KeyboardEvent): void {
    const { selectionStart } = event.target as HTMLInputElement;
    if (event.key === 'ArrowLeft' && selectionStart === 0) {
      this.hourInput.nativeElement.focus();
    }
  }
}

function preZero(number: number): string {
  // eslint-disable-next-line no-magic-numbers
  return number < 10 ? `0${number}` : `${number}`;
}
