import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Resolve, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CampaignLevelVM } from '../models/game-level.vm';
import { CampaignService } from '../services/campaign.service';

@Injectable()
export class CampaignLevelsResolver implements Resolve<CampaignLevelVM[]> {
  constructor(
    private readonly campaignService: CampaignService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router
  ) {}

  resolve(): Observable<CampaignLevelVM[]> {
    return this.campaignService.getCampaignLevels$().pipe(
      mergeMap((levels) => {
        if (levels.length === 0) {
          this.router.navigate(['/game/quiz']);
          this.snackBar.open(
            'Jelenleg az összes kampányhoz tartozik játék!',
            'X',
            {
              panelClass: ['snack-bar-container', 'failure'],
              duration: 10000,
            }
          );
          return EMPTY;
        }

        return of(levels);
      })
    );
  }
}
