<shared-form-card>
  <h2>Bejegyzés beállításai</h2>
  <mat-checkbox [checked]="true" disabled>Marketing tartalom</mat-checkbox>
  <h3>Publikálás adatai</h3>
  <div class="row">
    <mat-form-field>
      <mat-label>Publikálás dátuma</mat-label>
      <date-time-input
        [formControl]="controls.startDate"
        data-test="startDate"
      ></date-time-input>
      <mat-error *showError></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Publikálás vége</mat-label>
      <date-time-input
        [formControl]="controls.endDate"
        data-test="endDate"
      ></date-time-input>
      <mat-error *showError></mat-error>
    </mat-form-field>
  </div>
  <web-admin-file-picker
    [formControl]="controls.imageId"
    titleText="Töltsd fel a bejegyzés indexképét"
  ></web-admin-file-picker>
</shared-form-card>
<shared-form-card class="full-width">
  <h2>Kedvezmény részletei</h2>
  <mat-form-field>
    <mat-label>Kedvezmény címe</mat-label>
    <input matInput [formControl]="controls.title" data-test="title" />
    <mat-hint align="end">
      {{ controls.title.value?.length || 0 }}/{{ maxTitleLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Rövid leírás</mat-label>
    <textarea
      matInput
      [formControl]="controls.description"
      data-test="description"
      cdkTextareaAutosize
    ></textarea>
    <mat-hint align="end">
      {{ controls.description.value?.length || 0 }}/{{ maxDescriptionLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Elsődleges link</mat-label>
    <input
      matInput
      [formControl]="controls.linkPrimary"
      data-test="primary-link"
    />
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Másodlagos link</mat-label>
    <input
      matInput
      [formControl]="controls.linkSecondary"
      data-test="secondary-link"
    />
    <mat-error *showError></mat-error>
  </mat-form-field>
  <ng-content selector="[in-bottom-card]"></ng-content>
</shared-form-card>
