import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EmploymentType,
  JobDataService,
  JobStatus,
} from '@otp-junior/admin-client';
import { Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { JobFormData } from '../form/form.component';
import { routeTypeMap } from '../helpers/route-type.mapper';
import { typeList } from '../helpers/type-name.list';

@Component({
  selector: 'web-admin-job-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class JobEditComponent implements OnInit, OnDestroy {
  public control = new FormControl();
  public form = new FormGroup({ data: this.control });
  private readonly subscription = new Subscription();
  private id: number;
  public jobTypeName: string;
  public isVolunteer: boolean;
  public status: JobStatus;
  public stoppable: boolean;
  public createSwitchLink: string;

  public constructor(
    protected readonly jobDataService: JobDataService,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly snackBar: MatSnackBar
  ) {
    const jobType = this.calculateEmploymentType();
    this.isVolunteer = jobType === EmploymentType.Volunteer;
    this.jobTypeName = typeList.find((x) => x.value === jobType).name;
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.activatedRoute.paramMap
        .pipe(
          map((parameterMap) => parameterMap.get('id')),
          filter((id) => !!id),
          switchMap((id) => this.jobDataService.getJobById(+id))
        )
        .subscribe((data) => {
          this.id = data.id;
          this.status = data.status;
          this.stoppable = data.status === JobStatus.Active;
          this.isVolunteer = data.employmentType === EmploymentType.Volunteer;
          this.form.setValue({
            data,
          });
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  public submit(): void {
    const data = this.form.value.data;
    if (this.createSwitchLink === 'link') {
      data.emailBody = undefined;
      data.emailTo = undefined;
      data.emailSubject = undefined;
    } else if (this.createSwitchLink === 'email') {
      data.applicationUrl = undefined;
    }
    if (!data || this.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }

    this.sendEdit(data).subscribe(() => this.form.markAsPristine());
  }

  public onDeleteClicked(): void {
    this.jobDataService.deleteJob(this.id).subscribe();
  }

  public onStopClicked(): void {
    this.jobDataService
      .getJobById(this.id)
      .pipe(
        switchMap((data) =>
          this.jobDataService.updateJob(data.id, {
            ...data,
            endDate: new Date().toISOString(),
          })
        )
      )
      .subscribe();
  }

  private sendEdit(data: JobFormData) {
    return this.jobDataService.updateJob(this.id, {
      // readonly should be sent as null
      /* eslint-disable unicorn/no-null */
      id: null,
      status: null,
      viewCount: null,
      pinned: null,
      /* eslint-enable unicorn/no-null */
      employmentType: this.calculateEmploymentType(),
      ...data,
    });
  }

  private calculateEmploymentType(): EmploymentType {
    return routeTypeMap(this.router.url);
  }
}
