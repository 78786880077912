<input
  type="text"
  placeholder="Pick one"
  aria-label="Number"
  matInput
  [formControl]="inputControl"
  [matAutocomplete]="auto"
/>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngFor="let option of filteredCities$ | async" [value]="option">
    {{ option.name }}
  </mat-option>
</mat-autocomplete>
