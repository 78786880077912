import { NewsFeedEntryStatus } from '@otp-junior/admin-client';
import { GameQuizListItem } from '@otp-junior/admin-client/model/gameQuizListItem';
import { GameQuizListElementVM } from '../list/list-element.vm';
import { GameQuizStatus } from '../models/game-quiz-details.vm';

export const mapToGameQuizListElementVM = (
  gameQuiz: GameQuizListItem
): GameQuizListElementVM => ({
  id: gameQuiz.gameQuizId,
  campaignName: gameQuiz.campaignTitle,
  level: gameQuiz.levelTitle,
  status: mapGameQuizStatus(gameQuiz.status),
});

export const mapGameQuizStatus = (
  status: NewsFeedEntryStatus
): GameQuizStatus => {
  switch (status) {
    case NewsFeedEntryStatus.Active:
      return GameQuizStatus.ACTIVE;
    case NewsFeedEntryStatus.Archive:
      return GameQuizStatus.ARCHIVED;
    case NewsFeedEntryStatus.Draft:
      return GameQuizStatus.DRAFT;
    case NewsFeedEntryStatus.Scheduled:
      return GameQuizStatus.TIMED;
  }
};
