import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NewsFeedEntryType } from '@otp-junior/admin-client';

@Component({
  selector: 'web-admin-post-type-icon',
  templateUrl: './post-type-icon.component.html',
  styleUrls: ['./post-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostTypeIconComponent {
  @Input() public type: NewsFeedEntryType;
  public srcMap: Record<NewsFeedEntryType, string> = {
    ARTICLE: 'assets/post/type-icon/article.svg',
    DISCOUNT: 'assets/post/type-icon/discount.svg',
    EVENT: 'assets/post/type-icon/event.svg',
    VIDEO: 'assets/post/type-icon/video.svg',
    VOTE: 'assets/post/type-icon/vote.svg',
    SURVEY: 'assets/post/type-icon/vote.svg',
    QUIZ: 'assets/post/type-icon/event.svg',
    // GAME: 'assets/post/type-icon/game.svg',
    // GAME_QUIZ: 'assets/post/type-icon/game_quiz.svg',
    GAME_QUIZ: 'assets/post/type-icon/gamequiz.svg',
  };
}
