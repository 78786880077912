import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MaterialErrorModule } from '../material-error/material-error.module';
import { DateTimeInputComponent } from './date-time-input.component';

@NgModule({
  declarations: [DateTimeInputComponent],
  imports: [
    CommonModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MaterialErrorModule,
    MatInputModule,
  ],
  exports: [DateTimeInputComponent],
})
export class DateTimeInputModule {}

export * from './date-time-input.component';
