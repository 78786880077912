import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightValueInText',
  pure: true,
})
export class HighlightValueInTextPipe implements PipeTransform {
  public transform(value: string, text: string): string {
    const searchPattern = new RegExp(`(${value})`, 'ig');
    return text.replace(searchPattern, `<b>$1</b>`);
  }
}
