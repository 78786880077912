import {
  GameQuizDto,
  NewsFeedEntryStatus,
  NewsFeedEntryType,
  QuizStep,
} from '@otp-junior/admin-client';
import {
  GameQuizForm,
  GameQuizFormQuestion,
} from '../quiz/form/form.component';

export const mapGameQuizFormToApiModel = (
  formValue: GameQuizForm,
  id?: number
): GameQuizDto => {
  return {
    id: id || undefined,
    gameCampaignId: formValue.campaignId,
    objectType: 'GameQuizDto',
    type: NewsFeedEntryType.GameQuiz,
    status: NewsFeedEntryStatus.Active,
    draft: false,
    description: '', // TODO send level name later
    marketingContent: false,
    startDate: new Date().toISOString(),
    levelId: formValue.levelId,
    steps: mapGameQuizQuestionsToApiModelSteps(formValue.questions),
  };
};

const mapGameQuizQuestionsToApiModelSteps = (
  questions: GameQuizFormQuestion[]
): QuizStep[] =>
  questions.map((questionItem) => ({
    id: undefined,
    imageId: questionItem.imageId ? +questionItem.imageId : undefined,
    question: questionItem.question,
    choices: questionItem.choices.map((choice, index) => ({
      id: undefined,
      value: choice,
      isCorrect: index === 0,
    })),
  }));
