import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsDataService } from '@otp-junior/admin-client';
import { Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { TabStorageService } from '../../../core/storage.service';
import { GameAnalyticsService } from '../services/game-analytics.service';

@Component({
  selector: 'web-admin-analytics-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsTabsComponent implements OnDestroy {
  public tabs = [
    {
      title: 'Cikkek',
      route: 'article',
    },
    {
      title: 'Videók',
      route: 'video',
    },
    {
      title: 'Szavazások',
      route: 'vote',
    },
    {
      title: 'Kedvezmények',
      route: 'discount',
    },
    {
      title: 'Kvízek',
      route: 'quiz',
    },
    {
      title: 'Kutatás',
      route: 'survey',
    },
    {
      title: 'Játék',
      route: 'game',
    },
    {
      title: 'Játékosok',
      route: 'game_players',
    },
    {
      title: 'Játék kvíz',
      route: 'game_quiz',
    },
  ];
  public usersAcceptedMarketingConsent$: Observable<number>;
  public usersRegistered$: Observable<number>;
  public numberOfGamePlayers$: Observable<number>;

  public campaignId$ = this.gameAnalyticsService.campaignId$;

  public exportLoading = false;

  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly analyticsDataService: AnalyticsDataService,
    public readonly gameAnalyticsService: GameAnalyticsService,
    private readonly storageService: TabStorageService,
    private readonly router: Router
  ) {
    const stats$ = this.analyticsDataService.getAggregatedUserStatistics().pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );
    this.usersAcceptedMarketingConsent$ = stats$.pipe(
      map((data) => data.usersAcceptedMarketingConsent)
    );
    this.usersRegistered$ = stats$.pipe(map((data) => data.usersRegistered));
    this.numberOfGamePlayers$ = stats$.pipe(
      map((data) => data.numberOfGamePlayers)
    );
    this.restorePreviousTab();
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd && event.url === '/analytics'
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.restorePreviousTab();
      });
  }

  public onExportLoading(isLoading: boolean): void {
    this.exportLoading = isLoading;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private restorePreviousTab(): void {
    const previousTab = this.storageService.getTabData()?.tab;
    if (previousTab) {
      this.router.navigateByUrl(`/analytics/${previousTab}`);
    } else {
      this.router.navigateByUrl('/analytics/article');
    }
  }
}
