<div class="header-container">
  <h1 class="campaign-header">Játék</h1>
  <div class="campaign-boxes">
    <ng-container *ngIf="stats$ | async as stats">
      <div class="box">
        Regisztált felhasználók:
        <strong data-test="usersPlayed"> {{ stats.usersRegistered }} fő</strong>
      </div>
      <div class="box">
        Játékosok száma:
        <strong data-test="usersPlayed">
          {{ stats.numberOfGamePlayers }} fő</strong
        >
      </div>
    </ng-container>
  </div>
</div>

<web-admin-game-list-filter>
  <a
    *ngIf="isActiveUrl('game/campaign') || isActiveUrl('game/life-stage')"
    mat-flat-button
    color="primary"
    routerLink="campaign/create"
    data-test="create"
  >
    Új Kampány
  </a>
  <a
    *ngIf="isActiveUrl('game/quiz')"
    mat-flat-button
    color="primary"
    routerLink="quiz/create"
    data-test="create"
  >
    Új játék kvíz
  </a>
</web-admin-game-list-filter>

<router-outlet></router-outlet>
