import { EmploymentType } from '@otp-junior/admin-client';

export const routeTypeMap = (routeUrl: string): EmploymentType => {
  if (routeUrl.includes('volunteer')) {
    return EmploymentType.Volunteer;
  }
  if (routeUrl.includes('paid')) {
    return EmploymentType.Paid;
  }
  return EmploymentType.Internship;
};
