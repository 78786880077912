<shared-form-card>
  <h2>Bejegyzés beállításai</h2>
  <br />
  <mat-checkbox [formControl]="controls.marketingContent">
    Marketing tartalom
  </mat-checkbox>
  <br />
  <mat-form-field>
    <mat-label>Esemény dátuma</mat-label>
    <date-time-input [formControl]="controls.eventDate"></date-time-input>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Max. létszám</mat-label>
    <input
      matInput
      [formControl]="controls.maxAttendee"
      data-test="maxAttendee"
    />
    <mat-error *showError></mat-error>
  </mat-form-field>
</shared-form-card>
<web-admin-file-picker
  [formControl]="controls.imageId"
  titleText="Töltsd fel a bejegyzés indexképét"
></web-admin-file-picker>
<shared-form-card class="full-width">
  <h2>Esemény adatai</h2>
  <mat-form-field>
    <mat-label>Esemény címe</mat-label>
    <input matInput [formControl]="controls.title" data-test="title" />
    <mat-hint align="end">
      {{ controls.title.value?.length || 0 }}/{{ maxTitleLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Esemény linkje</mat-label>
    <input matInput [formControl]="controls.eventUrl" data-test="eventUrl" />
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Rövid leírás</mat-label>
    <input
      matInput
      [formControl]="controls.description"
      data-test="description"
    />
    <mat-hint align="end">
      {{ controls.description.value?.length || 0 }}/{{ maxDescriptionLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <ng-content selector="[in-bottom-card]"></ng-content>
</shared-form-card>
