import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  PostCategoryDataService,
  PostCategoryDto,
} from '@otp-junior/admin-client';

@Component({
  selector: 'web-admin-create-category-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCategoryModalComponent {
  public controls = {
    name: new FormControl('', Validators.required),
  };
  public form = new FormGroup(this.controls);

  public constructor(
    private readonly postCategoryDataService: PostCategoryDataService,
    private readonly dialogReference: MatDialogRef<
      CreateCategoryModalComponent,
      PostCategoryDto
    >
  ) {}

  public onCancelClicked(): void {
    this.dialogReference.close();
  }

  public onSubmit(): void {
    this.postCategoryDataService.createPostCategory(this.form.value).subscribe({
      next: (data) => {
        this.dialogReference.close(data);
      },
    });
  }
}
