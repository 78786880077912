import { Component, Optional, Self } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
} from '@angular/forms';
import {
  NewsFeedEntryFilter,
  NewsFeedEntryOrder,
  NewsFeedEntryStatus,
  NewsFeedEntrySummaryDto,
  PostCategoryDataService,
} from '@otp-junior/admin-client';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { typeList } from '../helpers/type-name.list';
import { PostListService } from '../list.service';

@Component({
  selector: 'web-admin-post-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
})
export class PostListFilterComponent implements ControlValueAccessor {
  public controls = {
    // eslint-disable-next-line unicorn/no-null
    type: new FormControl(''),
    category: new FormControl(''),
    order: new FormControl(''),
    status: new FormControl(NewsFeedEntryStatus.Active),
  };
  public form = new FormGroup(this.controls);
  public showSelectors$: Observable<boolean> = this.form.controls.type.valueChanges.pipe(
    startWith(true),
    map((type) => type !== 'GAME_QUIZ')
  );

  public statusList = [
    { name: 'Aktív bejegyzések', value: NewsFeedEntryStatus.Active },
    { name: 'Időzített bejegyzések', value: NewsFeedEntryStatus.Scheduled },
    { name: 'Archivált bejegyzések', value: NewsFeedEntryStatus.Archive },
  ];
  public accessList = [
    {
      name: 'Publikálás ideje szerint',
      value: NewsFeedEntryOrder.PublicationDate,
    },
    { name: 'Elérés szerint', value: NewsFeedEntryOrder.Views },
  ];
  public typeList = typeList;
  private readonly subscription = new Subscription();
  public list$: Observable<NewsFeedEntrySummaryDto[]>;
  public categories$ = this.postCategoryDataService.getAllPostCategory();

  constructor(
    @Self() @Optional() private readonly ngControl: NgControl,
    private readonly listService: PostListService,
    private readonly postCategoryDataService: PostCategoryDataService
  ) {
    this.list$ = this.listService.items$;
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  public writeValue(object: NewsFeedEntryFilter): void {
    if (object) {
      this.form.patchValue(
        {
          ...object,
          type: object.type ? object.type : '',
        },
        { emitEvent: false }
      );
    }
  }
  public registerOnChange(
    function_: (value: NewsFeedEntryFilter) => void
  ): void {
    this.subscription.add(
      this.form.valueChanges.subscribe((value) => {
        function_({
          ...value,
          type: value.type === '' ? undefined : value.type,
        });
      })
    );
  }
  public registerOnTouched(function_: () => void): void {
    this.subscription.add(this.form.statusChanges.subscribe(function_));
  }
  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && !this.form.disabled) {
      this.form.disable();
    }
    if (!isDisabled && !this.form.enabled) {
      this.form.enable();
    }
  }
}
