import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CampaignListStatus, CampaignVM } from '../../models/campaign.vm';
import { CampaignService } from '../../services/campaign.service';
import { CampaignFormComponent } from '../form/form.component';

@Component({
  selector: 'web-admin-edit-campaign',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditCampaignComponent implements OnInit {
  public campaignId: number;
  public campaign$: Observable<CampaignVM>;

  public CampaignListStatus = CampaignListStatus;

  @ViewChild(CampaignFormComponent)
  campaignFormComponent: CampaignFormComponent;

  constructor(
    private readonly campaignService: CampaignService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.campaignId = +this.route.snapshot.paramMap.get('id');
    this.campaign$ = this.campaignService.getCampaign$(this.campaignId);
  }

  public submit(): void {
    const form = this.campaignFormComponent.formGroup;
    form.markAllAsTouched();

    if (form.valid && form.value) {
      this.campaignService
        .updateCampaign$(this.campaignId, form.value)
        .pipe(
          take(1),
          catchError((error) => this.handleError(error, 'edit'))
        )
        .subscribe(() => this.router.navigate(['/game']));
    }
  }

  public stopCampaign(): void {
    this.campaignService
      .archiveCampaign$(this.campaignId)
      .pipe(
        take(1),
        catchError((error) => this.handleError(error, 'stop'))
      )
      .subscribe(() => this.router.navigate(['/game']));
  }

  private handleError<T>(error: T, type: 'stop' | 'edit'): Observable<T> {
    const errorBody = ((error as unknown) as HttpErrorResponse).error;

    if (type === 'edit') {
      if (errorBody.code.includes('GAME_ALREADY_BOOKED_IN_TIME_SLOT')) {
        this.openSnackBar('Erre az időszakra már van futó kampány');
      } else {
        this.openSnackBar(
          'Nem sikerült módosítani a kampányt. Próbáld meg újra.'
        );
      }
    } else {
      this.openSnackBar(
        'Nem sikerült archiválni a kampányt. Próbáld meg újra.'
      );
    }

    return throwError(error);
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'X', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 30000,
    });
  }
}
