import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { finalize, take } from 'rxjs/operators';
import { LexiconItemComponent } from '../form/lexicon-item.component';
import { LexiconWordStatus } from '../models/lexicon.vm';
import { LexiconService } from '../services/lexicon.service';

@Component({
  selector: 'web-admin-edit-lexicon-item',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLexiconItemComponent implements OnInit {
  @ViewChild(LexiconItemComponent)
  public lexiconItemFormComponent: LexiconItemComponent;

  public LexiconWordStatus = LexiconWordStatus;
  public wordId: number;
  public isLoading = false;

  public constructor(
    private readonly snackBar: MatSnackBar,
    private readonly route: ActivatedRoute,
    private readonly lexiconService: LexiconService
  ) {}

  public ngOnInit(): void {
    this.wordId = +this.route.snapshot.paramMap.get('id');
    this.isLoading = true;
    this.lexiconService
      .getLexiconWordDetails$(this.wordId)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((word) => {
        this.lexiconItemFormComponent.initForm(word);
        if (word.status === LexiconWordStatus.DRAFT) {
          this.lexiconItemFormComponent.setAvailableDate();
        }
      });
  }

  public submit(status = LexiconWordStatus.ACTIVE): void {
    const data = this.lexiconItemFormComponent.form.value;
    this.lexiconItemFormComponent.form.markAllAsTouched();

    if (data && this.lexiconItemFormComponent.form.valid) {
      this.lexiconItemFormComponent.form.markAsPristine();
      this.lexiconService
        .updateLexiconWord$(this.wordId, {
          ...data,
          status,
        })
        .pipe(take(1))
        .subscribe();
    } else {
      this.openSnackBar('Töltsd ki az összes mezőt!');
    }
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'X', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 10000,
    });
  }
}
