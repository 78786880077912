<div class="main">
  <div class="header">
    <h1>{{ title }}</h1>
    <button
      mat-raised-button
      data-test="submit"
      color="primary"
      (click)="onExportClicked()"
    >
      <img alt="Export-XLS" src="assets/icons/xls-white.svg" />Export XLS
    </button>
  </div>
  <div class="main-coupon-deatils" *ngIf="data$ | async as data">
    <div class="header">
      <h3>{{ resultsTitle }}</h3>
    </div>
    <div *ngIf="type === 'quiz'" class="publicationDate">
      <img
        src="../../../../assets/survey/pen_and_paper.svg"
        color="primary"
        alt=""
      />
      <p>Publikálva:</p>
      <p>{{ data.startDate | date }}</p>
    </div>
    <div class="details">
      <img src="../../../../assets/survey/quiz.svg" color="primary" alt="" />
      <h2>
        <ng-container>
          {{ data.campaignTitle }} - {{ data.levelTitle }}
        </ng-container>
      </h2>
    </div>
    <div class="header">
      <div *ngIf="type === 'game-quiz'" class="fillers">
        <p class="count">Tét nélkül:</p>
        <p class="count-number">{{ data.responseWithoutBetCount }} Fő</p>
      </div>
      <div *ngIf="type === 'game-quiz'" class="fillers">
        <p class="count">Téttel:</p>
        <p class="count-number">{{ data.responseWithBetCount }} Fő</p>
      </div>
      <div class="fillers">
        <p class="count">Kitöltők:</p>
        <p class="count-number">{{ data.responseCount }} Fő</p>
      </div>
    </div>
  </div>

  <div class="main-results" *ngIf="data$ | async as detailsData">
    <h3 class="title">Eredmények összegzése</h3>

    <div class="box" *ngFor="let item of detailsData.steps; let i = index">
      <div class="question">
        <h4>{{ i + 1 }}. {{ item.question }}</h4>
        <div class="response-count-container">
          <img
            src="../../../../assets/icons/profile.svg"
            color="primary"
            alt=""
          />

          <b>
            {{ item.responseCount }}
          </b>
        </div>
      </div>

      <div class="iterate-box">
        <table>
          <tr *ngFor="let steps of item.choices">
            <td class="box-left">
              <div class="survey-question">
                <span class="answer">
                  {{ steps.isCorrect ? 'Helyes válasz:' : '' }}
                </span>
                {{ steps.answer }}
                <span class="percent">({{ steps.responsePercentage }}% )</span>
              </div>
            </td>
            <td class="box-right">
              <div class="right-progress-box">
                <mat-progress-bar
                  [value]="steps.responsePercentage"
                  [ngClass]="steps.isCorrect ? '' : 'grey-progress'"
                  mode="determinate"
                ></mat-progress-bar>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <!--      <div class="iterate-box">
        <div class="box-left">
          <p class="survey-question" *ngFor="let steps of item.choices">
            <span>
              {{ steps.isCorrect ? 'Helyes válasz:' : '' }}
            </span>
            {{ steps.answer }} ( {{ steps.responsePercentage }}% )
          </p>
        </div>
        <div class="box-right">
          <div *ngFor="let steps of item.choices" class="right-progress-box">
            <mat-progress-bar
              [value]="steps.responsePercentage"
              [ngClass]="steps.isCorrect ? '' : 'grey-progress'"
              mode="determinate"
            ></mat-progress-bar>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</div>
