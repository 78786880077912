import { Injectable } from '@angular/core';
import {
  AnalyticsDataService,
  GameAnalyticsResponse,
  GamePlayersAnalyticsSort,
  GameQuizAnalyticsResponse,
} from '@otp-junior/admin-client';
import { GamePlayersAnalyticsResponse } from '@otp-junior/admin-client/model/models';
import { SortDirection } from '@web-admin/shared-lib';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GameAnalyticsService {
  constructor(private readonly analyticsDataService: AnalyticsDataService) {}

  public campaignId$: BehaviorSubject<number | undefined> = new BehaviorSubject(
    undefined
  );

  public getGamePlayersAnalytics$(
    page: number,
    pageSize = 10,
    campaignId?: number,
    sortBy?: GamePlayersAnalyticsSort,
    sortDirection?: SortDirection
  ): Observable<GamePlayersAnalyticsResponse> {
    this.campaignId$.next(campaignId);
    return this.analyticsDataService.getGamePlayersAnalytics(
      page,
      pageSize,
      campaignId,
      sortBy,
      sortDirection
    );
  }

  public getGameAnalytics$(
    page: number,
    pageSize = 10,
    campaignId?: number
  ): Observable<GameAnalyticsResponse> {
    this.campaignId$.next(campaignId);
    return this.analyticsDataService.getGameAnalytics(
      page,
      pageSize,
      campaignId
    );
  }

  public getGameQuizAnalytics$(
    page: number,
    pageSize = 10,
    campaignId?: number
  ): Observable<GameQuizAnalyticsResponse> {
    this.campaignId$.next(campaignId);
    return this.analyticsDataService.getGameQuizAnalytics(
      page,
      pageSize,
      campaignId
    );
  }

  public resetCampaignId(): void {
    // eslint-disable-next-line unicorn/no-useless-undefined
    this.campaignId$.next(undefined);
  }
}
