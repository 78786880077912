import { Component, Optional, Self } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
} from '@angular/forms';
import {
  NotificationFilter,
  NotificationFilterStatusEnum,
} from '@otp-junior/admin-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'web-admin-notification-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
})
export class NotificationListFilterComponent implements ControlValueAccessor {
  public controls = {
    status: new FormControl(NotificationFilterStatusEnum.ScheduledAndPending),
  };
  public form = new FormGroup(this.controls);

  public statusList = [
    {
      name: 'Időzített üzenetek',
      value: NotificationFilterStatusEnum.ScheduledAndPending,
    },
    { name: 'Kiküldött üzenetek', value: NotificationFilterStatusEnum.Sent },
  ];
  private readonly subscription = new Subscription();

  constructor(@Self() @Optional() private readonly ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  public writeValue(object: NotificationFilter): void {
    if (object) {
      this.form.setValue(object, { emitEvent: false });
    }
  }
  public registerOnChange(
    function_: (value: NotificationFilter) => void
  ): void {
    this.subscription.add(this.form.valueChanges.subscribe(function_));
  }
  public registerOnTouched(function_: () => void): void {
    this.subscription.add(this.form.statusChanges.subscribe(function_));
  }
  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && !this.form.disabled) {
      this.form.disable();
    }
    if (!isDisabled && !this.form.enabled) {
      this.form.enable();
    }
  }
}
