<shared-form-card [formGroup]="form">
  <h2>Kvíz beállításai</h2>
  <mat-checkbox formControlName="marketingContent">
    Marketing tartalom
  </mat-checkbox>
  <div class="columns">
    <mat-form-field>
      <mat-label>Megjelenés kezdete</mat-label>
      <date-time-input formControlName="startDate"></date-time-input>
      <mat-error *showError></mat-error>
    </mat-form-field>
  </div>
</shared-form-card>

<shared-form-card class="full-width" [formGroup]="form">
  <h2>Bejegyzés</h2>
  <h4>Ez a szöveg jelenik meg a hírfolyamban:</h4>
  <mat-form-field>
    <mat-label>Kvíz ajánló szövege</mat-label>
    <textarea
      formControlName="description"
      matInput
      cdkTextareaAutosize
      maxlength="200"
    ></textarea>
    <mat-hint align="end">
      {{ description?.value?.length || 0 }}/{{ 200 }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <h2 class="title-questions">Kérdések</h2>
  <ng-container
    formArrayName="steps"
    *ngFor="let questionForm of steps; let i = index"
  >
    <shared-form-card [formGroup]="questionForm" class="full-width inner-card">
      <div class="header">
        <h2>{{ i + 1 }}. kérdés</h2>
        <a
          *ngIf="steps.length > 1"
          mat-button
          type="button"
          color="warn"
          (click)="removeQuestion(i)"
        >
          <mat-icon>delete</mat-icon>
          Kérdés törlése
        </a>
      </div>
      <p class="hint">Kérdés képe <span>(nem kötelező)</span></p>
      <web-admin-file-picker
        [erasable]="true"
        formControlName="imageId"
        titleText="Töltsd fel a kérdés indexképét"
      ></web-admin-file-picker>
      <mat-form-field>
        <mat-label>Kérdés szövege</mat-label>
        <textarea
          cdkTextareaAutosize
          formControlName="question"
          matInput
        ></textarea>
        <mat-error *showError></mat-error>
      </mat-form-field>

      <ng-container *ngIf="getAnswers(questionForm) as choices">
        <div class="answer-box">
          <mat-form-field class="answer">
            <mat-label class="label">
              <img
                alt="Zöld pipa"
                src="../../../../assets/survey/green-tick.svg"
              />
              Helyes válasz</mat-label
            >
            <textarea
              cdkTextareaAutosize
              maxlength="100"
              matInput
              [formControl]="choices[0]"
            ></textarea>
            <mat-hint align="end">
              {{ choices[0].value?.length || 0 }}/{{ 100 }}
            </mat-hint>
            <mat-error *showError></mat-error>
          </mat-form-field>
        </div>
        <div class="answer-box">
          <mat-form-field class="answer">
            <mat-label class="label">
              <img
                src="../../../../assets/survey/red-cross.svg"
                alt="Piros X"
              />Hibás válasz 1.</mat-label
            >
            <textarea
              matInput
              maxlength="100"
              cdkTextareaAutosize
              [formControl]="choices[1]"
            ></textarea>
            <mat-hint align="end">
              {{ choices[1].value?.length || 0 }}/{{ 100 }}
            </mat-hint>
            <mat-error *showError></mat-error>
          </mat-form-field>
        </div>
        <div class="answer-box">
          <mat-form-field class="answer">
            <mat-label class="label"
              ><img
                src="../../../../assets/survey/red-cross.svg"
                alt="Piros X"
              />Hibás válasz 2.</mat-label
            >
            <textarea
              matInput
              cdkTextareaAutosize
              maxlength="100"
              [formControl]="choices[2]"
            ></textarea>
            <mat-hint align="end">
              {{ choices[2].value?.length || 0 }}/{{ 100 }}
            </mat-hint>
            <mat-error *showError></mat-error>
          </mat-form-field>
        </div>
        <div class="answer-box">
          <mat-form-field class="answer">
            <mat-label class="label"
              ><img
                src="../../../../assets/survey/red-cross.svg"
                alt="Piros X"
              />Hibás válasz 3.</mat-label
            >
            <textarea
              matInput
              maxlength="100"
              cdkTextareaAutosize
              [formControl]="choices[3]"
            ></textarea>
            <mat-hint align="end">
              {{ choices[3].value?.length || 0 }}/{{ 100 }}
            </mat-hint>
            <mat-error *showError></mat-error>
          </mat-form-field>
        </div>
      </ng-container>
      <mat-form-field class="more-info">
        <mat-label class="label">
          További információ a kérdéssel kapcsolatban (a helyes válasz
          <br />
          megjelenítésekor mutatjuk - opcionális)
        </mat-label>
        <textarea
          cdkTextareaAutosize
          matInput
          formControlName="moreInfo"
          maxlength="1000"
          rows="7"
        ></textarea>
        <!-- <mat-hint align="end">
            {{ questionForm.controls.moreInfo.value?.length || 0 }}/{{ 1000 }}
          </mat-hint> -->
        <mat-error *showError></mat-error>
      </mat-form-field>
    </shared-form-card>
  </ng-container>
  <a
    mat-stroked-button
    type="button"
    color="primary"
    class="add"
    (click)="addQuestion()"
  >
    <mat-icon>add</mat-icon>
    Még egy kérdés hozzáadása
  </a>
  <shared-form-card [formGroup]="form" class="last-shared-card">
    <h4 class="last-h4">
      Ha van kapcsolódó hírfolyam elem, itt tudod ajánlani a kvíz végén
      <span>(nem kötelező)</span>
    </h4>
    <mat-form-field>
      <mat-label>Válaszd ki a hírfolyam elemet</mat-label>
      <mat-select
        formControlName="linkedNewsFeedItem"
        [disableOptionCentering]="true"
      >
        <mat-option *ngFor="let post of allPost" [value]="post.id">
          {{ post.title }}
        </mat-option>
      </mat-select>
      <mat-error *showError></mat-error>
    </mat-form-field>
  </shared-form-card>
</shared-form-card>
