import { Injectable } from '@angular/core';
import { RequestHandler, setupWorker } from 'msw';
import { analyticsHandlers } from './handlers/analytics.handler';
import { lexiconHandlers } from './handlers/lexicon.handler';
import { newsFeedHandlers } from './handlers/newsfeed.handler';

@Injectable({
  providedIn: 'root',
})
export class MockServiceWorkerService {
  public async init(backendUrl: string): Promise<void> {
    const handlers: RequestHandler[] = [
      ...lexiconHandlers,
      ...newsFeedHandlers,
      ...analyticsHandlers,
    ];

    for (const handler of handlers) {
      handler.info.mask = `${backendUrl}${handler.info.mask}`;
    }

    const worker = setupWorker(...handlers);
    await worker.start({
      serviceWorker: {
        url: '/mockServiceWorker.js',
      },
      onUnhandledRequest: 'bypass',
    });
  }
}
