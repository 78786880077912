/* eslint-disable @typescript-eslint/no-explicit-any */

import { Location } from '@angular/common';
import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NewsFeedEntry } from '@otp-junior/admin-client';
import { ConfirmModalData, ModalService } from '@web-admin/shared-lib';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { HandleOptions } from './handle-options';

@Injectable()
export class EditInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly location: Location
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('admin/lexicon/words/')) {
      if (request.method === 'PUT') {
        return this.openConfirmationModal$(request, {
          title: 'Biztos, hogy módosítod a lexikon bejegyzést?',
          description: 'Ha módosítod a bejegyzést, a változások érvényesülnek',
          confirmText: 'Módosítás',
        }).pipe(
          filter((x) => x),
          switchMap(() =>
            this.handleRequest(request, next, {
              success: {
                message: 'Sikeresen módosítottad a kampányt',
                actionLabel: 'OK',
              },
              returnRoute: '/lexicon/lexicon',
            })
          )
        );
      } else if (request.method === 'DELETE') {
        return this.openConfirmationModal$(request, {
          title: 'Biztos, hogy törlöd a lexikon bejegyzést?',
          description:
            'Ha törlöd a bejegyzést akkor az nem lesz többet elérhető a felhasználók számára',
          confirmText: 'Törlöm',
        }).pipe(
          filter((x) => x),
          switchMap(() =>
            this.handleRequest(request, next, {
              success: {
                message: 'Sikeresen törölted a lexikon bejegyzést',
                actionLabel: 'OK',
              },
              returnRoute: '/lexicon/lexicon',
            })
          )
        );
      }
    }
    return next.handle(request);
  }

  private openConfirmationModal$(
    request: HttpRequest<NewsFeedEntry | number>,
    modalOptions: ConfirmModalData
  ): Observable<boolean> {
    if (request?.body && (request?.body as NewsFeedEntry).draft) {
      return of(true);
    }

    return this.modalService.openConfirmModal(modalOptions).afterClosed();
  }

  private handleRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    options: HandleOptions
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        this.openSnackBar(
          'failure',
          options.error.message,
          options.error.actionLabel
        );

        return throwError(error);
      }),
      tap((result) => {
        if (result.type === HttpEventType.Response) {
          this.openSnackBar(
            'success',
            options.success.message,
            options.success.actionLabel
          );
          this.router.navigateByUrl(options.returnRoute);
        }
      })
    );
  }

  private openSnackBar(
    type: 'success' | 'failure',
    message: string,
    actionLabel = 'X'
  ): void {
    this.snackBar.open(message, actionLabel, {
      panelClass: ['snack-bar-container', type],
      duration: 3000,
    });
  }
}
