<h1>Bejegyzések</h1>
<web-admin-post-list-filter [formControl]="filterControl">
  <web-admin-create-post-drop-down></web-admin-create-post-drop-down>
</web-admin-post-list-filter>
<ng-container *ngIf="(loading$ | async) === false">
  <ng-container *ngIf="list$ | async as list">
    <ng-container *ngIf="list.length > 0; else notFound">
      <table aria-label="Bejegyzések lista" mat-table [dataSource]="list">
        <ng-container matColumnDef="icon">
          <th id="icon-header" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <web-admin-post-type-icon
              [type]="element.type"
            ></web-admin-post-type-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th id="title-header" mat-header-cell *matHeaderCellDef>Cím</th>
          <td mat-cell *matCellDef="let element">
            <strong>{{ element.title }}</strong>
          </td>
        </ng-container>
        <ng-container matColumnDef="category">
          <th id="category-header" mat-header-cell *matHeaderCellDef>
            Kategória
          </th>
          <td mat-cell *matCellDef="let element">
            <web-admin-post-category-title
              [categoryTitle]="element.categoryTitle"
            ></web-admin-post-category-title>
          </td>
        </ng-container>
        <ng-container matColumnDef="publish">
          <th id="publish-header" mat-header-cell *matHeaderCellDef>
            Publikálás ideje
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.startDate">
              <strong>{{ element.startDate | date: 'short' }}</strong
              >-tól
            </ng-container>
            <ng-container *ngIf="element.endDate">
              <strong>{{ element.endDate | date: 'short' }}</strong
              >-ig
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="reactions">
          <th
            id="reactions-header"
            mat-header-cell
            *matHeaderCellDef
            class="right"
          >
            Elérés
          </th>
          <td mat-cell *matCellDef="let element">
            <web-admin-post-reactions
              *ngIf="element.type !== NewsFeedEntryType.GameQuiz"
              [view]="element.viewCount"
              [reactions]="element.reactionCount"
            ></web-admin-post-reactions>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th
            id="status-header"
            mat-header-cell
            *matHeaderCellDef
            class="right"
          >
            Státusz
          </th>
          <td mat-cell *matCellDef="let element">
            <web-admin-post-status-icon
              [status]="element.status"
            ></web-admin-post-status-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th id="edit-header" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="right">
            <a mat-button color="primary" (click)="getRouterLink(element)">
              <img src="assets/icons/edit.svg" alt="Szerkesztés icon" />
              Szerkesztés
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns$ | async"
          [class.archive]="row.status === 'ARCHIVE'"
        ></tr>
      </table>
      <mat-paginator
        [pageIndex]="pageNumber$ | async"
        [length]="count$ | async"
        [pageSize]="10"
        [pageSizeOptions]="[10]"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #notFound>
  <shared-list-empty>
    Nincsenek bejegyzések ebben a státuszban.
  </shared-list-empty>
</ng-template>
