import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JobStatus } from '@otp-junior/admin-client';

@Component({
  selector: 'web-admin-job-edit-status-indicator',
  templateUrl: './edit-status-indicator.component.html',
  styleUrls: ['./edit-status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobEditStatusIndicatorComponent {
  @Input() public status: JobStatus;
  public JobStatus = JobStatus;
}
