import { Location } from '@angular/common';
import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ModalService } from '@web-admin/shared-lib';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class DeleteInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly location: Location
  ) {}

  private selectDeleteForm(
    url: string
  ): {
    title: string;
    snackBarsSuccess: string;
    snackBarFail: string;
    route: string;
  } {
    if (/admin\/job\/\d+$/.test(url)) {
      return {
        title: 'Biztos, hogy törlöd az állást?',
        snackBarsSuccess: 'Sikeresen törölted az állást',
        snackBarFail: 'Nem sikerült törölni az állást. Próbáld meg újra.',
        route: 'job',
      };
    } else if (/admin\/notification\/\d+$/.test(url)) {
      return {
        title: 'Biztos, hogy törlöd a push üzenetet?',
        snackBarsSuccess: 'Sikeresen töröltük a Push üzenetet',
        snackBarFail: 'Nem sikerült törölni a Push üzenetet. Próbáld meg újra.',
        route: 'notification',
      };
    } else if (
      /admin\/news-feed-entry\/\d+$/.test(url) &&
      !this.location.path().includes('game/quiz/edit/')
    ) {
      return {
        title: 'Biztos, hogy törlöd a bejegyzést?',
        snackBarsSuccess: 'Sikeresen törölted a bejegyzést',
        snackBarFail: 'Nem sikerült törölni a bejegyzést. Próbáld meg újra.',
        route: 'post',
      };
    } else if (
      /admin\/news-feed-entry\/\d+$/.test(url) &&
      this.location.path().includes('game/quiz/edit/')
    ) {
      return {
        title: 'Biztos, hogy törlöd a kvízt?',
        snackBarsSuccess: 'Sikeresen törölted a kvízt',
        snackBarFail: 'Nem sikerült törölni a kvízt. Próbáld meg újra.',
        route: '/game/quiz',
      };
    } else {
      return;
    }
  }
  private callInterceptor(
    snackBar: MatSnackBar,
    router: Router,
    modalService: ModalService,
    next: HttpHandler,
    request: HttpRequest<unknown>
  ): Observable<HttpEvent<unknown>> {
    const modalAndSnack = this.selectDeleteForm(request.url);
    return modalService
      .openConfirmModal({
        title: modalAndSnack.title,
        description: 'A módosításaid végleg törlődnek.',
        confirmText: 'Törlöm',
        color: 'warn',
      })
      .afterClosed()
      .pipe(
        filter((x) => x),
        switchMap(() =>
          next.handle(request).pipe(
            tap((result) => {
              if (result.type === HttpEventType.Response) {
                snackBar.open(modalAndSnack.snackBarsSuccess, 'OK', {
                  panelClass: ['snack-bar-container', 'success'],
                  duration: 3000,
                });
                router.navigateByUrl(`/${modalAndSnack.route}`);
              }
            }),
            catchError((error) => {
              snackBar.open(modalAndSnack.snackBarFail, 'OK', {
                panelClass: ['snack-bar-container', 'failure'],
                duration: 3000,
              });
              return of(error);
            })
          )
        )
      );
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.method !== 'DELETE') {
      return next.handle(request);
    }
    if (
      /admin\/news-feed-entry\/\d+$/.test(request.url) ||
      /admin\/notification\/\d+$/.test(request.url) ||
      /admin\/job\/\d+$/.test(request.url)
    ) {
      return this.callInterceptor(
        this.snackBar,
        this.router,
        this.modalService,
        next,
        request
      );
    }
    return next.handle(request);
  }
}
