import { Injectable } from '@angular/core';
import {
  LexiconDataService,
  LexiconInfoResponse,
  LexiconWordResponse,
  LexiconWordSort,
  PostDataService,
  SortDirection,
} from '@otp-junior/admin-client';
import { DropdownVM } from '@web-admin/shared-lib';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LexiconItemForm } from '../form/lexicon-item.vm';
import {
  mapLexiconWordListItemResponseToDropdownItem,
  mapLexiconWordResponseToCreateOrUpdateLexiconWordRequest,
  mapLexiconWordResponseToLexiconItemForm,
  mapNewsFeedEntryUndetailedToLexiconDropdownItem,
  mapToLexiconVM,
} from '../mappers/map-to-lexiconvm';
import { LexiconListVM } from '../models/lexicon.vm';

@Injectable()
export class LexiconService {
  public constructor(
    private readonly lexiconDataService: LexiconDataService,
    private readonly postDataService: PostDataService
  ) {}

  public searchLexiconWords$(searchWord: string): Observable<DropdownVM> {
    return this.lexiconDataService.searchLexiconWords(searchWord).pipe(
      map(
        (response): DropdownVM => {
          const content = response.map((element) => {
            return mapLexiconWordListItemResponseToDropdownItem(element);
          });
          return {
            isLoading: false,
            content,
          };
        }
      )
    );
  }

  public getLexiconWords$(
    page: number,
    pageSize = 10,
    sortBy?: LexiconWordSort,
    sortDirection?: SortDirection
  ): Observable<LexiconListVM> {
    return this.lexiconDataService
      .getLexiconWords(page, pageSize, sortBy, sortDirection)
      .pipe(
        map((response) => ({
          totalElements: response.totalElements,
          items: response.content.map((content) => mapToLexiconVM(content)),
        }))
      );
  }

  public getLexiconInfo$(): Observable<LexiconInfoResponse> {
    return this.lexiconDataService
      .getLexiconInfo()
      .pipe(filter((response) => !!response));
  }

  public getLexiconWordDetails$(id: number): Observable<LexiconItemForm> {
    return this.lexiconDataService
      .getLexiconWordDetails(id)
      .pipe(map(mapLexiconWordResponseToLexiconItemForm));
  }

  public createLexiconWord$(
    word: LexiconItemForm
  ): Observable<LexiconWordResponse> {
    return this.lexiconDataService.createLexiconWord(
      mapLexiconWordResponseToCreateOrUpdateLexiconWordRequest(word)
    );
  }

  public updateLexiconWord$(
    id: number,
    word: LexiconItemForm
  ): Observable<LexiconWordResponse> {
    return this.lexiconDataService.updateLexiconWord(
      id,
      mapLexiconWordResponseToCreateOrUpdateLexiconWordRequest(word)
    );
  }

  public getNewsFeedItems$(searchWord: string): Observable<DropdownVM> {
    return this.postDataService.searchNewsFeedEntriesByTitle(searchWord).pipe(
      map(
        (response): DropdownVM => {
          const content = response.map((element) => {
            return mapNewsFeedEntryUndetailedToLexiconDropdownItem(element);
          });
          return {
            isLoading: false,
            content,
          };
        }
      )
    );
  }

  public deleteLexiconWord$(id: number): Observable<void> {
    return this.lexiconDataService.deleteLexiconWord(id);
  }
}
