<div
  #dropDownContainer
  class="dropdown-container"
  [class.disabled]="disabled"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  data-test="dropdown-container"
  (click)="openDropDown()"
>
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input matInput [formControl]="innerControl" data-test="search-input" />
    <mat-icon matPrefix *ngIf="icon">
      {{ icon }}
    </mat-icon>
  </mat-form-field>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="overlayPositionStrategy"
  [cdkConnectedOverlayWidth]="dropDownContainer.offsetWidth"
  (overlayOutsideClick)="onOverlayOutsideClick($event)"
>
  <div class="overlay-menu">
    <ng-container *ngIf="options$ | async as options">
      <ng-container *ngIf="!options.isLoading; else loader">
        <ng-container *ngIf="!!options.content">
          <ng-container *ngIf="options.content.length; else empty">
            <div
              *ngFor="let option of options.content; let index = index"
              class="option-container"
            >
              <button
                class="text-small option px-2"
                (click)="onOptionClick(option)"
                [attr.data-test]="'option-' + index"
              >
                <web-admin-type-icon
                  *ngIf="option.type"
                  [type]="option.type"
                ></web-admin-type-icon>
                <span
                  [innerHTML]="
                    innerControl.value | highlightValueInText: option.name
                  "
                ></span>
              </button>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #empty>
          <div class="option-container">
            <button class="text-small option px-2">Nincs találat</button>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #loader>
        <div class="option-container">
          <button class="text-small option px-2">
            <img
              class="load-icon"
              alt="Export-XLS"
              src="assets/icons/load.svg"
            />
          </button>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
