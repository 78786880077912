<button mat-flat-button [matMenuTriggerFor]="menu" color="primary">
  Új bejegyzés <img src="assets/icons/arrow.svg" alt="Nyíl icon" />
</button>
<mat-menu #menu="matMenu" overlapTrigger xPosition="before">
  <button
    *ngFor="let type of typeList"
    mat-menu-item
    [attr.data-test]="'item-' + type.value"
    (click)="routeToCreate(type.value)"
  >
    {{ type.name }}
  </button>
</mat-menu>
