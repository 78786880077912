import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CampaignVM } from '../../models/campaign.vm';
import { CampaignService } from '../../services/campaign.service';
import { CampaignFormComponent } from '../form/form.component';

@Component({
  selector: 'web-admin-create-campaign',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCampaignComponent implements OnInit {
  @ViewChild(CampaignFormComponent) campaignForm: CampaignFormComponent;
  public campaign$: Observable<Pick<CampaignVM, 'lifeStages'>>;

  constructor(
    private readonly campaignService: CampaignService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.campaign$ = this.campaignService.getCampaignOptions$().pipe(take(1));
  }

  public submit(): void {
    const form = this.campaignForm.formGroup;
    form.markAllAsTouched();

    if (form.valid && form.value) {
      this.campaignService
        .createCampaign$(this.campaignForm.formGroup.value)
        .pipe(
          take(1),
          catchError((error) => this.handleError(error))
        )
        .subscribe(() => this.router.navigate(['/game']));
    }
  }

  private handleError<T>(error: T): Observable<T> {
    const errorBody = ((error as unknown) as HttpErrorResponse).error;

    if (errorBody.code.includes('GAME_ALREADY_BOOKED_IN_TIME_SLOT')) {
      this.openSnackBar('Erre az időszakra már van futó kampány');
    } else {
      this.openSnackBar(
        'Nem sikerült a kampányt létrehozni. Próbáld meg újra.'
      );
    }

    return throwError(error);
  }

  private openSnackBar(message: string) {
    this.snackBar.open(message, 'X', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 30000,
    });
  }
}
