<div class="header">
  <h1>Új nyilatkozat hozzáadása</h1>
  <button mat-icon-button (click)="close(false)">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="forms">
  <h2>Nyilatkozat beállításai</h2>

  <mat-form-field>
    <mat-label>Típus</mat-label>
    <mat-select [formControl]="typeControl">
      <mat-option *ngFor="let type of types" [value]="type">{{
        type
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Érvényesség kezdete</mat-label>
    <date-time-input [formControl]="startDateTimeControl"></date-time-input>
    <mat-error *showError></mat-error>
  </mat-form-field>

  <web-admin-pdf-uploader
    [formControl]="fileInputControl"
  ></web-admin-pdf-uploader>
</div>

<div class="buttons">
  <button mat-button color="primary" (click)="close(false)">Elvetés</button>
  <button mat-flat-button color="primary" (click)="upload()">Feltöltöm</button>
</div>
