import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { ListEmptyModule } from '@web-admin/shared-lib';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminListComponent } from './list/list.component';

@NgModule({
  declarations: [AdminListComponent],
  imports: [
    AdminRoutingModule,
    CommonModule,
    ListEmptyModule,
    MatButtonModule,
    MatTableModule,
  ],
})
export class AdminModule {}
