export interface GameQuizDetailsVM {
  id: number;
  campaignId: number;
  levelId: number;
  isDraft: boolean;
  status: GameQuizStatus;
  questions: GameQuizQuestion[];
}

export interface GameQuizQuestion {
  id: number;
  question: string;
  imageId?: number;
  choices: GameQuizQuestionChoice[];
}

export interface GameQuizQuestionChoice {
  id: number;
  value: string;
  isCorrect: boolean;
}

export enum GameQuizStatus {
  TIMED = 'TIMED',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
}
