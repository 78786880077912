import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'web-admin-post-create-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCreateButtonsComponent {
  @Output() public draft = new EventEmitter();
  public onDraftClick(): void {
    this.draft.emit();
  }
}
