import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import {
  NotificationDataService,
  NotificationDto,
} from '@otp-junior/admin-client';
import { Observable } from 'rxjs';
import { NotificationListService } from '../list.service';

@Component({
  selector: 'web-admin-notification-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent implements OnInit {
  public filterControl = new FormControl();
  public count$: Observable<number>;
  public loading$: Observable<boolean>;
  public list$: Observable<NotificationDto[]>;
  public pageNumber$: Observable<number>;
  public displayedColumns = ['title', 'scheduledAt', 'status', 'edit'];

  public constructor(
    private readonly listService: NotificationListService,
    private readonly notificationDataService: NotificationDataService
  ) {}

  public ngOnInit(): void {
    this.filterControl.valueChanges.subscribe((filter) =>
      this.listService.setFilter(filter)
    );
    this.list$ = this.listService.items$;
    this.count$ = this.listService.totalElements$;
    this.loading$ = this.listService.loading$;
    this.pageNumber$ = this.listService.pageNumber$;
  }

  public onPageChange(event: PageEvent): void {
    this.listService.setPage(event.pageIndex);
  }

  public onDeleteClicked(element: NotificationDto): void {
    this.notificationDataService.deleteNotification(element.id).subscribe();
  }
}
