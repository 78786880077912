import { Injectable } from '@angular/core';
import {
  GameCampaignDataService,
  UpdateLifeStageRequest,
} from '@otp-junior/admin-client';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { mapToCampaignLevelVm } from '../mappers/map-to-campaign-level-vm';
import {
  mapLifeStageToLifeStageRequest,
  mapToCampaignVM,
} from '../mappers/map-to-campaignvm';
import { mapToLifeStageVM } from '../mappers/map-to-life-stage-vm';
import { CampaignListVM, CampaignVM } from '../models/campaign.vm';
import { CampaignLevelVM } from '../models/game-level.vm';
import { LifeStageListVM } from '../models/life-stage.vm';

@Injectable()
export class CampaignService {
  constructor(
    private readonly gameCampaignDataService: GameCampaignDataService
  ) {}

  public getCampaigns$(
    page: number,
    pageSize = 10
  ): Observable<CampaignListVM> {
    return this.gameCampaignDataService.getGameCampaigns(page, pageSize).pipe(
      map((response) => ({
        totalElements: response.totalElements,
        items: response.content.map((content) => mapToCampaignVM(content)),
      }))
    );
  }

  public getLifeStages$(
    page: number,
    pageSize = 10
  ): Observable<LifeStageListVM> {
    return this.gameCampaignDataService
      .getGameCampaignLifeStages(page, pageSize)
      .pipe(
        map((response) => ({
          totalElements: response.totalElements,
          items: response.content.map((content) => mapToLifeStageVM(content)),
        }))
      );
  }

  public getCampaign$(
    id: number,
    lifeStageId?: number
  ): Observable<CampaignVM> {
    return this.gameCampaignDataService
      .getGameCampaign(id)
      .pipe(map((campaign) => mapToCampaignVM(campaign, lifeStageId)));
  }

  public getCampaignOptions$(): Observable<Pick<CampaignVM, 'lifeStages'>> {
    return this.gameCampaignDataService.getNewGameCampaignOptions().pipe(
      shareReplay({
        refCount: true,
        bufferSize: 1,
      }),
      catchError(() => {
        return of({
          lifeStages: [],
        });
      }),
      map((lifeStagesResponse) => {
        return {
          lifeStages: lifeStagesResponse?.lifeStages ?? [],
        };
      })
    );
  }

  public createCampaign$(campaign: CampaignVM): Observable<unknown> {
    return this.gameCampaignDataService.createGameCampaign({
      name: campaign.name,
      startDate: campaign.startDate,
      endDate: campaign.endDate,
      lifeStages: campaign.lifeStages.map((lifeStage) =>
        mapLifeStageToLifeStageRequest(lifeStage)
      ),
    });
  }

  public updateCampaign$(
    id: number,
    campaign: CampaignVM
  ): Observable<unknown> {
    return this.gameCampaignDataService.updateGameCampaign(id, {
      name: campaign.name,
      startDate: campaign.startDate,
      endDate: campaign.endDate,
    });
  }

  public getCampaignLevels$(withoutQuiz = true): Observable<CampaignLevelVM[]> {
    return this.gameCampaignDataService.getGameCampaignLevels(withoutQuiz).pipe(
      map((response) => response.content),
      map((levels) => levels.map((level) => mapToCampaignLevelVm(level)))
    );
  }

  public updateLifeStage$(
    id: number,
    updateLifeStageRequest: UpdateLifeStageRequest
  ): Observable<unknown> {
    return this.gameCampaignDataService.updateGameCampaignLifeStage(
      id,
      updateLifeStageRequest
    );
  }

  public archiveCampaign$(id: number): Observable<unknown> {
    return this.gameCampaignDataService.archiveGameCampaign(id);
  }
}
