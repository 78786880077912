import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  debounceTimeAfterFirst,
  DropdownListItem,
  DropdownVM,
} from '@web-admin/shared-lib';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { mapToLexiconVM } from '../mappers/map-to-lexiconvm';
import { LexiconVM } from '../models/lexicon.vm';
import { LexiconService } from '../services/lexicon.service';

const debounceTime = 300;
const minWordLength = 3;
@Component({
  selector: 'web-admin-campaign-game-quiz-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public todaysWord$: Observable<LexiconVM>;
  public controls = {
    search: new FormControl(undefined),
  };
  public formGroup = new FormGroup(this.controls);

  public searchSubject$: Subject<string> = new Subject();
  public options$ = this.searchSubject$.asObservable().pipe(
    debounceTimeAfterFirst(debounceTime),
    switchMap((searchWord: string) => {
      if (searchWord.length < minWordLength) {
        return of({ isLoading: false } as DropdownVM);
      }
      return this.lexiconService.searchLexiconWords$(searchWord).pipe(
        startWith({ isLoading: true, content: [] }),
        catchError(() => {
          return of({ isLoading: false, content: [] });
        })
      );
    })
  );

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor(
    private readonly router: Router,
    private readonly lexiconService: LexiconService
  ) {}

  public ngOnInit(): void {
    this.todaysWord$ = this.lexiconService.getLexiconInfo$().pipe(
      map((response) => mapToLexiconVM(response.wordOfTheDay)),
      takeUntil(this.destroy$)
    );
  }

  public onSearchFor(searchWord: string): void {
    this.searchSubject$.next(searchWord);
  }

  public onPickOption(option: DropdownListItem): void {
    this.router.navigate(['lexicon/lexicon/edit', option.id]);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isActiveUrl(url: string): boolean {
    return this.router.isActive(url, true);
  }
}
