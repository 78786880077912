import {
  Directive,
  Inject,
  Input,
  Optional,
  Self,
  SkipSelf,
} from '@angular/core';
import {
  AsyncValidator,
  AsyncValidatorFn,
  ControlContainer,
  FormGroup,
  FormGroupDirective,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  Validator,
  ValidatorFn,
} from '@angular/forms';

@Directive({
  selector: '[formGroupPass]',
  providers: [
    {
      provide: FormGroupDirective,
      useExisting: FormGroupPassDirective,
    },
    {
      provide: ControlContainer,
      useExisting: FormGroupPassDirective,
    },
  ],
  exportAs: 'formGroupPass',
})
export class FormGroupPassDirective extends FormGroupDirective {
  @Input() public set formGroupPass(formGroup: FormGroup) {
    this.form = formGroup;
  }
  public constructor(
    @Optional()
    @SkipSelf()
    private readonly parent: FormGroupDirective,
    @Optional()
    @Self()
    @Inject(NG_VALIDATORS)
    validators: (Validator | ValidatorFn)[],
    @Optional()
    @Self()
    @Inject(NG_ASYNC_VALIDATORS)
    asyncValidators: (AsyncValidator | AsyncValidatorFn)[]
  ) {
    super(validators, asyncValidators);
    if (this.parent) {
      this.parent.ngSubmit.subscribe((event) => this.onSubmit(event));
    }
  }
}
