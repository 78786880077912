import {
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImageDto, MediaLibraryDataService } from '@otp-junior/admin-client';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

interface UploadReporter {
  file: Observable<ImageDto>;
  percentage: Observable<number>;
}

const responseFilter = <T>(event: HttpEvent<T>): event is HttpResponse<T> =>
  event.type === HttpEventType.Response;
const uploadProgressFilter = <T>(
  event: HttpEvent<T>
): event is HttpProgressEvent => event.type === HttpEventType.UploadProgress;

@Injectable({
  providedIn: 'root',
})
export class FileUploaderService {
  public constructor(
    private readonly mediaLibraryDataService: MediaLibraryDataService
  ) {}

  public uploadFile(file: File): UploadReporter {
    const uploader = this.mediaLibraryDataService
      .uploadImage(file, 'events', true)
      .pipe(
        shareReplay({
          refCount: true,
          bufferSize: 0,
        })
      );
    return {
      file: uploader.pipe(
        filter(responseFilter),
        map((x) => x.body)
      ),
      percentage: uploader.pipe(
        filter(uploadProgressFilter),
        // eslint-disable-next-line no-magic-numbers
        map((x) => Math.round((100 * x.loaded) / x.total))
      ),
    };
  }

  public getFileById(id: number): Observable<ImageDto> {
    return this.mediaLibraryDataService.getImageById(id);
  }
}
