import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NewsFeedEntryStatus } from '@otp-junior/admin-client';

@Component({
  selector: 'web-admin-edit-status-indicator',
  templateUrl: './edit-status-indicator.component.html',
  styleUrls: ['./edit-status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditStatusIndicatorComponent {
  @Input() public status: NewsFeedEntryStatus;
  public NewsFeedEntryStatus = NewsFeedEntryStatus;
}
