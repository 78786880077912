<div>
  <a
    mat-stroked-button
    type="button"
    data-test="discard"
    color="primary"
    routerLink="/post"
  >
    Elvetés
  </a>
  <button
    *ngIf="removable; else archive"
    mat-stroked-button
    type="button"
    data-test="delete"
    color="warn"
    (click)="onDeleteClick()"
  >
    Végleges törlés
  </button>
  <ng-template #archive>
    <button
      mat-stroked-button
      type="button"
      data-test="archive"
      color="warn"
      (click)="onArchiveClick()"
    >
      Archiválás
    </button>
  </ng-template>
</div>
<div>
  <button
    *ngIf="draft"
    mat-stroked-button
    type="button"
    data-test="draft"
    color="primary"
    (click)="onDraftClick()"
  >
    Mentés
  </button>
  <button mat-raised-button data-test="submit" color="primary">
    Módosítások mentése
  </button>
</div>
