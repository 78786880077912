import {
  Component,
  HostBinding,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { PostCategoryDataService } from '@otp-junior/admin-client';
import { Observable } from 'rxjs';

@Component({
  selector: 'web-admin-post-category-select',
  templateUrl: './post-category-select.component.html',
  styleUrls: ['./post-category-select.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: PostCategorySelectComponent },
  ],
})
export class PostCategorySelectComponent
  implements MatFormFieldControl<string>, ControlValueAccessor {
  public static nextId = 0;

  @HostBinding()
  public id = `post-category-select-${PostCategorySelectComponent.nextId++}`;
  public controlType = 'post-category-select';
  @ViewChild(MatSelect, { static: true }) public select: MatSelect;
  public categories$ = this.postCategoryDataService.getAllPostCategory();
  public constructor(
    private readonly postCategoryDataService: PostCategoryDataService,
    @Self() @Optional() public readonly ngControl: NgControl,
    @Optional() private readonly controlContainer: ControlContainer,
    private readonly errorStateMatcher: ErrorStateMatcher
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.refreshCategories();
  }
  public refreshCategories(): void {
    this.categories$ = this.postCategoryDataService.getAllPostCategory();
  }
  public writeValue(object: string): void {
    this.refreshCategories();
    this.select.writeValue(object);
  }
  public registerOnChange(function_: (value: string) => void): void {
    this.select.registerOnChange(function_);
  }
  public registerOnTouched(function_: () => unknown): void {
    this.select.registerOnTouched(function_);
  }
  public setDisabledState(isDisabled: boolean): void {
    this.select.setDisabledState(isDisabled);
  }
  public get value(): string {
    return this.select.value;
  }
  public get stateChanges(): Observable<void> {
    return this.select.stateChanges;
  }
  public set placeholder(value: string) {
    this.select.placeholder = value;
  }
  public get placeholder(): string {
    return this.select.placeholder;
  }
  public get focused(): boolean {
    return this.select.focused;
  }
  public get empty(): boolean {
    return this.select.empty;
  }
  public get shouldLabelFloat(): boolean {
    return this.select.shouldLabelFloat;
  }
  @Input() public set required(required: boolean) {
    this.select.required = required;
  }
  public get required(): boolean {
    return this.select.required;
  }
  public set disabled(disabled: boolean) {
    this.select.disabled = disabled;
  }
  public get disabled(): boolean {
    return this.select.disabled;
  }
  public get errorState(): boolean {
    return this.errorStateMatcher.isErrorState(
      this.ngControl.control as FormControl,
      this.controlContainer as NgForm | FormGroupDirective
    );
  }
  public setDescribedByIds(ids: string[]): void {
    this.select.setDescribedByIds(ids);
  }
  public onContainerClick(): void {
    this.select.onContainerClick();
  }
}
