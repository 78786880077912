import { NewsFeedEntryStatisticsFilterSort } from '@otp-junior/admin-client/model/models';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { SortDirection } from './sort-direction.enum';

interface BaseResponse<T> {
  content: T[];
  totalElements: number;
}

type State<T> = T & {
  loading: boolean;
};

export class ListService<
  TFilter,
  TResponseDto,
  TResponse extends BaseResponse<TResponseDto> = BaseResponse<TResponseDto>
> {
  private readonly input$: BehaviorSubject<{
    filterValue: TFilter;
    page: number;
    sortBy?: NewsFeedEntryStatisticsFilterSort;
    sortDirection?: SortDirection;
  }>;
  public items$: Observable<TResponseDto[]>;
  public totalElements$: Observable<number>;
  public loading$: Observable<boolean>;
  public filter$: Observable<TFilter>;
  public pageNumber$: Observable<number>;
  public sort$: Observable<{
    sortBy: NewsFeedEntryStatisticsFilterSort;
    sortDirection: SortDirection;
  }>;

  public constructor(
    defaultFilter: TFilter,
    getList: (
      filter: TFilter,
      page: number,
      sortBy: NewsFeedEntryStatisticsFilterSort,
      sortDirection: SortDirection
    ) => Observable<TResponse>
  ) {
    this.input$ = new BehaviorSubject({
      filterValue: defaultFilter,
      page: 0,
    });
    const state: Observable<State<TResponse>> = this.input$.pipe(
      switchMap(({ filterValue, page, sortBy, sortDirection }) =>
        merge(
          of({
            content: [],
            totalElements: 0,
            loading: true,
          } as State<TResponse>),
          getList(filterValue, page, sortBy, sortDirection).pipe(
            catchError(() =>
              of({
                content: [],
                totalElements: 0,
              } as TResponse)
            ),
            filter((x) => !!x),
            map((x) => ({
              ...x,
              loading: false,
            }))
          )
        )
      ),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
    this.items$ = state.pipe(map((x) => x.content));
    this.totalElements$ = state.pipe(map((x) => x.totalElements));
    this.loading$ = state.pipe(map((x) => x.loading));
    this.filter$ = this.input$.pipe(map((x) => x.filterValue));
    this.pageNumber$ = this.input$.pipe(map((x) => x.page));
    this.sort$ = this.input$.pipe(
      map((x) => ({
        sortBy: x.sortBy,
        sortDirection: x.sortDirection,
      }))
    );
  }

  public setFilter(filter: TFilter): void {
    this.input$.next({
      filterValue: filter,
      sortBy: this.input$.getValue().sortBy,
      sortDirection: this.input$.getValue().sortDirection,
      page: 0,
    });
  }

  public setPage(page: number): void {
    this.input$.next({
      // eslint-disable-next-line rxjs/no-subject-value
      filterValue: this.input$.getValue().filterValue,
      sortBy: this.input$.getValue().sortBy,
      sortDirection: this.input$.getValue().sortDirection,
      page,
    });
  }

  public setSort(sort: {
    sortBy?: NewsFeedEntryStatisticsFilterSort;
    sortDirection?: SortDirection;
  }): void {
    this.input$.next({
      // eslint-disable-next-line rxjs/no-subject-value
      filterValue: this.input$.getValue().filterValue,
      // eslint-disable-next-line rxjs/no-subject-value
      page: this.input$.getValue().page,
      sortBy: sort.sortBy,
      sortDirection: sort.sortDirection,
    });
  }

  public reload(): void {
    this.input$.next({
      // eslint-disable-next-line rxjs/no-subject-value
      filterValue: this.input$.getValue().filterValue,
      sortBy: this.input$.getValue().sortBy,
      sortDirection: this.input$.getValue().sortDirection,
      // eslint-disable-next-line rxjs/no-subject-value
      page: this.input$.getValue().page,
    });
  }
}
