/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  NewsFeedEntryStatus,
  NewsFeedEntrySummaryDto,
  NewsFeedEntryType,
  PostDataService,
} from '@otp-junior/admin-client';
import { Observable } from 'rxjs';
import { PostListService } from '../../post/list.service';

export interface QuizQuestion {
  question: string;
  choices: string[];
  imageId?: string;
}

export const snackBar = 'snack-bar-container';

@Component({
  selector: 'web-admin-quiz-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() public edit: number;
  public form: FormGroup;
  public post$: Observable<NewsFeedEntrySummaryDto[]>;
  public allPost: NewsFeedEntrySummaryDto[];
  public status: NewsFeedEntryStatus;

  constructor(
    protected readonly snackBar: MatSnackBar,
    private readonly postListService: PostListService,
    protected readonly postDataService: PostDataService
  ) {
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required]),
      marketingContent: new FormControl(false),
      startDate: new FormControl(undefined, [Validators.required]),
      steps: new FormArray([]),
      // eslint-disable-next-line unicorn/no-null
      linkedNewsFeedItem: new FormControl(null),
    });
    this.addQuestion();
    this.post$ = this.postListService.allItems$;
    this.post$.subscribe((allList) => {
      this.allPost = [];
      for (const element of allList) {
        if (
          element.type !== NewsFeedEntryType.Vote &&
          element.status == NewsFeedEntryStatus.Active
        ) {
          this.allPost.push(element);
        }
      }
    });
  }

  ngOnInit(): void {
    if (this.edit) {
      this.postDataService.getNewsFeedEntryById(this.edit).subscribe((data) => {
        const incomingData: any = data;
        this.status = data.status;
        this.form.controls.description.setValue(incomingData.description);
        this.form.controls.marketingContent.setValue(
          incomingData.marketingContent
        );
        this.form.controls.startDate.setValue(incomingData.startDate);
        this.form.controls.linkedNewsFeedItem.setValue(
          incomingData.linkedNewsFeedItem
        );
        this.removeQuestion(0);
        for (let index = 0; index < incomingData.steps.length; index++) {
          this.addQuestion(incomingData.steps[index]);
          this.steps[index].controls.imageId.setValue(
            incomingData.steps[index].imageId
          ); //TODO
        }
      });
    }
  }

  public addQuestion(value?: any): void {
    (this.form.get('steps') as FormArray).push(
      new FormGroup({
        // eslint-disable-next-line unicorn/no-null
        imageId: new FormControl(null), // TODO
        question: new FormControl(value ? value.question : '', [
          Validators.required,
        ]),
        choices: new FormArray([
          new FormControl(value ? value.choices[0].value : '', [
            Validators.required,
          ]),
          new FormControl(value ? value.choices[1].value : '', [
            Validators.required,
          ]),
          new FormControl(value ? value.choices[2].value : '', [
            Validators.required,
          ]),
          new FormControl(value ? value.choices[3].value : '', [
            Validators.required,
          ]),
        ]),
        // eslint-disable-next-line unicorn/no-null
        moreInfo: new FormControl(value ? value.moreInfo : null),
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getForm(): any {
    return this.form.value;
  }

  public removeQuestion(id: number): void {
    (this.form.get('steps') as FormArray).removeAt(id);
  }

  public get description(): FormControl {
    return this.form.get('description') as FormControl;
  }

  public get marketingContent(): FormControl {
    return this.form.get('marketingContent') as FormControl;
  }

  public get startDate(): FormControl {
    return this.form.get('startDate') as FormControl;
  }

  public get steps(): FormGroup[] {
    return (this.form.get('steps') as FormArray).controls as FormGroup[];
  }

  public getAnswers(questionForm: FormGroup): FormControl[] {
    return (questionForm.get('choices') as FormArray).controls as FormControl[];
  }
}
