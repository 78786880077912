<div class="quiz-create">
  <h1>Új szó/kifejezés hozzáadása a Lexikonhoz</h1>
  <web-admin-create-lexicon-item-form></web-admin-create-lexicon-item-form>
  <div class="actions">
    <div class="left">
      <a
        type="button"
        mat-stroked-button
        color="warn"
        data-test="discard"
        routerLink="/lexicon/lexicon"
      >
        Elvetés
      </a>
      <a
        type="button"
        mat-stroked-button
        data-test="draft"
        (click)="submit(LexiconWordStatus.DRAFT)"
        color="primary"
      >
        Piszkozat mentése
      </a>
    </div>
    <button
      mat-raised-button
      data-test="submit"
      (click)="submit()"
      color="primary"
    >
      Publikálom
    </button>
  </div>
</div>
<div *ngIf="isLoading" class="loading">
  <mat-spinner></mat-spinner>
</div>
