import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  Self,
} from '@angular/core';
import { FormControl, NgControl, Validators } from '@angular/forms';
import { DiscountDto } from '@otp-junior/admin-client';
import { shouldBeAfter } from '@web-admin/shared-lib';
import { urlRegex } from '../../../../utils/url-regex';
import { PostAbstractFormComponent } from '../abstract-form.component';
import { RemoveCommonDto } from '../remove-common-dto.type';

const maxTitleLength = 60;
const maxDescriptionLength = 100;

@Component({
  selector: 'web-admin-discount-form',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountFormComponent extends PostAbstractFormComponent<DiscountDto> {
  public maxTitleLength = maxTitleLength;
  public maxDescriptionLength = maxDescriptionLength;
  public constructor(@Self() @Optional() readonly ngControl: NgControl) {
    super(ngControl, {
      description: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxDescriptionLength),
      ]),
      imageId: new FormControl(undefined, [Validators.required]),
      title: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxTitleLength),
      ]),
      endDate: new FormControl(undefined),
      startDate: new FormControl(undefined, [Validators.required]),
      linkPrimary: new FormControl(undefined, [
        Validators.required,
        Validators.pattern(urlRegex),
      ]),
      linkSecondary: new FormControl(undefined, [Validators.pattern(urlRegex)]),
    });
    this.controls.endDate.setValidators([
      Validators.required,
      shouldBeAfter(this.controls.startDate),
    ]);
    this.subscription.add(
      this.controls.startDate.valueChanges.subscribe(() =>
        this.controls.endDate.updateValueAndValidity()
      )
    );
  }

  protected mapToForm(data: DiscountDto): RemoveCommonDto<DiscountDto> {
    return {
      description: data.description,
      imageId: data.imageId,
      title: data.title,
      endDate: data.endDate,
      startDate: data.startDate,
      linkPrimary: data.linkPrimary,
      linkSecondary: data.linkSecondary,
    };
  }
}
