<ng-container *ngIf="campaigns$ | async as campaigns">
  <ng-container *ngIf="campaigns.items.length > 0; else notFound">
    <table
      aria-label="Kampányok lista"
      mat-table
      [dataSource]="campaigns.items"
    >
      <ng-container matColumnDef="name">
        <th id="type-header" mat-header-cell *matHeaderCellDef>
          <strong>Név</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th
          id="availability-start-date-header"
          mat-header-cell
          *matHeaderCellDef
        >
          <strong>Kampány kezdete</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.startDate">
            {{ element.startDate | date: 'short' }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th id="campaign-end-id" mat-header-cell *matHeaderCellDef>
          <strong>Kampány vége</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.endDate">
            {{ element.endDate | date: 'short' }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th id="status-header" mat-header-cell *matHeaderCellDef>
          <strong>Státusz</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <div
            *ngIf="element.status === campaignListStatus.SCHEDULED"
            class="status-cell"
          >
            <img
              src="assets/declaration/status-icon/scheduled.svg"
              alt="Időzített icon"
            />
            Időzített
          </div>
          <div
            *ngIf="element.status === campaignListStatus.ACTIVE"
            class="status-cell"
          >
            <img
              src="assets/declaration/status-icon/active.svg"
              alt="Aktív icon"
            />
            Aktív
          </div>
          <ng-container *ngIf="element.status === campaignListStatus.ARCHIVE">
            <img
              src="assets/declaration/status-icon/archived.svg"
              alt="Archivált icon"
            />
            Archivált
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th id="activate-header" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="right">
          <a mat-button color="primary" [routerLink]="['edit', element.id]">
            <img src="assets/icons/edit.svg" alt="Szerkesztés icon" />
            Szerkesztés
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.archive]="row.status === 'ARCHIVE'"
      ></tr>
    </table>
    <mat-paginator
      [length]="campaigns.totalElements"
      [pageSize]="10"
      [pageSizeOptions]="[10]"
      (page)="onPageChange($event)"
    ></mat-paginator>
  </ng-container>
</ng-container>

<ng-template #notFound>
  <shared-list-empty> Nincsenek kampányok. </shared-list-empty>
</ng-template>
