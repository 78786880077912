<ng-container [ngSwitch]="status">
  <div *ngSwitchCase="JobStatus.Active" class="active">
    <strong>Ez a bejegyzés AKTÍV.</strong> Szerkesztésed mentés után megjelenik
    az éles oldalon.
  </div>
  <div *ngSwitchCase="JobStatus.Archive" class="archive">
    <strong>Ez a bejegyzés ARCHIVÁLT.</strong> Ha szeretnéd aktíválni állítsd át
    a hirdetés kezdeti és záró dátumá és mensd el.
  </div>
  <div *ngSwitchCase="JobStatus.Scheduled" class="scheduled">
    <strong>Ez a hirdetés IDŐZÍTETT.</strong> Szerkesztheted.
  </div>
</ng-container>
