import { Component, Input } from '@angular/core';
import { EmploymentType } from '@otp-junior/admin-client';

@Component({
  selector: 'web-admin-job-type-icon',
  templateUrl: './job-type-icon.component.html',
  styleUrls: ['./job-type-icon.component.scss'],
})
export class EmploymentTypeIconComponent {
  @Input() public type: EmploymentType;
  public srcMap: Record<EmploymentType, string> = {
    INTERNSHIP: 'assets/job/type-icon/internship.svg',
    PAID: 'assets/job/type-icon/paid.svg',
    VOLUNTEER: 'assets/job/type-icon/volunteer.svg',
  };
}
