import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsentDataService, ConsentFileDto } from '@otp-junior/admin-client';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'web-admin-pdf-uploader',
  templateUrl: './pdf-uploader.component.html',
  styleUrls: ['./pdf-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfUploadComponent implements ControlValueAccessor {
  // eslint-disable-next-line no-magic-numbers
  public readonly maxSizeInMB = 3;
  @ViewChild('input', { static: true })
  public input: ElementRef<HTMLInputElement>;
  public uploadedFileName$ = new BehaviorSubject<string>('');

  public onChange: (value: string) => void;
  public onTouched: () => void;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public writeValue(): void {
    // no-op
  }

  public registerOnChange(function_: (value: string) => void): void {
    this.onChange = function_;
  }
  public registerOnTouched(function_: () => void): void {
    this.onTouched = function_;
  }

  public constructor(
    @Self() @Optional() private readonly ngControl: NgControl,
    private readonly snackBar: MatSnackBar,
    private readonly consentDataService: ConsentDataService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public onUploadFile(): void {
    const selectedFile = this.input.nativeElement.files.item(0);
    if (selectedFile && selectedFile.type !== 'application/pdf') {
      this.snackBar.open('A file típusának pdf kell lennie.', 'X', {
        duration: 3000,
        panelClass: ['snack-bar-container', 'failure'],
      });
      return;
    }

    const file = this.uploadPdfFile(selectedFile);
    file.subscribe((response) => {
      if (response.ok) {
        if (this.onChange) {
          this.onChange(response.body.fileUrl);
        }
        this.uploadedFileName$.next(selectedFile.name);
      }
    });
  }

  public onUploadedFileDeleteClick(): void {
    if (this.onChange) {
      this.onChange('');
    }
    this.input.nativeElement.value = '';
    this.uploadedFileName$.next('');
  }

  private uploadPdfFile(file: File): Observable<HttpResponse<ConsentFileDto>> {
    return this.consentDataService.uploadFile(file, 'response').pipe(
      catchError((error) => {
        this.snackBar.open(
          'Nem sikerült feltölteni a pdf fájlt. Próbáld meg újra.',
          'OK',
          {
            panelClass: ['snack-bar-container', 'failure'],
            duration: 3000,
          }
        );
        return of(error);
      })
    );
  }
}
