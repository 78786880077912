import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsDataService } from '@otp-junior/admin-client';
import { UserStatisticsDto } from '@otp-junior/admin-client/model/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'web-admin-campaign-game-quiz-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameListComponent implements OnInit {
  public stats$: Observable<UserStatisticsDto>;

  constructor(
    private readonly analyticsDataService: AnalyticsDataService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.stats$ = this.analyticsDataService.getAggregatedUserStatistics();
  }

  public isActiveUrl(url: string): boolean {
    return this.router.isActive(url, true);
  }
}
