<shared-form-card>
  <h2>Bejegyzés beállításai</h2>
  <mat-form-field>
    <mat-label>Kategória</mat-label>
    <web-admin-post-category-select
      [formControl]="controls.categoryId"
    ></web-admin-post-category-select>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <web-admin-create-category-button
    (created)="onCategoryCreated($event)"
  ></web-admin-create-category-button>
  <br />
  <div class="checkbox-container">
    <mat-checkbox [formControl]="controls.marketingContent">
      Marketing tartalom
    </mat-checkbox>
    <mat-checkbox [formControl]="controls.highlighted">
      Kiemelt tartalom
    </mat-checkbox>
    <mat-checkbox [formControl]="controls.pinned">
      Rögzített tartalom
    </mat-checkbox>
  </div>
  <br />
  <div class="index-image-container">
    <mat-form-field>
      <mat-label>Publikálás dátuma</mat-label>
      <date-time-input
        [formControl]="controls.startDate"
        data-test="startDate"
      ></date-time-input>
      <mat-error *showError></mat-error>
    </mat-form-field>
    <web-admin-file-picker
      [formControl]="controls.imageId"
      [erasable]="true"
      titleText="Töltsd fel a bejegyzés indexképét"
      descriptionText="A bejegyzés indexképe"
    ></web-admin-file-picker>
  </div>
</shared-form-card>

<shared-form-card
  *ngIf="controls.hasSharingImage.value || controls.hasSharingText.value"
>
  <div class="card-header">
    <h2>Megosztás részletei</h2>
    <button
      (click)="removeSharingImage()"
      mat-icon-button
      color="warn"
      type="button"
      matSuffix
      [attr.data-test]="'sharing-image-remove'"
    >
      <img src="assets/icons/delete.svg" alt="" />
      Törlés
    </button>
  </div>
  <web-admin-file-picker
    [formControl]="controls.sharingImageId"
    [erasable]="true"
    titleText="Töltsd fel a megosztás indexképét"
    descriptionText="A megosztás indexképe"
  ></web-admin-file-picker>
  <mat-form-field class="sharing-image-text">
    <mat-label>Megosztás szövege</mat-label>
    <input matInput [formControl]="controls.sharingText" data-test="title" />
    <mat-hint align="end">
      {{ controls.sharingText.value?.length || 0 }}/{{ maxShareTextLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
</shared-form-card>
<button
  *ngIf="!controls.hasSharingImage.value"
  (click)="addSharingImage()"
  type="button"
  mat-button
  color="primary"
  class="sharing-image"
  data-test="add-content"
>
  <img
    src="assets/icons/plus_green.svg"
    alt="megosztási kep és szöveg hozzáadása"
    class="add-content-image"
  />
  <b>Megosztási kép és szöveg hozzáadása</b>
</button>
<shared-form-card>
  <h2>Bejegyzés</h2>
  <mat-form-field>
    <mat-label>Bejegyzés címe</mat-label>
    <input matInput [formControl]="controls.title" data-test="title" />
    <mat-hint align="end">
      {{ controls.title.value?.length || 0 }}/{{ maxTitleLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Bejegyzés rövid leírása</mat-label>
    <input
      matInput
      [formControl]="controls.description"
      data-test="description"
    />
    <mat-hint align="end">
      {{ controls.description.value?.length || 0 }}/{{ maxDescriptionLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <angular-editor
    [formControl]="controls.htmlContent"
    [config]="editorConfig"
    placeholder="Bejegyzés szövege"
    data-test="html"
  >
  </angular-editor>
  <h2>Lexikon bejegyzéshez kapcsolás</h2>
  <shared-multi-input
    #multiInput
    [formControl]="controls.lexiconWords"
    [controlGenerator]="connectedLexiconWordControlGenerator"
  >
    <div
      *sharedMultiInputTemplate="let control; let index = index"
      [formGroupPass]="control"
      class="deletable-control"
    >
      <app-filter-picker
        label="Kapcsolódó Lexikon bejegyzés"
        data-test="filter-picker"
        [formControl]="control.controls.connectingLexiconWord"
        [options$]="options$"
        (searchFor)="onSearchFor($event)"
      ></app-filter-picker>
      <button
        *ngIf="controls.lexiconWords.value?.length"
        (click)="removeConnectedLexiconItem(multiInput, index)"
        mat-icon-button
        color="warn"
        type="button"
        matSuffix
        [attr.data-test]="'content-remove-' + index"
      >
        <img src="assets/icons/delete.svg" alt="" />
      </button>
    </div>
  </shared-multi-input>
  <button
    (click)="multiInput.addControl()"
    type="button"
    mat-stroked-button
    color="primary"
    class="add-content"
    data-test="add-content"
  >
    <img
      src="assets/icons/plus_green.svg"
      alt="tartalom hozzáadása"
      class="add-content-image"
    />
    <b>Lexikon bejegyzés hozzáadása</b>
  </button>
  <ng-content selector="[in-bottom-card]"></ng-content>
</shared-form-card>
