/* eslint-disable prettier/prettier */
import { Directive, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
  ValidationErrors,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive()
export abstract class AbstractFormComponent<
  TInput,
  TForm = TInput,
  TOutput = TForm
> implements ControlValueAccessor, OnDestroy {
  public readonly form: FormGroup;
  protected readonly subscription = new Subscription();

  public constructor(
    protected readonly ngControl: NgControl,
    public readonly controls: Record<keyof TForm, FormControl>
  ) {
    this.form = new FormGroup(this.controls);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
      this.subscription.add(
        this.form.statusChanges.subscribe(() => {
          if (this.form.invalid) {
            const invalid: Record<
              string,
              ValidationErrors
            > = this.getErrorsFromControls();
            this.ngControl.control.setErrors({
              ...this.ngControl.errors,
              invalid,
            });
          }
          if (this.form.valid) {
            // eslint-disable-next-line unicorn/no-null
            this.ngControl.control.setErrors(null);
          }
        })
      );
    }
  }

  private getErrorsFromControls() {
    const invalid: Record<string, ValidationErrors> = {};
    for (const controlName in this.form.controls) {
      const control = this.form.controls[controlName];
      if (control.errors) {
        invalid[controlName] = control.errors;
      }
    }
    return invalid;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public writeValue(value: TInput): void {
    if (value) {
      this.form.patchValue(this.mapToForm(value));
    }
  }
  public registerOnChange(function_: (value: TOutput) => void): void {
    this.subscription.add(
      this.form.valueChanges.subscribe((data: TForm) =>
        function_(this.mapFromForm(data))
      )
    );
  }
  public registerOnTouched(function_: () => void): void {
    this.subscription.add(this.form.statusChanges.subscribe(function_));
  }
  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && !this.form.disabled) {
      this.form.disable();
    }
    if (!isDisabled && !this.form.enabled) {
      this.form.enable();
    }
  }

  protected mapFromForm(data: TForm): TOutput {
    return (data as unknown) as TOutput;
  }

  protected abstract mapToForm(data: TInput): TForm;
}
