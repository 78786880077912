<button mat-flat-button [matMenuTriggerFor]="menu" color="primary">
  Új állás hozzáadása <img alt="arrow"  src="assets/icons/arrow.svg" />
</button>
<mat-menu #menu="matMenu" overlapTrigger xPosition="before">
  <button
    *ngFor="let type of typeList"
    mat-menu-item
    [attr.data-test]="'item-' + type.value"
    [routerLink]="type.value | lowercase"
  >
    {{ type.name }}
  </button>
</mat-menu>
