import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import {
  mapMaterialSortByToSortBy,
  mapMaterialSortDirectionToSortDirection,
  SortDirection,
  UnionSortBy,
} from '@web-admin/shared-lib';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { LexiconListVM, LexiconWordStatus } from '../../models/lexicon.vm';
import { LexiconService } from '../../services/lexicon.service';

@Component({
  selector: 'web-admin-lexicon-list',
  templateUrl: './lexicon-list.component.html',
  styleUrls: ['./lexicon-list.component.scss'],
})
export class LexiconListComponent implements OnInit, OnDestroy {
  private readonly page$ = new BehaviorSubject<number>(0);
  private readonly currentPageSize$ = new BehaviorSubject<number>(0);
  private readonly destroy$ = new Subject<void>();

  public lexiconWords$: Observable<LexiconListVM>;
  public sortArguments$: Subject<{
    sortBy?: UnionSortBy;
    sortDirection?: SortDirection;
  }> = new Subject();

  public lexiconListStatus = LexiconWordStatus;
  public readonly displayedColumns: string[] = [
    'word',
    'date',
    'associatedContentCount',
    'openCount',
    'status',
    'edit',
    'delete',
  ];

  constructor(
    private readonly lexiconService: LexiconService,
    private readonly snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.lexiconWords$ = combineLatest([
      this.page$.asObservable(),
      this.sortArguments$
        .asObservable()
        .pipe(startWith({ sortBy: undefined, sortDirection: undefined })),
    ]).pipe(
      switchMap(([page, sortArguments]) =>
        this.lexiconService
          .getLexiconWords$(
            page,
            10,
            sortArguments?.sortBy,
            sortArguments?.sortDirection
          )
          .pipe(
            tap((response) => this.currentPageSize$.next(response.items.length))
          )
      ),
      catchError((error) => this.handleError(error)),
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onPageChange(pageEvent: PageEvent): void {
    this.page$.next(pageEvent.pageIndex);
  }

  public onDeleteClick(lexiconId: number): void {
    this.lexiconService
      .deleteLexiconWord$(lexiconId)
      .pipe(take(1))
      .subscribe(() => {
        const page = this.page$.getValue();
        const pageSize = this.currentPageSize$.getValue();
        this.page$.next(pageSize > 1 || page === 0 ? page : page - 1);
      });
  }

  public onSortChange(sort: Sort): void {
    this.sortArguments$.next({
      sortBy: mapMaterialSortByToSortBy(sort.active),
      sortDirection: mapMaterialSortDirectionToSortDirection(sort.direction),
    });
  }

  private handleError<T>(error: T): Observable<T> {
    this.snackBar.open('Hiba történt. Próbáld újra!', 'OK', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 30000,
    });
    return of(error);
  }
}
