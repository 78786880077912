import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; //  TODO
import {
  DateTimeInputModule,
  FileUploaderModule,
  FormCardModule,
  ListEmptyModule,
  MaterialErrorModule,
} from '@web-admin/shared-lib';
import { SurveyCreateComponent } from './create/create.component';
import { SurveyEditComponent } from './edit/edit.component';
import { FormComponent } from './form/form.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { SurveyCouponDetailsComponent } from './survey-coupon-details/survey-coupon-details.component';
import { SurveyDetailsComponent } from './survey-details/survey-details.component';
import { SurveyResultComponent } from './survey-result/survey-result.component';
import { SurveyRoutingModule } from './survey-routing.module';

@NgModule({
  declarations: [
    SurveyCreateComponent,
    FormComponent,
    ImageUploadComponent,
    SurveyResultComponent,
    SurveyEditComponent,
    SurveyDetailsComponent,
    SurveyCouponDetailsComponent,
  ],
  imports: [
    FileUploaderModule,
    CommonModule,
    SurveyRoutingModule,
    ReactiveFormsModule,
    MaterialErrorModule,
    MatSnackBarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DateTimeInputModule,
    FormCardModule,
    MatSelectModule,
    MatIconModule,
    MatSelectModule,
    MatPaginatorModule,
    ListEmptyModule,
    MatTableModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    FormsModule,
  ],
})
export class SurveyModule {}
