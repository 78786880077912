import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize, take } from 'rxjs/operators';
import { LexiconItemComponent } from '../form/lexicon-item.component';
import { LexiconWordStatus } from '../models/lexicon.vm';
import { LexiconService } from '../services/lexicon.service';

@Component({
  selector: 'web-admin-create-lexicon-item',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateLexiconItemComponent implements AfterViewInit {
  @ViewChild(LexiconItemComponent)
  public lexiconItemFormComponent: LexiconItemComponent;
  public LexiconWordStatus = LexiconWordStatus;

  public isLoading = false;

  public constructor(
    private readonly snackBar: MatSnackBar,
    private readonly lexiconService: LexiconService
  ) {}

  public ngAfterViewInit(): void {
    if (this.lexiconItemFormComponent) {
      this.lexiconItemFormComponent.setAvailableDate();
    }
  }

  public submit(status = LexiconWordStatus.ACTIVE): void {
    const data = this.lexiconItemFormComponent.form.value;
    this.lexiconItemFormComponent.form.markAllAsTouched();
    if (data && this.lexiconItemFormComponent.form.valid) {
      this.lexiconItemFormComponent.form.markAsPristine();
      this.isLoading = true;
      this.lexiconService
        .createLexiconWord$({
          ...data,
          status,
        })
        .pipe(
          take(1),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(() => (this.isLoading = false));
    } else {
      this.openSnackBar('Töltsd ki az összes mezőt!');
    }
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'X', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 10000,
    });
  }
}
