import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanDeactivate, UrlTree } from '@angular/router';
import { ModalService } from '@web-admin/shared-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeaveGuard implements CanDeactivate<{ form: FormGroup }> {
  constructor(private readonly modalService: ModalService) {}
  canDeactivate({
    form,
  }: {
    form: FormGroup;
  }): boolean | Observable<boolean | UrlTree> {
    if (form.pristine) {
      return true;
    }
    return this.modalService
      .openConfirmModal({
        confirmText: 'Elvetem',
        description: 'A módosításaid végleg törlődnek.',
        title: 'Biztos, hogy elveted?',
        color: 'warn',
      })
      .afterClosed();
  }
}
