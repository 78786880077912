import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { PostDataService, VoteDto } from '@otp-junior/admin-client';
import { AbstractEdit } from '../abstract-edit';

@Component({
  selector: 'web-admin-post-edit-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostEditVoteComponent extends AbstractEdit<VoteDto> {
  protected objectType: 'VoteDto' = 'VoteDto';

  public constructor(
    readonly postDataService: PostDataService,
    readonly activatedRoute: ActivatedRoute,
    readonly snackBar: MatSnackBar
  ) {
    super(postDataService, activatedRoute, snackBar);
  }
}
