import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AdminDataService } from '@otp-junior/admin-client';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../../config/app.config';

@Component({
  selector: 'web-admin-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutComponent {
  public name$ = this.adminDataService
    .getCurrentAdminProfile()
    .pipe(map((x) => x.name));

  public links = [
    {
      icon: 'insights',
      name: 'Analitika',
      link: '/analytics',
    },
    {
      icon: 'work',
      name: 'Meghirdetett állások',
      link: '/job',
    },
    {
      icon: 'format_align_justify',
      name: 'Bejegyzések',
      link: '/post',
    },
    {
      icon: 'notifications',
      name: 'Push küldés',
      link: '/notification',
    },
    {
      icon: 'format_align_justify',
      name: 'Nyilatkozatok',
      link: '/declaration',
    },
    {
      icon: 'format_align_justify',
      name: 'Admin',
      link: '/admin',
    },
    {
      icon: 'format_align_justify',
      name: 'Játék',
      link: '/game',
    },
    {
      icon: 'format_align_justify',
      name: 'Lexikon',
      link: '/lexicon',
    },
  ];
  public isSmallScreen$ = this.breakpointObserver
    .observe('(max-width: 800px)')
    .pipe(map((x) => x.matches));

  public readonly navbarBackgroundColor = this.config.getConfig(
    'headerBackgroundColor'
  );

  public constructor(
    private readonly adminDataService: AdminDataService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly config: AppConfig
  ) {}
}
