import { Directive, TemplateRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

interface MultiInputTemplateContext<T extends AbstractControl> {
  $implicit: T;
  index: number;
}

@Directive({
  selector: '[sharedMultiInputTemplate]',
})
export class MultiInputTemplateDirective<T extends AbstractControl> {
  public constructor(
    public readonly template: TemplateRef<MultiInputTemplateContext<T>>
  ) {}

  public static ngTemplateContextGuard<T extends AbstractControl>(
    directive: MultiInputTemplateDirective<T>,
    context: MultiInputTemplateContext<T>
  ): context is MultiInputTemplateContext<T> {
    return true;
  }
}
