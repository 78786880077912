<form (ngSubmit)="submit()">
  <h1>Kvíz szerkesztése</h1>
  <web-admin-quiz-form [edit]="this.id"> </web-admin-quiz-form>
  <div class="actions">
    <div class="left">
      <a
        mat-stroked-button
        type="button"
        data-test="discard"
        color="primary"
        routerLink="/post"
      >
        Elvetés
      </a>
      <a
        mat-stroked-button
        *ngIf="!removable"
        (click)="onArchiveClicked()"
        data-test="delete"
        color="warn"
        type="button"
      >
        Archiválás
      </a>
      <a
        *ngIf="removable"
        mat-stroked-button
        type="button"
        data-test="delete"
        color="warn"
        (click)="onDeleteClicked()"
      >
        Végleges törlés
      </a>
    </div>
    <button mat-raised-button data-test="submit" color="primary">
      Módosítások mentése
    </button>
  </div>
</form>
