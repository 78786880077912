import { Directive, Host, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { JobCategorySelectComponent } from './job-category-select/job-category-select.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'web-admin-job-category-select[isVolunteer]',
})
export class JobCategorySelectIsVolunteerDirective {
  @Input() set isVolunteer(value: boolean) {
    if (value) {
      this.select.categories$.pipe(first()).subscribe((categories) => {
        const category = categories.find((x) =>
          x.title.toLocaleLowerCase().includes('önkéntes')
        );
        this.select.ngControl.control.setValue(category?.id);
      });
    }
  }
  public constructor(
    @Host() private readonly select: JobCategorySelectComponent
  ) {}
}
