import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  Self,
} from '@angular/core';
import { FormControl, NgControl, Validators } from '@angular/forms';
import { VoteDto } from '@otp-junior/admin-client';
import { shouldBeAfter } from '@web-admin/shared-lib';
import { PostAbstractFormComponent } from '../abstract-form.component';
import { RemoveCommonDto } from '../remove-common-dto.type';

export type FlattenedVoteDto = Omit<
  RemoveCommonDto<VoteDto>,
  'optionA' | 'optionB'
> & {
  optionADescription: VoteDto['optionA']['description'];
  optionBDescription: VoteDto['optionB']['description'];
};

const maxAnswerLength = 25;
const maxQuestionLength = 60;
const maxDescriptionLength = 100;

@Component({
  selector: 'web-admin-vote-form',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoteFormComponent extends PostAbstractFormComponent<
  VoteDto,
  FlattenedVoteDto
> {
  public maxAnswerLength = maxAnswerLength;
  public maxQuestionLength = maxQuestionLength;
  public maxDescriptionLength = maxDescriptionLength;
  public constructor(@Self() @Optional() readonly ngControl: NgControl) {
    super(ngControl, {
      description: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxDescriptionLength),
      ]),
      marketingContent: new FormControl(false),
      optionADescription: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxAnswerLength),
      ]),
      imageId: new FormControl(undefined, [Validators.required]),
      optionBDescription: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxAnswerLength),
      ]),
      question: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxQuestionLength),
      ]),
      startDate: new FormControl(undefined, [Validators.required]),
      endDate: new FormControl(undefined),
    });
    this.controls.endDate.setValidators([
      Validators.required,
      shouldBeAfter(this.controls.startDate),
    ]);
    this.subscription.add(
      this.controls.startDate.statusChanges.subscribe(() =>
        this.controls.endDate.updateValueAndValidity()
      )
    );
  }

  protected mapFromForm(data: FlattenedVoteDto): RemoveCommonDto<VoteDto> {
    return {
      description: data.description,
      endDate: data.endDate,
      marketingContent: data.marketingContent,
      optionA: {
        description: data.optionADescription,
      },
      optionB: {
        description: data.optionBDescription,
      },
      imageId: data.imageId,
      question: data.question,
      startDate: data.startDate,
    };
  }

  protected mapToForm(data: RemoveCommonDto<VoteDto>): FlattenedVoteDto {
    return {
      marketingContent: data.marketingContent,
      startDate: data.startDate,
      description: data.description,
      endDate: data.endDate,
      optionADescription: data.optionA.description,
      optionBDescription: data.optionB.description,
      imageId: data.imageId,
      question: data.question,
    };
  }
}
