/* eslint-disable no-magic-numbers */

import {
  NewsFeedEntryType,
  NewsFeedEntryUndetailed,
} from '@otp-junior/admin-client';
import { DefaultRequestMultipartBody, RequestParams, rest } from 'msw';

const NEWSFEED_ENTRY_SEARCH_PATH = '/admin/news-feed-entry/search';

const getNewsFeedEntryUndetailed = (id: number): NewsFeedEntryUndetailed => {
  return {
    id,
    type: NewsFeedEntryType.Video,
    title: `Test${id}`,
  };
};

export const newsFeedHandlers = [
  rest.get<DefaultRequestMultipartBody, RequestParams, NewsFeedEntryUndetailed>(
    `${NEWSFEED_ENTRY_SEARCH_PATH}`,
    (request, response, context) => {
      const searchWord = request.url.searchParams.get('text');

      return response(
        context.delay(1000),
        context.json(
          Array.from({ length: 10 }, (_, index) =>
            getNewsFeedEntryUndetailed(index)
          ).filter((entry) => {
            return entry.title
              ?.toLowerCase()
              .includes(searchWord?.toLowerCase());
          })
        )
      );
    }
  ),
];
