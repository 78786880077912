import { GameQuizDto, QuizChoice, QuizStep } from '@otp-junior/admin-client';
import {
  GameQuizDetailsVM,
  GameQuizQuestion,
  GameQuizQuestionChoice,
} from '../models/game-quiz-details.vm';
import { mapGameQuizStatus } from './map-to-game-quiz-list-element-vm';

export const mapToGameQuizDetailsVM = (dto: GameQuizDto): GameQuizDetailsVM => {
  return {
    id: dto.id,
    campaignId: dto.gameCampaignId,
    levelId: dto.levelId,
    isDraft: dto.draft,
    status: mapGameQuizStatus(dto.status),
    questions: dto.steps.map((step) => mapToGameQuizQuestions(step)),
  };
};

export const mapToGameQuizQuestions = (dto: QuizStep): GameQuizQuestion => ({
  id: dto.id,
  question: dto.question,
  imageId: dto.imageId,
  choices: dto.choices.map((choice) => mapToGameQuizQuestionChoice(choice)),
});

export const mapToGameQuizQuestionChoice = (
  dto: QuizChoice
): GameQuizQuestionChoice => ({
  id: dto.id,
  value: dto.value,
  isCorrect: dto.isCorrect,
});
