import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  Self,
} from '@angular/core';
import { FormControl, NgControl, Validators } from '@angular/forms';
import { EventDto } from '@otp-junior/admin-client';
import { PostAbstractFormComponent } from '../abstract-form.component';
import { RemoveCommonDto } from '../remove-common-dto.type';

const maxTitleLength = 60;
const maxDescriptionLength = 500;

@Component({
  selector: 'web-admin-event-form',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormComponent extends PostAbstractFormComponent<EventDto> {
  public maxTitleLength = maxTitleLength;
  public maxDescriptionLength = maxDescriptionLength;
  public constructor(@Self() @Optional() readonly ngControl: NgControl) {
    super(ngControl, {
      marketingContent: new FormControl(false),
      imageId: new FormControl(undefined, [Validators.required]),
      title: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxTitleLength),
      ]),
      eventDate: new FormControl(undefined, [Validators.required]),
      description: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxDescriptionLength),
      ]),
      eventUrl: new FormControl(undefined, [Validators.required]),
      maxAttendee: new FormControl(undefined, [Validators.required]),
    });
  }

  protected mapToForm(data: EventDto): RemoveCommonDto<EventDto> {
    return {
      imageId: data.imageId,
      marketingContent: data.marketingContent,
      eventUrl: data.eventUrl,
      title: data.title,
      description: data.description,
      eventDate: data.eventDate,
      maxAttendee: data.maxAttendee,
    };
  }

  protected mapFromForm(
    data: RemoveCommonDto<EventDto>
  ): RemoveCommonDto<EventDto> {
    return {
      ...data,
      maxAttendee: +data.maxAttendee,
    };
  }
}
