<ng-container *ngIf="lexiconWords$ | async as lexiconWords">
  <ng-container *ngIf="lexiconWords.items?.length > 0; else notFound">
    <table
      aria-label="Lexikon lista"
      mat-table
      matSort
      (matSortChange)="onSortChange($event)"
      [dataSource]="lexiconWords.items"
    >
      <ng-container matColumnDef="word">
        <th id="type-header" mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Szavak</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.word }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th
          id="availability-start-date-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          <strong>Publikálás ideje</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.date">
            {{ element.date | date: 'short' }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="associatedContentCount">
        <th
          id="lexicon-associated-content-count-id"
          mat-header-cell
          *matHeaderCellDef
        >
          <strong>Kapcsolt tartalom</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.associatedContentCount">
            {{ element.associatedContentCount }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="openCount">
        <th
          id="lexicon-open-count-id"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          <strong>Megnyitva</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.viewCount">
            {{ element.viewCount }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th id="status-header" mat-header-cell *matHeaderCellDef>
          <strong>Státusz</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <div
            *ngIf="element.status === lexiconListStatus.DRAFT"
            class="status-cell"
          >
            <img
              src="assets/declaration/status-icon/draft.svg"
              alt="Piszkozat icon"
            />
            Piszkozat
          </div>
          <div
            *ngIf="element.status === lexiconListStatus.ACTIVE"
            class="status-cell"
          >
            <img
              src="assets/declaration/status-icon/active.svg"
              alt="Aktív icon"
            />
            Aktív
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th id="activate-header" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="right">
          <a mat-button color="primary" [routerLink]="['edit', element.id]">
            <img src="assets/icons/edit.svg" alt="Szerkesztés icon" />
            Szerkesztés
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th id="delete-header" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <a mat-button color="error" (click)="onDeleteClick(element.id)">
            <img src="assets/icons/delete.svg" alt="Törlés icon" />
            Törlés
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.archive]="row.status === 'ARCHIVE'"
      ></tr>
    </table>
    <mat-paginator
      [length]="lexiconWords.totalElements"
      [pageSize]="10"
      [pageSizeOptions]="[10]"
      (page)="onPageChange($event)"
    ></mat-paginator>
  </ng-container>
</ng-container>

<ng-template #notFound>
  <shared-list-empty> Nincsenek kampányok. </shared-list-empty>
</ng-template>
