import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {
  DateTimeInputModule,
  FileUploaderModule,
  FilterPickerModule,
  FormCardModule,
  ListEmptyModule,
  MaterialErrorModule,
  MatTabFormModule,
  MultiInputModule,
} from '@web-admin/shared-lib';
import { CreateLexiconItemComponent } from './create/create.component';
import { EditLexiconItemComponent } from './edit/edit.component';
import { ExportButtonComponent } from './export-button/export-button.component';
import { LexiconItemComponent } from './form/lexicon-item.component';
import { ImportButtonComponent } from './import-button/import-button.component';
import { CreateInterceptor } from './interceptors/create.interceptor';
import { EditInterceptor } from './interceptors/edit.interceptor';
import { LexiconRoutingModule } from './lexicon-routing.module';
import { LexiconListComponent } from './list/lexicon-list/lexicon-list.component';
import { LexiconListFilterComponent } from './list/list-filter/list-filter.component';
import { ListComponent } from './list/list.component';
import { LexiconService } from './services/lexicon.service';

@NgModule({
  declarations: [
    ListComponent,
    LexiconListComponent,
    LexiconListFilterComponent,
    LexiconItemComponent,
    CreateLexiconItemComponent,
    EditLexiconItemComponent,
    ExportButtonComponent,
    ImportButtonComponent,
  ],
  imports: [
    CommonModule,
    AngularEditorModule,
    LexiconRoutingModule,
    MatPaginatorModule,
    ListEmptyModule,
    MatTableModule,
    MatButtonModule,
    MatSelectModule,
    MatButtonModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatTabFormModule,
    MatFormFieldModule,
    MatIconModule,
    DateTimeInputModule,
    FileUploaderModule,
    MatInputModule,
    MatCheckboxModule,
    FormCardModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MultiInputModule,
    MaterialErrorModule,
    FilterPickerModule,
  ],
  providers: [
    LexiconService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CreateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EditInterceptor,
      multi: true,
    },
  ],
})
export class LexiconModule {}
