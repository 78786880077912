import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {
  DateTimeInputModule,
  FileUploaderModule,
  FilterPickerModule,
  FormCardModule,
  ListEmptyModule,
  MaterialErrorModule,
  MatTabFormModule,
  MultiInputModule,
} from '@web-admin/shared-lib';
import { LayoutModule } from '../layout/layout.module';
import { CreateCategoryButtonComponent } from './create-category/button/button.component';
import { CreateCategoryModalComponent } from './create-category/modal/modal.component';
import { CreatePostDropDownComponent } from './create-post-drop-down/create-post-drop-down.component';
import { PostCreateArticleComponent } from './create/article/article.component';
import { PostCreateButtonsComponent } from './create/buttons/buttons.component';
import { PostCreateDiscountComponent } from './create/discount/discount.component';
import { PostCreateEventComponent } from './create/event/event.component';
import { PostCreateVideoComponent } from './create/video/video.component';
import { PostCreateVoteComponent } from './create/vote/vote.component';
import { EditStatusIndicatorComponent } from './edit-status-indicator/edit-status-indicator.component';
import { PostEditArticleComponent } from './edit/article/article.component';
import { PostEditButtonsComponent } from './edit/buttons/buttons.component';
import { PostEditDiscountComponent } from './edit/discount/discount.component';
import { PostEditEventComponent } from './edit/event/event.component';
import { PostEditVideoComponent } from './edit/video/video.component';
import { PostEditVoteComponent } from './edit/vote/vote.component';
import { ArticleFormComponent } from './form/article/article.component';
import { DiscountFormComponent } from './form/discount/discount.component';
import { EventFormComponent } from './form/event/event.component';
import { VideoFormComponent } from './form/video/video.component';
import { VoteFormComponent } from './form/vote/vote.component';
import { CreateInterceptor } from './interceptors/create.interceptor';
import { EditInterceptor } from './interceptors/edit.interceptor';
import { PostListFilterComponent } from './list-filter/list-filter.component';
import { PostListComponent } from './list/list.component';
import { PostCategorySelectComponent } from './post-category-select/post-category-select.component';
import { PostCategoryTitleComponent } from './post-category-title/post-category-title.component';
import { PostReactionsComponent } from './post-reactions/post-reactions.component';
import { PostRoutingModule } from './post-routing.module';
import { PostStatusIconComponent } from './post-status-icon/post-status-icon.component';
import { PostTypeIconComponent } from './post-type-icon/post-type-icon.component';
import { XlsUploaderComponent } from './xls-uploader/xls-uploader.component';

@NgModule({
  declarations: [
    PostListComponent,
    PostListFilterComponent,
    PostTypeIconComponent,
    PostStatusIconComponent,
    PostReactionsComponent,
    CreatePostDropDownComponent,
    PostCreateArticleComponent,
    ArticleFormComponent,
    PostCategorySelectComponent,
    PostEditArticleComponent,
    VideoFormComponent,
    PostCreateVideoComponent,
    PostEditVideoComponent,
    VoteFormComponent,
    PostCreateVoteComponent,
    PostEditVoteComponent,
    EventFormComponent,
    PostCreateEventComponent,
    PostEditEventComponent,
    DiscountFormComponent,
    PostCreateDiscountComponent,
    PostEditDiscountComponent,
    PostCategoryTitleComponent,
    XlsUploaderComponent,
    EditStatusIndicatorComponent,
    CreateCategoryButtonComponent,
    CreateCategoryModalComponent,
    PostEditButtonsComponent,
    PostCreateButtonsComponent,
  ],
  imports: [
    CommonModule,
    PostRoutingModule,
    FileUploaderModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatCardModule,
    MatCheckboxModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AngularEditorModule,
    LayoutModule,
    MatTabFormModule,
    MatTableModule,
    ListEmptyModule,
    FormCardModule,
    MaterialErrorModule,
    DateTimeInputModule,
    FilterPickerModule,
    MultiInputModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CreateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EditInterceptor,
      multi: true,
    },
  ],
  exports: [PostTypeIconComponent],
})
export class PostModule {}
