<div class="quiz-create">
  <h1>Új szó/kifejezés hozzáadása a Lexikonhoz</h1>
  <web-admin-create-lexicon-item-form></web-admin-create-lexicon-item-form>
  <div class="actions">
    <div class="left">
      <a
        mat-stroked-button
        type="button"
        data-test="discard"
        color="warn"
        routerLink="/lexicon/lexicon"
      >
        Elvetés
      </a>
      <a
        mat-stroked-button
        type="button"
        data-test="draft"
        color="primary"
        (click)="submit(LexiconWordStatus.DRAFT)"
      >
        Piszkozat mentése
      </a>
    </div>
    <button
      mat-raised-button
      data-test="submit"
      color="primary"
      (click)="submit()"
    >
      Szerkesztem
    </button>
  </div>
</div>
<div class="loading" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
