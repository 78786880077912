import { Directive } from '@angular/core';
import { NewsFeedEntry } from '@otp-junior/admin-client';
import { AbstractFormComponent } from '@web-admin/shared-lib/base-class';
import { RemoveCommonDto } from './remove-common-dto.type';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class PostAbstractFormComponent<
  TEntry extends NewsFeedEntry,
  TForm = RemoveCommonDto<TEntry>
> extends AbstractFormComponent<TEntry, TForm, RemoveCommonDto<TEntry>> {}
