export interface LifeStageListVM {
  totalElements: number;
  items: LifeStageVM[];
}

export interface LifeStageVM {
  id?: number;
  title?: string;
  scheduledAt?: string;
  status?: GameCampaignLifeStageStatusEnum;
  campaignId?: number;
  campaignTitle?: string;
}

export enum GameCampaignLifeStageStatusEnum {
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
}
