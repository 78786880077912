import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MockServiceWorkerService } from '../mock/mock-worker.service';
import { AppConfig } from './app.config';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AppConfig,
    MockServiceWorkerService,
    {
      provide: APP_INITIALIZER,
      deps: [MockServiceWorkerService, AppConfig],
      useFactory: (
        mockServiceWorker: MockServiceWorkerService,
        appConfig: AppConfig
      ) => {
        return async () => {
          await appConfig.load();
          if (appConfig.getConfig('mockServiceWorker')) {
            await mockServiceWorker.init(appConfig.getConfig('backendBaseUrl'));
          }
        };
      },
      multi: true,
    },
  ],
})
export class ConfigModule {}
