import { NewsFeedEntryType } from '@otp-junior/admin-client';

export const typeList: { name: string; value: NewsFeedEntryType }[] = [
  { name: 'Cikk', value: NewsFeedEntryType.Article },
  { name: 'Videó', value: NewsFeedEntryType.Video },
  { name: 'Szavazás', value: NewsFeedEntryType.Vote },
  { name: 'Kedvezmény', value: NewsFeedEntryType.Discount },
  { name: 'Kutatás', value: NewsFeedEntryType.Survey },
  { name: 'Kvíz', value: NewsFeedEntryType.Quiz },
];
