import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JobStatus } from '@otp-junior/admin-client';

@Component({
  selector: 'web-admin-job-status-icon',
  templateUrl: './job-status-icon.component.html',
  styleUrls: ['./job-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobStatusIconComponent {
  @Input() status: JobStatus;
  public srcMap: Record<JobStatus, string> = {
    ACTIVE: 'assets/job/status-icon/active.svg',
    ARCHIVE: 'assets/job/status-icon/archive.svg',
    SCHEDULED: 'assets/job/status-icon/scheduled.svg',
  };
  public textMap: Record<JobStatus, string> = {
    ACTIVE: 'Aktív',
    ARCHIVE: 'Archivált',
    SCHEDULED: 'Időzített',
  };
}
