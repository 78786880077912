<h1>Inaktív adminisztrátorok</h1>

<ng-container *ngIf="items$ | async as items">
  <ng-container *ngIf="items.length > 0; else notFound">
    <table aria-label="Adminisztrátor lista" mat-table [dataSource]="items">
      <ng-container matColumnDef="email">
        <th id="email-id" mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let admin">
          <strong>{{ admin.email }}</strong>
        </td>
      </ng-container>
      <ng-container matColumnDef="lastSuccessfulLogin">
        <th id="last-success-login-id" mat-header-cell *matHeaderCellDef>Utolsó sikeres belépés</th>
        <td mat-cell *matCellDef="let admin">
          {{ admin.lastSuccessfulLogin | date: 'short' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="lastUnsuccessfulLogin">
        <th id="last-unsuccess-login-id" mat-header-cell *matHeaderCellDef>Utolsó sikertelen belépés</th>
        <td mat-cell *matCellDef="let admin">
          {{ admin.lastUnsuccessfulLogin | date: 'short' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="activate">
        <th id="activate-header" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="right">
          <a mat-button color="primary" (click)="onActivateClicked(element)">
            Aktiválás
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-container>
</ng-container>

<ng-template #notFound>
  <shared-list-empty> Nincsenek inaktív adminisztrátorok. </shared-list-empty>
</ng-template>
