import { ChangeDetectorRef, Directive, Input, OnInit } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';
import { SortDirection as OTPSortDirection } from '../sort-direction.enum';
@Directive({
  selector: '[matSortInitialState]',
})
export class MatSortInitialStateDirective implements OnInit {
  @Input() public matSortInitialState: {
    sortBy?: string;
    sortDirection?: OTPSortDirection;
  } | null;

  public constructor(
    private readonly element: MatSort,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public get sortDirection(): SortDirection {
    if (this.matSortInitialState.sortDirection === OTPSortDirection.Asc) {
      return 'asc';
    }
    if (this.matSortInitialState.sortDirection === OTPSortDirection.Desc) {
      return 'desc';
    }
  }

  public ngOnInit(): void {
    if (this.matSortInitialState) {
      this.element.active = this.matSortInitialState.sortBy;
      this.element.direction = this.sortDirection;
    }
  }
}
