import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationDataService } from '@otp-junior/admin-client';
import { NotificationFormData } from '../form/form.component';

@Component({
  selector: 'web-admin-notification-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCreateComponent {
  public control = new FormControl();
  public form = new FormGroup({ data: this.control });
  public createSwitchLink: string;

  public constructor(
    protected readonly notificationDataService: NotificationDataService,
    protected readonly snackBar: MatSnackBar
  ) {}
  public submit(): void {
    const data = this.form.value.data;
    if (this.createSwitchLink == 'deeplink') {
      data.jobId = undefined;
      data.postId = undefined;
    } else if (this.createSwitchLink == 'job') {
      data.deepLink = undefined;
      data.postId = undefined;
    } else if (this.createSwitchLink == 'post') {
      data.jobId = undefined;
      data.deepLink = undefined;
    }
    this.form.markAllAsTouched();
    if (!data || this.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }
    this.sendCreation(data).subscribe(() => this.form.markAsPristine());
  }

  private sendCreation(data: NotificationFormData) {
    return this.notificationDataService.createNotification({
      // readonly should be sent as null
      /* eslint-disable unicorn/no-null */
      id: null,
      status: null,
      /* eslint-enable unicorn/no-null */
      ...data,
    });
  }
}
