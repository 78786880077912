<shared-form-card>
  <h2>Napi szó beállítása</h2>
  <mat-form-field>
    <mat-label>Publikálás dátuma</mat-label>
    <date-time-input
      [formControl]="controls.date"
      data-test="start-date"
    ></date-time-input>
    <mat-error *showError></mat-error>
  </mat-form-field>
</shared-form-card>
<shared-form-card>
  <h2>Lexikon bejegyzés</h2>
  <mat-form-field>
    <mat-label>Megjelenő szó vagy kifejezés</mat-label>
    <input matInput [formControl]="controls.word" data-test="word" />
    <mat-hint align="end">
      {{ controls.word.value?.length || 0 }}/{{ maxTitleLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <angular-editor
    #definitionEditor
    (paste)="pasteHtml($event)"
    [formControl]="controls.definition"
    [config]="editorConfig"
    placeholder="Bejegyzés szövege"
    data-test="html"
  ></angular-editor>
  <h2>Kapcsolódó tartalmak</h2>
  <shared-multi-input
    #multiInput
    [formControl]="controls.connectingPosts"
    [controlGenerator]="additionalContentControlGenerator"
  >
    <div
      *sharedMultiInputTemplate="let control; let index = index"
      [formGroupPass]="control"
      class="deletable-control"
    >
      <app-filter-picker
        label="Kapcsolódó tartalom címe"
        data-test="filter-picker"
        [formControl]="control.controls.connectingPost"
        [options$]="options$"
        (searchFor)="onSearchFor($event)"
      ></app-filter-picker>
      <button
        *ngIf="controls.connectingPosts.value?.length"
        (click)="deleteAdditionalContent(multiInput, index)"
        mat-icon-button
        color="warn"
        matSuffix
        [attr.data-test]="'content-remove-' + index"
      >
        <img src="assets/icons/delete.svg" alt="" />
      </button>
    </div>
  </shared-multi-input>
  <button
    (click)="multiInput.addControl()"
    type="button"
    mat-stroked-button
    color="primary"
    class="add-content"
    data-test="add-content"
  >
    <img
      src="assets/icons/plus_green.svg"
      alt="tartalom hozzáadása"
      class="add-content-image"
    />
    <b>Kapcsolódó tartalom hozzáadása</b>
  </button>
</shared-form-card>
<ng-content selector="[in-bottom-card]"></ng-content>
