import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AngularEditorConfig,
  AngularEditorService,
} from '@kolkov/angular-editor';
import { UploadResponse } from '@kolkov/angular-editor/lib/angular-editor.service';
import { ImageDto } from '@otp-junior/admin-client';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor(
    private readonly http: HttpClient,
    private readonly snackBar: MatSnackBar
  ) {}

  public upload(
    file: File,
    uploadUrl: string,
    editorService: AngularEditorService,
    fileUploadLimit?: number
  ): Observable<Partial<HttpEvent<ImageDto>>> {
    const snackbarContainerClass = 'snack-bar-container';
    const snackbarErrorMessage =
      'Kép feltöltése sikertelen!\nEllenőrizd a fájl kiterjesztését illetve méretét!';
    const snackbarErrorConfig = {
      panelClass: [snackbarContainerClass, 'failure'],
      duration: 3000,
    };

    if (!file) {
      /* eslint-disable unicorn/no-null */
      return of(null);
    }

    if (fileUploadLimit && file.size > fileUploadLimit) {
      this.snackBar.open(
        `A kép mérete túl nagy! Maximális megengedett fájlméret: ${Math.round(
          // eslint-disable-next-line no-magic-numbers
          fileUploadLimit / 1000000
        )} MB`,
        'OK',
        snackbarErrorConfig
      );
      return of(null);
    }

    const formData = new FormData();
    formData.append('file', file, file.name);
    this.snackBar.open('Kép feltöltése folyamatban...', 'OK', {
      panelClass: [snackbarContainerClass, 'info'],
      duration: 3000,
    });
    return this.http
      .post<ImageDto>(uploadUrl, formData, { withCredentials: true })
      .pipe(
        take(1),
        catchError((error) => {
          this.snackBar.open(snackbarErrorMessage, 'OK', snackbarErrorConfig);
          return throwError(error);
        }),
        tap((response) => {
          this.snackBar.open('Kép sikeresen feltöltve!', 'OK', {
            panelClass: [snackbarContainerClass, 'success'],
            duration: 3000,
          });
          // In Angular Editor versions above 1.5.0 and above the upload function is broken, this is a workaround for that.
          editorService.insertImage(response.imageUrl);
        }),
        map((response) => {
          return {
            body: {
              imageUrl: response.imageUrl,
            },
          } as Partial<HttpEvent<ImageDto>>;
        })
      );
  }

  public getAngularEditorConfig(
    uploadUrl: string,
    editorServiceInstance: AngularEditorService,
    fileUploadLimit?: number
  ): Partial<AngularEditorConfig> {
    return {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '300',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [],
      customClasses: [],
      upload: (file: File) =>
        this.upload(
          file,
          uploadUrl,
          editorServiceInstance,
          fileUploadLimit
        ) as Observable<HttpEvent<UploadResponse>>,
      uploadWithCredentials: true,
      sanitize: false,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        ['fontSize', 'fontName'],
        [
          'textColor',
          'backgroundColor',
          'customClasses',
          'insertVideo',
          'insertHorizontalRule',
        ],
      ],
    };
  }
}
