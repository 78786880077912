<ng-container [formGroupPass]="group">
  <input
    matInput
    #dateInput
    #date="matInput"
    [matDatepicker]="datePicker"
    [formControl]="controls.date"
    data-test="date"
    [required]="required"
    [min]="currentDate"
    (blur)="onTouched()"
    (keydown)="onDateKeydown($event)"
    class="date"
  />
  <div (click)="onTimeDivClicked($event)" class="time">
    <input
      matInput
      #hourInput
      [formControl]="controls.hours"
      data-test="hours"
      placeholder="00"
      class="hours"
      [required]="required"
      (click)="hourInput.select()"
      (blur)="onTouched()"
      (keydown)="onHourKeydown($event)"
    />
    <span class="colon" [class.show]="shouldLabelFloat">:</span>
    <input
      matInput
      #minuteInput
      [formControl]="controls.minutes"
      data-test="minutes"
      placeholder="00"
      class="minutes"
      [required]="required"
      (click)="minuteInput.select()"
      (blur)="onTouched()"
      (keydown)="onMinuteKeydown($event)"
    />
  </div>
  <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker></mat-datepicker>
</ng-container>
