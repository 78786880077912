/* eslint-disable unicorn/no-null */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { FormControl, NgControl, Validators } from '@angular/forms';
import {
  JobSummaryDto,
  NewsFeedEntryStatus,
  NewsFeedEntrySummaryDto,
  NewsFeedEntryType,
  NotificationDto,
} from '@otp-junior/admin-client';
import { AbstractFormComponent } from '@web-admin/shared-lib/base-class';
import { Observable } from 'rxjs';
import { urlRegex } from '../../../utils/url-regex';
import { JobListService } from '../../job/list.service';
import { PostListService } from '../../post/list.service';

export type NotificationFormData = Omit<NotificationDto, 'id' | 'status'>;

const maxMessageLength = 150;
const maxTitleLength = 60;
@Component({
  selector: 'web-admin-notification-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationFormComponent extends AbstractFormComponent<
  NotificationDto,
  NotificationFormData
> {
  public maxMessageLength = maxMessageLength;
  public maxTitleLength = maxTitleLength;
  public switchLink: string;
  public job$: Observable<JobSummaryDto[]>;
  public post$: Observable<NewsFeedEntrySummaryDto[]>;
  public allPost: NewsFeedEntrySummaryDto[];
  @Output() switchLinkToCreate? = new EventEmitter<string>();

  public constructor(
    @Self() @Optional() readonly ngControl: NgControl,
    private readonly listService: JobListService,
    private readonly postListService: PostListService
  ) {
    super(ngControl, {
      marketingContent: new FormControl(false),
      android: new FormControl(false),
      ios: new FormControl(false),
      deepLink: new FormControl(undefined, [Validators.required]),
      message: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxMessageLength),
      ]),
      title: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxTitleLength),
      ]),
      scheduledAt: new FormControl(undefined, [Validators.required]),
      jobId: new FormControl(undefined),
      postId: new FormControl(undefined),
    });
    this.allPost = [];
    this.switchLink = 'deeplink';
    this.job$ = this.listService.items$;
    this.post$ = this.postListService.allItems$;
    this.post$.subscribe((allList) => {
      for (const element of allList) {
        if (
          element.type !== NewsFeedEntryType.Vote &&
          element.status == NewsFeedEntryStatus.Active
        ) {
          this.allPost.push(element);
        }
      }
    });
  }

  protected mapToForm(data: NotificationDto): NotificationFormData {
    if (data.jobId !== undefined) {
      this.switchLink = 'job';
    } else if (data.postId !== undefined) {
      this.switchLink = 'post';
    } else if (data.deepLink !== undefined) {
      this.switchLink = 'deeplink';
    }
    return {
      android: data.android,
      ios: data.ios,
      marketingContent: data.marketingContent,
      message: data.message,
      scheduledAt: data.scheduledAt,
      jobId: data.jobId ?? null,
      postId: data.postId ?? null,
      deepLink: data.deepLink ?? null,
      title: data.title,
    };
  }

  public updateValidators(): void {
    this.form.controls.postId.updateValueAndValidity();
    this.form.controls.jobId.updateValueAndValidity();
    this.form.controls.deepLink.updateValueAndValidity();
  }

  public onItemChange(value: string): void {
    this.switchLink = value;
    if (this.switchLink === 'job') {
      this.form.controls.jobId.setValidators([Validators.required]);
      this.form.controls.postId.clearValidators();
      this.form.controls.deepLink.clearValidators();
      this.updateValidators();
    } else if (this.switchLink === 'post') {
      this.form.controls.jobId.clearValidators();
      this.form.controls.postId.setValidators([Validators.required]);
      this.form.controls.deepLink.clearValidators();
      this.updateValidators();
    } else if (this.switchLink === 'deeplink') {
      this.form.controls.jobId.clearValidators();
      this.form.controls.postId.clearValidators();
      this.form.controls.deepLink.setValidators([
        Validators.required,
        Validators.pattern(urlRegex),
      ]);
      this.updateValidators();
    }
    this.switchLinkToCreate.emit(this.switchLink);
  }
}
