import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import {
  NewsFeedEntryStatus,
  NewsFeedEntrySummaryDto,
  NewsFeedEntryType,
} from '@otp-junior/admin-client';
import { Observable } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';
import { PostListService } from '../list.service';

@Component({
  selector: 'web-admin-post-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class PostListComponent implements OnInit {
  public filterControl = new FormControl();
  public count$: Observable<number>;
  public loading$: Observable<boolean>;
  public list$: Observable<NewsFeedEntrySummaryDto[]>;
  public displayedColumns$: Observable<string[]>;
  public pageNumber$: Observable<number>;
  public readonly NewsFeedEntryType = NewsFeedEntryType;
  public constructor(
    private readonly listService: PostListService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.listService.filterState$
      .pipe(first())
      .subscribe((filter) =>
        this.filterControl.patchValue(filter, { emitEvent: false })
      );
    this.filterControl.valueChanges.subscribe((filter) =>
      this.listService.setFilter(filter)
    );
    this.list$ = this.listService.items$;
    this.count$ = this.listService.totalElements$;
    this.pageNumber$ = this.listService.pageNumber$;
    this.loading$ = this.listService.loading$;
    this.displayedColumns$ = this.listService.filterState$.pipe(
      map((x) => x.status),
      distinctUntilChanged(),
      map((status) => {
        const result = [
          'icon',
          'title',
          'category',
          'publish',
          'reactions',
          'status',
          'edit',
        ];
        if (status === NewsFeedEntryStatus.Scheduled) {
          result.splice(result.indexOf('reactions'), 1);
        }
        return result;
      })
    );
  }

  public onPageChange(event: PageEvent): void {
    this.listService.setPage(event.pageIndex);
  }

  public getRouterLink(
    dto: Pick<NewsFeedEntrySummaryDto, 'type' | 'id'>
  ): void {
    if (dto.type == 'SURVEY' || dto.type == 'QUIZ') {
      this.router.navigate(
        [`/${dto.type.toLocaleLowerCase()}/edit/${dto.id}`],
        {
          replaceUrl: true,
        }
      );
    } else if (dto.type == 'GAME_QUIZ') {
      this.router.navigate([`/game/quiz/edit/${dto.id}`], {
        replaceUrl: true,
      });
    } else {
      this.router.navigate([`post/${dto.type.toLocaleLowerCase()}/${dto.id}`], {
        replaceUrl: true,
      });
    }
  }
}
