import { Directive } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewsFeedEntry, PostDataService } from '@otp-junior/admin-client';
import { Observable } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractCreate<TEntry extends NewsFeedEntry> {
  protected abstract objectType: TEntry['objectType'];
  public control = new FormControl();
  public form = new FormGroup({ data: this.control });
  public constructor(
    protected readonly postDataService: PostDataService,
    protected readonly snackBar: MatSnackBar
  ) {}
  public submit(): void {
    const data = this.form.value.data;
    if (!data || this.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }
    this.sendCreation(data).subscribe(() => this.form.markAsPristine());
  }

  public onDraftClicked(): void {
    const data = this.form.value.data;
    this.sendCreation(data, true).subscribe(() => this.form.markAsPristine());
  }

  protected sendCreation(
    data: TEntry,
    draft = false
  ): Observable<NewsFeedEntry> {
    return this.postDataService.createNewsFeedEntry({
      objectType: this.objectType,
      draft,
      // readonly should be sent as null
      /* eslint-disable unicorn/no-null */
      type: null,
      id: null,
      status: null,
      /* eslint-enable unicorn/no-null */
      ...data,
    });
  }
}
