import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  NotificationDataService,
  NotificationStatus,
} from '@otp-junior/admin-client';
import { Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { NotificationFormData } from '../form/form.component';

@Component({
  selector: 'web-admin-notification-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationEditComponent implements OnInit, OnDestroy {
  public control = new FormControl();
  public form = new FormGroup({ data: this.control });
  public removable: boolean;
  private readonly subscription = new Subscription();
  private id: number;
  public createSwitchLink: string;

  public constructor(
    protected readonly notificationDataService: NotificationDataService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.activatedRoute.paramMap
        .pipe(
          map((parameterMap) => parameterMap.get('id')),
          filter((id) => !!id),
          switchMap((id) =>
            this.notificationDataService.getNotificationById(+id)
          )
        )
        .subscribe((data) => {
          this.id = data.id;
          this.removable = data.status !== NotificationStatus.Sent;
          this.form.setValue({
            data,
          });
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  public submit(): void {
    const data = this.form.value.data;
    if (this.createSwitchLink == 'deeplink') {
      data.jobId = undefined;
      data.postId = undefined;
    } else if (this.createSwitchLink == 'job') {
      data.deepLink = undefined;
      data.postId = undefined;
    } else if (this.createSwitchLink == 'post') {
      data.jobId = undefined;
      data.deepLink = undefined;
    }
    if (!data || this.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }
    this.sendEdit(data).subscribe(() => this.form.markAsPristine());
  }

  public onDeleteClicked(): void {
    this.notificationDataService
      .deleteNotification(this.id)
      .subscribe(() => this.form.markAsPristine());
  }

  private sendEdit(data: NotificationFormData) {
    return this.notificationDataService.updateNotification(this.id, {
      // readonly should be sent as null
      /* eslint-disable unicorn/no-null */
      id: null,
      status: null,
      /* eslint-enable unicorn/no-null */
      ...data,
    });
  }
}
