import { SortDirection } from '../../sort-direction.enum';
import { SortBy, UnionSortBy } from '../../sort.enum';

export const mapMaterialSortByToSortBy = (sortBy: string): UnionSortBy => {
  switch (sortBy) {
    case 'word':
      return SortBy.Word;
    case 'publishDate':
      return SortBy.Date;
    case 'date':
      return SortBy.Date;
    case 'openCount':
      return SortBy.OpenCount;
    case 'title':
      return SortBy.Title;
    case 'points':
      return SortBy.Points;
    case 'moneyWonByQuiz':
      return SortBy.MoneyWonByQuiz;
  }
};

export const mapMaterialSortDirectionToSortDirection = (
  sortDirection: string
): SortDirection => {
  switch (sortDirection) {
    case 'asc':
      return SortDirection.Asc;
    case 'desc':
      return SortDirection.Desc;
  }
};
