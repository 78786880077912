import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventDto, PostDataService } from '@otp-junior/admin-client';
import { AbstractCreate } from '../abstract-create';

@Component({
  selector: 'web-admin-post-create-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class PostCreateEventComponent extends AbstractCreate<EventDto> {
  protected objectType: 'EventDto' = 'EventDto';

  public constructor(
    readonly postDataService: PostDataService,
    readonly snackBar: MatSnackBar
  ) {
    super(postDataService, snackBar);
  }
}
