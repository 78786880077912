/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  NewsFeedEntry,
  NewsFeedEntryStatus,
  PostDataService,
} from '@otp-junior/admin-client';
import { FormComponent } from '../form/form.component';

@Component({
  selector: 'web-admin-quiz-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizCreateComponent<TEntry extends NewsFeedEntry> {
  protected objectType: TEntry['objectType'];
  public control = new FormControl();
  public form = new FormGroup({ data: this.control });
  public status: NewsFeedEntryStatus;
  @ViewChild(FormComponent) child: FormComponent;
  constructor(
    protected readonly snackBar: MatSnackBar,
    protected readonly postDataService: PostDataService
  ) {
    this.objectType = 'QuizDto';
    this.status = NewsFeedEntryStatus.Draft;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public submit(): TEntry {
    const data = this.child.getForm();
    this.child.form.markAllAsTouched();
    // this.child.questionForm.markAllAsTouched();
    if (!data || this.child.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }
    new Date(data.startDate) > new Date()
      ? (this.status = NewsFeedEntryStatus.Scheduled)
      : (this.status = NewsFeedEntryStatus.Active);
    if (typeof data.steps[0].choices[0] == 'string') {
      for (let index = 0; index < data.steps.length; index++) {
        data.steps[index].choices = data.steps[index].choices.map(
          (choice: string) => ({
            value: choice,
            isCorrect: false,
          })
        );
        data.steps[index].choices[0].isCorrect = true;
      }
    }
    this.sendCreation(data).subscribe(() => {
      this.child.form.markAsPristine();
    });
  }

  public onDraftClicked(): void {
    const data = this.child.getForm();
    this.child.form.markAllAsTouched();
    if (!data || this.child.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }
    this.status == NewsFeedEntryStatus.Draft;
    if (typeof data.steps[0].choices[0] == 'string') {
      for (let index = 0; index < data.steps.length; index++) {
        data.steps[index].choices = data.steps[index].choices.map(
          (choice: string) => ({
            value: choice,
            isCorrect: false,
          })
        );
        data.steps[index].choices[0].isCorrect = true;
      }
    }
    this.sendCreation(data, true).subscribe(() =>
      this.child.form.markAsPristine()
    );
  }

  private sendCreation(data: TEntry, draft = true) {
    return this.postDataService.createNewsFeedEntry({
      objectType: this.objectType,
      type: 'QUIZ',
      status: this.status,
      draft: this.status == NewsFeedEntryStatus.Draft ? draft : !draft,
      ...data,
    });
  }
}
