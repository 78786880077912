<div class="quiz-create">
  <h1>Új Játék Kvíz hozzáadása</h1>
  <web-admin-game-quiz-form
    [campaignLevels]="campaignLevels$ | async"
  ></web-admin-game-quiz-form>
  <div class="actions">
    <div class="left">
      <a
        mat-stroked-button
        type="button"
        data-test="discard"
        color="primary"
        routerLink="/game/quiz"
      >
        Elvetés
      </a>
    </div>
    <button
      mat-raised-button
      data-test="submit"
      color="primary"
      (click)="submit()"
    >
      Beállítom
    </button>
  </div>
</div>
