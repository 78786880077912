import { LifeStageResponse } from '@otp-junior/admin-client';

export interface CampaignListVM {
  totalElements: number;
  items: CampaignVM[];
}

export interface CampaignVM {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  status: CampaignListStatus;
  lifeStages: LifeStageResponse[];
}

export enum CampaignListStatus {
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
  ARCHIVE = 'ARCHIVE',
}
