import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  Self,
} from '@angular/core';
import { FormControl, NgControl, Validators } from '@angular/forms';
import { urlRegex } from '../../../../utils/url-regex';
import { PostAbstractFormComponent } from '../abstract-form.component';
import { RemoveCommonDto } from '../remove-common-dto.type';
import { VideoVM } from './video.interface';

const maxTitleLength = 60;
const maxDescriptionLength = 100;
const maxShareTextLength = 100;

@Component({
  selector: 'web-admin-video-form',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoFormComponent extends PostAbstractFormComponent<VideoVM> {
  public maxTitleLength = maxTitleLength;
  public maxDescriptionLength = maxDescriptionLength;
  public maxShareTextLength = maxShareTextLength;

  public constructor(@Self() @Optional() readonly ngControl: NgControl) {
    super(ngControl, {
      marketingContent: new FormControl(false),
      highlighted: new FormControl(false),
      pinned: new FormControl(false),
      startDate: new FormControl(undefined, [Validators.required]),
      title: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxTitleLength),
      ]),
      videoUrl: new FormControl(undefined, [
        Validators.required,
        Validators.pattern(urlRegex),
      ]),
      description: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxDescriptionLength),
      ]),
      imageId: new FormControl(),
      sharingText: new FormControl(),
      sharingImageId: new FormControl(undefined),
      sharingImage: new FormControl(false),
    });
  }

  public addSharingImage(): void {
    this.controls.sharingImage.setValue(true);
  }

  public removeSharingImage(): void {
    this.controls.sharingImage.reset(false);
    this.controls.sharingText.reset();
    this.controls.sharingImageId.reset();
  }

  protected mapToForm(data: VideoVM): RemoveCommonDto<VideoVM> {
    return {
      description: data.description,
      marketingContent: data.marketingContent,
      pinned: data.pinned,
      highlighted: data.highlighted,
      startDate: data.startDate,
      title: data.title,
      videoUrl: data.videoUrl,
      sharingImageId: data.sharingImageId,
      sharingText: data.sharingText,
      imageId: data.imageId,
      sharingImage: !!data.sharingImageId,
    };
  }
}
