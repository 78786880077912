import { Component } from '@angular/core';

@Component({
  selector: 'web-admin-game-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
})
export class GameListFilterComponent {
  public tabs: { name: string; route: string }[] = [
    {
      name: 'Kampányok',
      route: 'campaign',
    },
    {
      name: 'Életszakaszok',
      route: 'life-stage',
    },
    {
      name: 'Játék kvíz',
      route: 'quiz',
    },
  ];
}
