import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CampaignListStatus, CampaignVM } from '../../models/campaign.vm';
import { CampaignService } from '../../services/campaign.service';
import { CampaignFormComponent } from '../form/form.component';

@Component({
  selector: 'web-admin-edit-lifestage',
  templateUrl: './edit-lifestage.component.html',
  styleUrls: ['./edit-lifestage.component.scss'],
})
export class EditLifestageComponent implements OnInit {
  public campaignId: number;
  public lifeStageId: number;
  public campaign$: Observable<CampaignVM>;

  public CampaignListStatus = CampaignListStatus;

  @ViewChild(CampaignFormComponent)
  campaignFormComponent: CampaignFormComponent;

  constructor(
    private readonly campaignService: CampaignService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.campaignId = +this.route.snapshot.paramMap.get('id');
    this.lifeStageId = +this.route.snapshot.paramMap.get('lifeStageId');
    this.campaign$ = this.campaignService.getCampaign$(
      this.campaignId,
      this.lifeStageId
    );
  }

  public submit(): void {
    const form = this.campaignFormComponent.formGroup;
    form.markAllAsTouched();

    if (form.valid && form.value) {
      this.campaignService
        .updateLifeStage$(form.controls.lifeStages.value[0].id, {
          scheduledAt: form.controls.lifeStages.value[0].scheduledAt,
        })
        .pipe(
          take(1),
          catchError((error) => this.handleError(error, 'edit'))
        )
        .subscribe(() => this.router.navigate(['/game']));
    }
  }

  private handleError<T>(error: T, type: 'stop' | 'edit'): Observable<T> {
    if (type === 'edit') {
      this.openSnackBar(
        'Nem sikerült módosítani az életszakaszt. Próbáld meg újra.'
      );
    }

    return throwError(error);
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'X', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 30000,
    });
  }
}
