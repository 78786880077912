<h1 mat-dialog-title>{{ data.title }}</h1>
<button mat-icon-button class="x" (click)="close(false)">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-content>
  <p>{{ data.description }}</p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close(false)" data-test="cancel" cdkFocusInitial>
    Mégsem
  </button>
  <button
    mat-flat-button
    (click)="close(true)"
    data-test="confirm"
    [color]="data.color"
  >
    {{ data.confirmText }}
  </button>
</div>
