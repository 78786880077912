import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CampaignListStatus, CampaignListVM } from '../../models/campaign.vm';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'web-admin-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss'],
})
export class CampaignListComponent implements OnInit {
  private readonly page$ = new BehaviorSubject<number>(0);

  public campaigns$: Observable<CampaignListVM>;

  public campaignListStatus = CampaignListStatus;
  public readonly displayedColumns: string[] = [
    'name',
    'startDate',
    'endDate',
    'status',
    'edit',
  ];

  constructor(
    private readonly campaignService: CampaignService,
    private readonly snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.campaigns$ = this.page$.asObservable().pipe(
      switchMap((page) => this.campaignService.getCampaigns$(page, 10)),
      catchError((error) => this.handleError(error))
    );
  }

  public onPageChange(pageEvent: PageEvent): void {
    this.page$.next(pageEvent.pageIndex);
  }

  private handleError<T>(error: T): Observable<T> {
    this.snackBar.open('Hiba történt. Próbáld újra!', 'OK', {
      panelClass: ['snack-bar-container', 'failure'],
      duration: 30000,
    });
    return of(error);
  }
}
