/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterContentInit,
  ContentChildren,
  Directive,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatTabNav } from '@angular/material/tabs';
import { Changeable, CHANGEABLE } from './changeable';
import { MatTabFormDirective } from './mat-tab-form.directive';

@Directive({
  selector:
    '[mat-tab-nav-bar][formControl], [mat-tab-nav-bar][formControlName], [mat-tab-nav-bar][ngModel]',
  providers: [
    {
      provide: CHANGEABLE,
      useExisting: MatTabGroupFormDirective,
    },
  ],
})
export class MatTabGroupFormDirective
  implements ControlValueAccessor, AfterContentInit, Changeable {
  @ContentChildren(MatTabFormDirective)
  public matTabs!: QueryList<MatTabFormDirective>;
  public currentValue: unknown;
  private onChange: any;
  private onTouch: any;

  public constructor(public ngControl: NgControl, public matTabNav: MatTabNav) {
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  public ngAfterContentInit(): void {
    if (this.currentValue) {
      this.setCurrentTab(this.currentValue);
    }
  }
  public writeValue(object: unknown): void {
    this.currentValue = object;
    if (object && this.matTabs) {
      this.setCurrentTab(object);
    }
  }
  private setCurrentTab(object: unknown) {
    const currentIndex = this.matTabs
      .map((x) => x)
      .findIndex((x) => x.value === object);
    for (const x of this.matTabs.filter((_, index) => index !== currentIndex)) {
      if (x.matTabLink.active) {
        x.matTabLink.active = false;
        x.cdr.detectChanges();
      }
    }
    if (currentIndex > -1) {
      this.matTabNav.selectedIndex = currentIndex;
      const tab = this.matTabs.get(currentIndex);
      if (!tab.matTabLink.active) {
        tab.matTabLink.active = true;
        tab.cdr.detectChanges();
      }
    }
  }

  public changed(value: unknown): void {
    if (this.onTouch) {
      this.onTouch();
    }
    if (this.onChange) {
      this.onChange(value);
    }
    this.setCurrentTab(value);
  }

  public registerOnChange(function_: any): void {
    this.onChange = function_;
  }
  public registerOnTouched(function_: any): void {
    this.onTouch = function_;
  }
}
