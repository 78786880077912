import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HandleOptions } from './handle-options';

@Injectable()
export class CreateInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router
  ) {}

  private readonly campaignOptions: HandleOptions = {
    success: {
      message: 'Szó létrehozása sikeres.',
    },
    error: {
      message: 'Nem sikerült a szót létrehozni. Próbáld meg újra.',
    },
    returnRoute: '/lexicon/lexicon',
  };

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.method === 'POST' &&
      /admin\/lexicon\/words$/.test(request.url)
    ) {
      return this.handleRequest(request, next, this.campaignOptions);
    }

    return next.handle(request);
  }

  private handleRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    options: HandleOptions
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((result) => {
        if (result.type === HttpEventType.Response) {
          this.snackBar.open(
            options.success.message,
            options.success.actionLabel || 'OK',
            {
              panelClass: ['snack-bar-container', 'success'],
              duration: 3000,
            }
          );
          this.router.navigateByUrl(options.returnRoute);
        }
      })
    );
  }
}
