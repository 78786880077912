import { Level } from '@otp-junior/admin-client';
import { GameCampaignLevels } from '@otp-junior/admin-client/model/gameCampaignLevels';
import { CampaignLevelVM, GameLevelVM } from '../models/game-level.vm';

export const mapToCampaignLevelVm = ({
  campaignId,
  campaignTitle,
  levels,
}: GameCampaignLevels): CampaignLevelVM => ({
  campaignId,
  campaignTitle,
  gameLevels: levels.map((lvl) => mapGameLevelVM(lvl)),
});

export const mapGameLevelVM = (level: Level): GameLevelVM => ({
  id: level.levelId,
  name: level.levelTitle,
});
