import { Location } from '@angular/common';
import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NewsFeedEntry } from '@otp-junior/admin-client';
import { ConfirmModalData, ModalService } from '@web-admin/shared-lib';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { HandleOptions } from './handle-options';

@Injectable()
export class EditInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly location: Location
  ) {}

  CAMPAIGN_ROUTE = '/game/campaign';

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes('/admin/gamecampaigns/life-stages/') &&
      request.method === 'PUT'
    ) {
      return this.openConfirmationModal$(request, {
        title: 'Biztos, hogy módosítod az életszakaszt?',
        description: 'Az életszakasz a módosított dátumtól lesz elérhető.',
        confirmText: 'Módosítás',
      }).pipe(
        filter((x) => x),
        switchMap(() =>
          this.handleRequest(request, next, {
            success: {
              message: 'Sikeresen módosítottad az életszakaszt',
              actionLabel: 'OK',
            },
            returnRoute: this.CAMPAIGN_ROUTE,
          })
        )
      );
    } else if (request.url.includes('admin/gamecampaigns/')) {
      if (request.method === 'PUT') {
        return this.openConfirmationModal$(request, {
          title: 'Biztos, hogy módosítod a kampányt?',
          description: 'A kampany a módosított dátumig lesz elérhető.',
          confirmText: 'Módosítás',
        }).pipe(
          filter((x) => x),
          switchMap(() =>
            this.handleRequest(request, next, {
              success: {
                message: 'Sikeresen módosítottad a kampányt',
                actionLabel: 'OK',
              },
              returnRoute: this.CAMPAIGN_ROUTE,
            })
          )
        );
      } else if (request.method === 'DELETE') {
        return this.openConfirmationModal$(request, {
          title: 'Biztos, hogy leállítod a kampányt?',
          description:
            'Ha leállítod a kampányt, nem lesz többé elérhető a játék.',
          confirmText: 'Leállítom',
        }).pipe(
          filter((x) => x),
          switchMap(() =>
            this.handleRequest(request, next, {
              success: {
                message: 'Sikeresen archiváltad a kampányt.',
                actionLabel: 'OK',
              },
              returnRoute: this.CAMPAIGN_ROUTE,
            })
          )
        );
      }
    } else if (
      request.url.includes('admin/news-feed-entry/') &&
      request.method === 'PUT' &&
      this.location.path().includes('/game/quiz/edit/')
    ) {
      return this.openConfirmationModal$(request, {
        title: 'Biztos, hogy módosítod a kvízt?',
        description: 'A módosítások azonnal érvénybe lépnek.',
        confirmText: 'Módosítás',
      }).pipe(
        filter((x) => x),
        switchMap(() =>
          this.handleRequest(request, next, {
            success: {
              message: 'Sikeresen módosítottad a kvízt.',
              actionLabel: 'OK',
            },
            error: {
              message: 'Nem sikerült módosítani a játékot. Próbáld meg újra.',
            },
            returnRoute: '/game/quiz',
          })
        )
      );
    }

    return next.handle(request);
  }

  private openConfirmationModal$(
    request: HttpRequest<NewsFeedEntry | number>,
    modalOptions: ConfirmModalData
  ): Observable<boolean> {
    if (request?.body && (request?.body as NewsFeedEntry).draft) {
      return of(true);
    }

    return this.modalService.openConfirmModal(modalOptions).afterClosed();
  }

  private handleRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    options: HandleOptions
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        this.openSnackBar(
          'failure',
          options.error.message,
          options.error.actionLabel
        );

        return throwError(error);
      }),
      tap((result) => {
        if (result.type === HttpEventType.Response) {
          this.openSnackBar(
            'success',
            options.success.message,
            options.success.actionLabel
          );
          this.router.navigateByUrl(options.returnRoute);
        }
      })
    );
  }

  private openSnackBar(
    type: 'success' | 'failure',
    message: string,
    actionLabel = 'X'
  ): void {
    this.snackBar.open(message, actionLabel, {
      panelClass: ['snack-bar-container', type],
      duration: 3000,
    });
  }
}
