/* eslint-disable unicorn/no-null */

import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { EmploymentType, NewsFeedEntryType } from '@otp-junior/admin-client';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownListItem, DropdownVM } from './filter-picker.vm';

@Component({
  selector: 'app-filter-picker',
  templateUrl: './filter-picker.component.html',
  styleUrls: ['./filter-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPickerComponent implements ControlValueAccessor, OnInit {
  @Input() public options$: Observable<DropdownVM>;
  @Input() public label: string;
  @Input() public icon?: string;
  @Output()
  public searchFor: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public optionSelected: EventEmitter<DropdownListItem> = new EventEmitter<DropdownListItem>();

  public selectedOption: string;

  private readonly destroyed$ = new Subject();
  public innerControl = new FormControl();

  public isOpen = false;
  public readonly overlayPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: -20,
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
  ];

  public onChange: ((_: DropdownListItem | null) => void) | undefined;
  public onTouched: (() => void) | undefined;

  public constructor(
    @Optional() @Self() private readonly ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get value(): DropdownListItem {
    return this.ngControl.control?.value;
  }
  public get disabled(): boolean {
    return this.ngControl.control?.disabled || false;
  }

  // Basic accessibility support - close modal on ESC keyboard event
  @HostListener('keydown', ['$event'])
  public onKeydownHandler(event: KeyboardEvent): void {
    if (this.isOpen && event.key === 'Escape') {
      this.isOpen = false;
      this.cdr.markForCheck();
    }
  }

  public ngOnInit(): void {
    this.innerControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((searchTerm) => {
        if (this.onChange) {
          this.onChange(null);
        }
        if (this.onTouched) {
          this.onTouched();
        }
        this.searchFor.emit(searchTerm);
      });
  }

  public onOverlayOutsideClick(event: MouseEvent): void {
    const clickTarget = event.target as HTMLElement;
    const isFilterCloseButtonClicked = clickTarget.closest('.dropdown-button');
    if (isFilterCloseButtonClicked) {
      return;
    }
    event.preventDefault();
    if (
      this.selectedOption !== this.innerControl.value &&
      this.innerControl.value?.length > 0
    ) {
      this.innerControl.setValue(this.selectedOption, { emitEvent: false });
    } else if (this.innerControl.value?.length === 0) {
      this.selectedOption = undefined;
    }
    this.isOpen = false;
  }

  public onOptionClick(option: DropdownListItem): void {
    const selectedOption = `${option.name}  ${
      option.type ? `(${this.mapTypeToTranslation(option.type)})` : ''
    }  `;
    this.isOpen = false;

    this.innerControl.setValue(selectedOption, { emitEvent: false });

    if (this.onChange) {
      this.onChange(option);
    }
    if (this.onTouched) {
      this.onTouched();
    }
    this.selectedOption = selectedOption;
    this.optionSelected.emit(option);
  }

  public writeValue(value: DropdownListItem): void {
    if (value && value.name) {
      const selectedOption = `${value.name}  ${
        value.type ? `(${this.mapTypeToTranslation(value.type)})` : ''
      }  `;
      this.selectedOption = selectedOption;
      this.innerControl.setValue(selectedOption, { emitEvent: false });
    }
    this.cdr.markForCheck();
  }

  public registerOnChange(
    function_: (_: DropdownListItem | null) => void
  ): void {
    this.onChange = function_;
  }
  public registerOnTouched(function_: () => unknown): void {
    this.onTouched = function_;
  }

  public openDropDown(): void {
    this.isOpen = true;
  }

  private mapTypeToTranslation(
    type: NewsFeedEntryType | EmploymentType
  ): string {
    switch (type) {
      case NewsFeedEntryType.Article:
        return 'cikk';
      case NewsFeedEntryType.Discount:
        return 'kedvezmény';
      case NewsFeedEntryType.Event:
        return 'esemény';
      case NewsFeedEntryType.Quiz:
        return 'kvíz';
      case NewsFeedEntryType.Survey:
        return 'eredmény';
      case NewsFeedEntryType.Video:
        return 'videó';
      case NewsFeedEntryType.Vote:
        return 'szavazás';
      case NewsFeedEntryType.GameQuiz:
        return 'jaték kvíz';
    }
  }
}
