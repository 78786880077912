/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import {
  AngularEditorComponent,
  AngularEditorConfig,
  AngularEditorService,
} from '@kolkov/angular-editor';
import {
  AttachmentService,
  ConfirmModalData,
  debounceTimeAfterFirst,
  DropdownListItem,
  DropdownVM,
  getUtcDate,
  ModalService,
  MultiInputComponent,
} from '@web-admin/shared-lib';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, take } from 'rxjs/operators';
import { AppConfig } from '../../../config/app.config';
import { LexiconWordStatus } from '../models/lexicon.vm';
import { LexiconService } from '../services/lexicon.service';
import { LexiconAbstractFormComponent } from './lexicon-item-abstract-form.component';
import { LexiconItemForm } from './lexicon-item.vm';

const maxTitleLength = 60;
const maxDescriptionLength = 100;
const debounceTime = 300;
const minWordLength = 3;

@Component({
  selector: 'web-admin-create-lexicon-item-form',
  templateUrl: './lexicon-item.component.html',
  styleUrls: ['./lexicon-item.component.scss'],
  providers: [AngularEditorService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LexiconItemComponent extends LexiconAbstractFormComponent<LexiconItemForm> {
  @ViewChild('definitionEditor') public myEditor!: AngularEditorComponent;
  @Input() public word?: LexiconItemForm;
  public maxTitleLength = maxTitleLength;
  public maxDescriptionLength = maxDescriptionLength;
  public readonly editorConfig: AngularEditorConfig = this.attachmentService.getAngularEditorConfig(
    `${this.appConfig.getConfig('backendBaseUrl')}/admin/image`,
    this.angularEditorService,
    this.appConfig.getConfig('imageUploadLimitInBytes')
  );

  public constructor(
    @Self() @Optional() readonly ngControl: NgControl,
    private readonly appConfig: AppConfig,
    private readonly lexiconService: LexiconService,
    private readonly modalService: ModalService,
    private readonly angularEditorService: AngularEditorService,
    private readonly attachmentService: AttachmentService
  ) {
    super(ngControl, {
      word: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(maxTitleLength),
      ]),
      connectingPosts: new FormControl([]),
      definition: new FormControl(undefined, [Validators.required]),
      date: new FormControl({ value: undefined, disabled: true }, [
        Validators.required,
      ]),
    });
  }

  public searchSubject$: Subject<string> = new Subject();
  public options$ = this.searchSubject$.asObservable().pipe(
    debounceTimeAfterFirst(debounceTime),
    switchMap((searchWord: string) => {
      if (searchWord.length < minWordLength) {
        return of({ isLoading: false } as DropdownVM);
      }
      return this.lexiconService
        .getNewsFeedItems$(searchWord)
        .pipe(
          map((response) => {
            const content = response.content.filter(
              (dropdownItem) =>
                !this.form.value.connectingPosts.some(
                  (connectingPost: { connectingPost: DropdownListItem }) => {
                    return (
                      connectingPost?.connectingPost?.id === dropdownItem.id
                    );
                  }
                )
            );
            return {
              ...response,
              content: content,
              isLoading: false,
            };
          }),
          catchError(() => {
            return of({ isLoading: false, content: [] });
          })
        )
        .pipe(startWith({ isLoading: true, content: [] }));
    })
  );

  public setAvailableDate(): void {
    this.lexiconService
      .getLexiconInfo$()
      .pipe(take(1))
      .subscribe((response) => {
        const date = new Date(response.nextAvailableScheduleDate);
        this.form.patchValue({
          date: getUtcDate(date),
        });
      });
  }

  public additionalContentControlGenerator(): FormGroup {
    return new FormGroup({
      connectingPost: new FormControl(undefined, [Validators.required]),
    });
  }

  public onSearchFor(searchWord: string): void {
    this.searchSubject$.next(searchWord);
  }

  public initForm(word: LexiconItemForm): void {
    this.form.patchValue({
      word: word.word,
      definition: word.definition,
      connectingPosts: word.connectingPosts,
    });

    if (word.status !== LexiconWordStatus.DRAFT) {
      this.form.patchValue({
        date: word.date,
      });
    }
  }

  public pasteHtml(event: ClipboardEvent): void {
    const htmlMode = this.myEditor.editorToolbar.htmlMode;
    if (htmlMode) {
      return;
    }
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');

    const youtubeLinkRegex = new RegExp(
      [
        '^',
        '((?:https?:)?\\/\\/)?',
        '((?:www|m)\\.)?',
        '((?:youtube(-nocookie)?\\.com|youtu\\.be))',
        '(\\/(?:[\\w\\-]+\\?v=|embed\\/|live\\/|v\\/)?)',
        '([\\w\\-]+)',
        '(\\S+)?',
        '$',
      ].join('')
    );

    const youtubeIdRegex = /^.*(youtu\.be\/|v\/|u\/\w\/|live\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const youtubeMatch = pastedText.match(youtubeLinkRegex);

    if (youtubeMatch) {
      const videoId = pastedText.match(youtubeIdRegex)[2];
      const youTubeEmbed = `<iframe
                                      width="100%"
                                      height="315"
                                      src="https://www.youtube.com/embed/${videoId}"
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      allowfullscreen
                                    ></iframe>`;
      this.angularEditorService.insertHtml(youTubeEmbed);
    } else {
      this.angularEditorService.insertHtml(pastedText);
    }
  }

  public deleteAdditionalContent(
    multiInput: MultiInputComponent<FormGroup, unknown>,
    index: number
  ): void {
    this.openConfirmationModal$({
      title: 'Biztos, hogy törlöd a kapcsolódó bejegyzést?',
      description:
        'A bejegyzés nem fog többé megjelenni a kapcsolt szó oldalán.',
      confirmText: 'Törlöm',
    })
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          multiInput.removeControl(index);
        }
      });
  }

  private openConfirmationModal$(
    modalOptions: ConfirmModalData
  ): Observable<boolean> {
    return this.modalService.openConfirmModal(modalOptions).afterClosed();
  }

  protected mapToForm(data: LexiconItemForm): LexiconItemForm {
    return data;
  }
}
