import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { LexiconDataService } from '@otp-junior/admin-client';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'web-admin-lexicon-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportButtonComponent {
  @Output() public exportLoading = new EventEmitter<boolean>();

  public constructor(private readonly lexiconDataService: LexiconDataService) {}

  public onExportClicked(): void {
    this.exportLoading.emit(true);
    this.lexiconDataService
      .exportLexicon()
      .pipe(
        catchError((error) => {
          this.exportLoading.emit(false);
          throw error;
        })
      )
      .subscribe((response) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        document.body.append(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'export.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.exportLoading.emit(false);
      });
  }
}
