import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsDataService } from '@otp-junior/admin-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { mapGameCardType } from '../mappers/map-game-analytics';
import { GameLevelAnalyticsVM } from '../models/game-level-analytics.vm';

@Component({
  selector: 'web-admin-game-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent implements OnInit {
  public id: number;
  public data$: Observable<GameLevelAnalyticsVM>;
  public exportLoading = new BehaviorSubject(false);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly analyticsDataService: AnalyticsDataService
  ) {
    this.id = (this.route.snapshot.paramMap.get('id') as unknown) as number;
  }

  public ngOnInit(): void {
    this.data$ = this.analyticsDataService.getGameLevelAnalytics(this.id).pipe(
      map((data) => {
        return {
          ...data,
          cardResults: data.cardResults.map((cardResult) => {
            return {
              ...cardResult,
              type: mapGameCardType(cardResult.type),
            };
          }),
        };
      })
    );
  }

  public onExportClicked(): void {
    this.exportLoading.next(true);
    this.analyticsDataService
      .getGameLevelAnalyticsExport(this.id)
      .pipe(
        catchError((error) => {
          this.exportLoading.next(false);
          throw error;
        }),
        finalize(() => {
          this.exportLoading.next(false);
        })
      )
      .subscribe((response) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        document.body.append(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'export.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.exportLoading.next(false);
      });
  }
}
