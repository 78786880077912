import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PostDataService, VoteDto } from '@otp-junior/admin-client';
import { AbstractCreate } from '../abstract-create';

@Component({
  selector: 'web-admin-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCreateVoteComponent extends AbstractCreate<VoteDto> {
  protected objectType: 'VoteDto' = 'VoteDto';

  public constructor(
    readonly postDataService: PostDataService,
    readonly snackBar: MatSnackBar
  ) {
    super(postDataService, snackBar);
  }
}
