/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  CouponLibraryDataService,
  NewsFeedEntry,
  NewsFeedEntryStatus,
  PostDataService,
} from '@otp-junior/admin-client';
import { FormComponent } from '../form/form.component';

@Component({
  selector: 'web-admin-survey-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyEditComponent<TEntry extends NewsFeedEntry> {
  protected objectType: TEntry['objectType'];
  public control = new FormControl();
  public form = new FormGroup({ data: this.control });
  public status: NewsFeedEntryStatus;
  public id: number;
  @ViewChild(FormComponent) child: FormComponent;
  constructor(
    protected readonly snackBar: MatSnackBar,
    protected readonly postDataService: PostDataService,
    public readonly couponApi: CouponLibraryDataService,
    private readonly route: ActivatedRoute
  ) {
    this.id = (this.route.snapshot.paramMap.get('type') as unknown) as number;
    this.objectType = 'SurveyDto';
  }

  public get removable(): boolean {
    return (
      this.child?.status === NewsFeedEntryStatus.Archive ||
      this.child?.status === NewsFeedEntryStatus.Draft
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public submit(): TEntry {
    const data = this.child.getForm();
    this.child.form.markAllAsTouched();
    if (!data || this.child.form.invalid) {
      this.snackBar.open('Töltsd ki az összes mezőt!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
      return;
    }
    new Date(data.startDate) > new Date()
      ? (this.status = NewsFeedEntryStatus.Scheduled)
      : (this.status = NewsFeedEntryStatus.Active);
    for (let index = 0; index < data.steps.length; index++) {
      for (
        let jindex = 0;
        jindex < data.steps[index].choices.length;
        jindex++
      ) {
        if (data.steps[index].choices[jindex].answer == '') {
          data.steps[index].choices.splice(jindex, 1);
        }
      }
      if (
        data.steps[index].type === 'SLIDER' ||
        data.steps[index].type === 'FREETEXT'
      ) {
        data.steps[index].choices = [];
      }
      if (data.steps[index].multiple) {
        data.steps[index].type = 'MULTI';
      }
      if (typeof data.steps[index].choices[0] == 'string') {
        data.steps[index].choices = data.steps[
          index
        ].choices.map((choice: string) => ({ value: choice }));
        if (data.steps[index].none) {
          data.steps[index].choices.push({ value: 'Egyik sem.', type: 'NONE' });
        }
      }
      delete data.steps[index].multiple;
      delete data.steps[index].none;
    }
    delete data.couponDescription;
    delete data.couponImageId;
    this.sendCreation(data).subscribe(() => {
      this.child.saveCoupon();
      this.child.form.markAsPristine();
    });
  }

  public onArchiveClicked(): void {
    this.postDataService
      .updateStatus(this.id, {
        status: NewsFeedEntryStatus.Archive,
      })
      .subscribe(() => this.child.form.markAsPristine());
  }

  public onDeleteClicked(): void {
    if (this.child.status === 'ACTIVE') {
      this.snackBar.open('Aktív bejegyzést nem lehet törölni!', 'X', {
        panelClass: ['snack-bar-container', 'failure'],
        duration: 3000,
      });
    } else {
      this.postDataService
        .deleteNewsFeedEntry(this.id)
        .subscribe(() => this.child.form.markAsPristine());
    }
  }

  private sendCreation(data: TEntry, draft = true) {
    return this.postDataService.updateNewsFeedEntry(this.id, {
      objectType: this.objectType,
      type: 'SURVEY',
      status: this.status,
      draft: this.status == NewsFeedEntryStatus.Draft ? draft : !draft,
      ...data,
    });
  }
}
