<form [formGroup]="form" (ngSubmit)="submit()">
  <h1>Új {{ jobTypeName }} állás hozzáadása</h1>
  <web-admin-job-form [formControl]="control" [isVolunteer]="isVolunteer" (switchLinkToCreate)="createSwitchLink = ($event)">
    <div in-bottom-card>
      <a
        mat-stroked-button
        type="button"
        data-test="discard"
        color="primary"
        routerLink="/job"
      >
        Elvetés
      </a>
      <button mat-raised-button data-test="submit" color="primary">
        Feltöltés
      </button>
    </div>
  </web-admin-job-form>
</form>
