<div>
  <mat-form-field appearance="standard">
    <mat-select [formControl]="campaignControl">
      <mat-option
        *ngFor="let campaign of availableCampaigns$ | async"
        [value]="campaign.id"
        >{{ campaign.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
<table
  mat-table
  matSort
  (matSortChange)="onSortChange($event)"
  [dataSource]="analytics$"
  aria-label="Game analytics table"
>
  <ng-container matColumnDef="gameCampaignName">
    <th id="campaign-id" mat-header-cell *matHeaderCellDef>Kampány</th>
    <td mat-cell *matCellDef="let element">
      {{ element.gameCampaignName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell id="headerCell-playerName" *matHeaderCellDef>
      Játékos neve
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.name }}
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell id="headerCell-playerEmail" *matHeaderCellDef>
      Játékos email címe
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.email }}
    </td>
  </ng-container>
  <ng-container matColumnDef="points">
    <th
      mat-header-cell
      id="headerCell-totalPoints"
      *matHeaderCellDef
      mat-sort-header
    >
      Összpontszám
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.points }}
    </td>
  </ng-container>
  <ng-container matColumnDef="level">
    <th mat-header-cell id="headerCell-stage" *matHeaderCellDef>Pálya</th>
    <td mat-cell *matCellDef="let element">
      {{ element.level }}
    </td>
  </ng-container>
  <ng-container matColumnDef="startedCount">
    <th mat-header-cell id="headerCell-startedCount" *matHeaderCellDef>
      Elkezdve
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.startedCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="finishedCount">
    <th mat-header-cell id="headerCell-finishedCount" *matHeaderCellDef>
      Befelyezve
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.finishedCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="gameAnswerCounts">
    <th mat-header-cell id="headerCell-quizAnswers" *matHeaderCellDef>
      Kvíz helyes válaszok
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.gameAnswerCounts }}
    </td>
  </ng-container>
  <ng-container matColumnDef="spentTimeInSeconds">
    <th mat-header-cell id="headerCell-quizTime" *matHeaderCellDef>Kvíz idő</th>
    <td mat-cell *matCellDef="let element">
      {{ element.spentTimeInSeconds }}
    </td>
  </ng-container>

  <ng-container matColumnDef="moneyWonByQuiz">
    <th
      mat-header-cell
      id="headerCell-moneyWonByQuiz"
      *matHeaderCellDef
      mat-sort-header
    >
      Kvízzel nyert pénz
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.moneyWonByQuiz }}
    </td>
  </ng-container>

  <ng-container matColumnDef="campaignTitle">
    <th id="campaign-title" mat-header-cell *matHeaderCellDef>Kampány</th>
    <td mat-cell *matCellDef="let element">
      {{ element.campaignTitle }}
    </td>
  </ng-container>
  <ng-container matColumnDef="lifeStageTitle">
    <th id="life-stage-title" mat-header-cell *matHeaderCellDef>Életszakasz</th>
    <td mat-cell *matCellDef="let element">
      {{ element.lifeStageTitle }}
    </td>
  </ng-container>
  <ng-container matColumnDef="levelTitle">
    <th id="game-id" mat-header-cell *matHeaderCellDef>Pálya</th>
    <td mat-cell *matCellDef="let element">
      {{ element.levelTitle }}
    </td>
  </ng-container>
  <ng-container matColumnDef="responseWithBetCount">
    <th id="response-with-bet" mat-header-cell *matHeaderCellDef>Téttel</th>
    <td mat-cell *matCellDef="let element">
      {{ element.responseWithBetCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="responseWithoutBetCount">
    <th id="response-without-bet" mat-header-cell *matHeaderCellDef>
      Tét nélkül
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.responseWithoutBetCount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="filledCount">
    <th id="filled-id" mat-header-cell *matHeaderCellDef>Kitöltők</th>
    <td mat-cell *matCellDef="let element">
      {{ element.filledCount }}
    </td>
  </ng-container>

  <ng-container matColumnDef="results">
    <th id="results-id" mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="right">
      <a mat-button color="primary" [routerLink]="getRouterLink(element)">
        <img src="assets/survey/green-draft.svg" alt="Eredmények icon" />
        Eredmények
      </a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator
  [length]="count$ | async"
  [pageSize]="10"
  [pageSizeOptions]="[10]"
  [pageIndex]="this.page$ | async"
  (page)="onPageChange($event)"
></mat-paginator>
