import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LifeStageRequest } from '@otp-junior/admin-client';
import { Subject, Subscription } from 'rxjs';
import { CampaignVM } from '../../models/campaign.vm';

@Component({
  selector: 'web-admin-campaign-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignFormComponent implements OnDestroy, OnInit {
  private readonly formGoupValueChangesSubscription: Subscription;
  private readonly destroy$ = new Subject<void>();

  @Input() public set campaign(value: CampaignVM | Partial<CampaignVM>) {
    if (value) {
      this.formGroup?.patchValue({
        name: value.name,
        startDate: value.startDate,
        endDate: value.endDate,
        lifeStages: value.lifeStages,
      });
    }
  }

  @Input() public campaignEditMode = false;
  @Input() public lifeStageEditMode = false;

  public get controls(): Record<keyof CampaignVM, FormControl> {
    return this.formGroup.controls as Record<keyof CampaignVM, FormControl>;
  }

  constructor(private readonly snackBar: MatSnackBar) {
    this.formGoupValueChangesSubscription = this.formGroup.valueChanges.subscribe(
      ({ startDate, endDate }) => {
        const campaignStartDate = new Date(
          startDate || this.formGroup.controls.startDate.value
        );
        const campaignEndDate = new Date(
          endDate || this.formGroup.controls.endDate.value
        );

        // If any of the lifeStages has a scheduledAt date that is before the startDate, show a snackbar
        const lifeStages: LifeStageRequest[] = this.formGroup.controls
          .lifeStages.value;
        if (
          lifeStages &&
          lifeStages.some(
            (lifeStage) =>
              lifeStage.scheduledAt &&
              new Date(lifeStage.scheduledAt) < campaignStartDate
          )
        ) {
          this.formGroup.setErrors({ invalid: true });
          this.snackBar.open(
            'Az életszakasz kezdete nem lehet korábbi a kampány kezdési dátumánál',
            'X',
            {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              panelClass: ['snack-bar-container', 'failure'],
              duration: 30000,
            }
          );
        } else if (
          lifeStages &&
          lifeStages.some(
            (lifeStage) =>
              lifeStage.scheduledAt &&
              new Date(lifeStage.scheduledAt) > campaignEndDate
          )
        ) {
          this.formGroup.setErrors({ invalid: true });
          this.snackBar.open(
            'Az életszakasz kezdete nem lehet későbbi a kampány zárási dátumánál',
            'X',
            {
              panelClass: ['snack-bar-container', 'failure'],
              duration: 30000,
            }
          );
        } else if (startDate && endDate && startDate > endDate) {
          this.formGroup.controls.endDate.setErrors({ invalid: true });
          this.snackBar.open(
            'A kezdési dátum nem lehet a befejezési dátumnál későbbi',
            'X',
            {
              panelClass: ['snack-bar-container', 'failure'],
              duration: 30000,
            }
          );
        } else {
          // eslint-disable-next-line unicorn/no-useless-undefined
          this.formGroup.setErrors(undefined);
          // eslint-disable-next-line unicorn/no-useless-undefined
          this.formGroup.controls.endDate.setErrors(undefined);
          this.snackBar.dismiss();
        }
      }
    );
  }

  public formGroup: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    lifeStages: new FormControl([]),
  });

  public ngOnInit(): void {
    if (this.lifeStageEditMode) {
      this.disableInLifeStageEdit();
    }
  }

  public disableInLifeStageEdit(): void {
    this.formGroup.controls.name.disable();
    this.formGroup.controls.startDate.disable();
    this.formGroup.controls.endDate.disable();
  }

  public lifeStageControlGenerator(): FormGroup {
    return new FormGroup({
      id: new FormControl(undefined, [Validators.required]),
      title: new FormControl({ value: undefined, disabled: true }, [
        Validators.required,
      ]),
      scheduledAt: new FormControl(undefined, [Validators.required]),
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.formGoupValueChangesSubscription.unsubscribe();
  }
}
