import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { NewsFeedEntryType } from '@otp-junior/admin-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CurrentTypeService {
  public constructor(private readonly activatedRoute: ActivatedRoute) {}

  public get type(): NewsFeedEntryType {
    return this.calculateTypeFromRoute(this.activatedRoute.snapshot.root);
  }

  private calculateTypeFromRoute(start: ActivatedRouteSnapshot) {
    const routes = [start];
    let type: NewsFeedEntryType;
    while (routes.length > 0) {
      const route = routes.pop();
      if (route.data.type) {
        type = route.data.type;
      }
      routes.push(...route.children);
    }
    return type;
  }

  public get type$(): Observable<NewsFeedEntryType> {
    return this.activatedRoute.data.pipe(
      map(() => this.calculateTypeFromRoute(this.activatedRoute.snapshot.root))
    );
  }
}
