import {
  GamePlayersAnalyticsSort,
  LexiconWordSort,
  NewsFeedEntryStatisticsFilterSort,
} from '@otp-junior/admin-client/model/models';

export enum SortBy {
  Word = 'WORD',
  Date = 'DATE',
  OpenCount = 'OPEN_COUNT',
  Title = 'TITLE',
  Points = 'POINTS',
  MoneyWonByQuiz = 'MONEY_WON_BY_QUIZ',
}

export enum MaterialSortBy {
  Word = 'word',
  Date = 'date',
  OpenCount = 'openCount',
  Title = 'title',
  Points = 'points',
  MoneyWonByQuiz = 'moneyWonByQuiz',
}

export type UnionSortBy =
  | NewsFeedEntryStatisticsFilterSort
  | GamePlayersAnalyticsSort
  | LexiconWordSort
  | SortBy;
