import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import {
  DateTimeInputModule,
  FileUploaderModule,
  ListEmptyModule,
} from '@web-admin/shared-lib';
import { DeclarationRoutingModule } from './declaration-routing.module';
import { DeclarationListComponent } from './list/list.component';
import { NewDeclarationModalComponent } from './new-declaration-modal/new-declaration-modal.component';
import { PdfUploadComponent } from './pdf-uploader/pdf-uploader.component';

@NgModule({
  declarations: [
    DeclarationListComponent,
    NewDeclarationModalComponent,
    PdfUploadComponent,
  ],
  imports: [
    CommonModule,
    DeclarationRoutingModule,
    MatPaginatorModule,
    ListEmptyModule,
    MatTableModule,
    MatButtonModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    DateTimeInputModule,
    FileUploaderModule,
  ],
})
export class DeclarationModule {}
