import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmploymentType, NewsFeedEntryType } from '@otp-junior/admin-client';

@Component({
  selector: 'web-admin-type-icon',
  templateUrl: './type-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeIconComponent {
  @Input() public type: NewsFeedEntryType | EmploymentType;
  public srcMap: Record<NewsFeedEntryType | EmploymentType, string> = {
    ARTICLE: 'assets/post/type-icon/article.svg',
    DISCOUNT: 'assets/post/type-icon/discount.svg',
    EVENT: 'assets/post/type-icon/event.svg',
    VIDEO: 'assets/post/type-icon/video.svg',
    VOTE: 'assets/post/type-icon/vote.svg',
    SURVEY: 'assets/post/type-icon/vote.svg',
    QUIZ: 'assets/post/type-icon/event.svg',
    GAME_QUIZ: 'assets/post/type-icon/gamequiz.svg',
    INTERNSHIP: 'assets/job/type-icon/internship.svg',
    PAID: 'assets/job/type-icon/paid.svg',
    VOLUNTEER: 'assets/job/type-icon/volunteer.svg',
  };
}
