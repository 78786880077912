<button
  mat-flat-button
  class="import-button"
  color="secondary"
  mat-stroked-button
  type="button"
  (click)="input.click()"
>
  <input type="file" (change)="onImportClicked()" #input />
  <img alt="Import-XLS" src="../../../../assets/icons/xls.svg" />
  <strong>Import XLS</strong>
</button>
