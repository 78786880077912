import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'web-admin-job-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobPinComponent {
  @Input() public pinned: boolean;
}
