import { Injectable } from '@angular/core';
import {
  GameQuizDataService,
  GameQuizDto,
  PostDataService,
} from '@otp-junior/admin-client';
import { NewsFeedEntry } from '@otp-junior/admin-client/model/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GameQuizListVM } from '../list/list-element.vm';
import { mapGameQuizFormToApiModel } from '../mappers/map-game-quiz-form-model-to-api-model';
import { mapToGameQuizDetailsVM } from '../mappers/map-to-game-quiz-details-vm';
import { mapToGameQuizListElementVM } from '../mappers/map-to-game-quiz-list-element-vm';
import { GameQuizDetailsVM } from '../models/game-quiz-details.vm';
import { GameQuizForm } from '../quiz/form/form.component';

@Injectable()
export class GameQuizService {
  public constructor(
    private readonly gameQuizDataService: GameQuizDataService,
    private readonly campaignLevelService: GameQuizDataService,
    private readonly postDataService: PostDataService
  ) {}

  public getGameQuizzes$(
    page: number,
    pageSize = 10
  ): Observable<GameQuizListVM> {
    return this.gameQuizDataService.listGameQuizzes(page, pageSize).pipe(
      map((gameDto) => ({
        totalElements: gameDto.totalElements,
        items: gameDto.content.map((content) =>
          mapToGameQuizListElementVM(content)
        ),
      }))
    );
  }

  public createGameQuiz(data: GameQuizForm): Observable<NewsFeedEntry> {
    return this.postDataService.createNewsFeedEntry(
      mapGameQuizFormToApiModel(data)
    );
  }

  public getGameQuiz(id: number): Observable<GameQuizDetailsVM> {
    return this.postDataService
      .getNewsFeedEntryById(id)
      .pipe(map((dto) => mapToGameQuizDetailsVM(dto as GameQuizDto)));
  }

  public updateGameQuiz(
    id: number,
    data: GameQuizForm
  ): Observable<NewsFeedEntry> {
    return this.postDataService.updateNewsFeedEntry(
      id,
      mapGameQuizFormToApiModel(data, id)
    );
  }
}
