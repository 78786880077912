import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MultiInputTemplateDirective } from './multi-input-template.directive';
import { MultiInputComponent } from './multi-input/multi-input.component';

@NgModule({
  declarations: [MultiInputComponent, MultiInputTemplateDirective],
  exports: [MultiInputComponent, MultiInputTemplateDirective],
  imports: [CommonModule],
})
export class MultiInputModule {}
