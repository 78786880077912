import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'web-admin-post-reactions',
  templateUrl: './post-reactions.component.html',
  styleUrls: ['./post-reactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostReactionsComponent {
  @Input() public view: number;
  @Input() public reactions: number;
}
