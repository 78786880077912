<div class="campaign-edit">
  <h1>Kampány szerkesztése</h1>

  <ng-container *ngIf="campaign$ | async as campaign; else loading">
    <web-admin-campaign-form
      [campaign]="campaign"
      [campaignEditMode]="true"
    ></web-admin-campaign-form>
    <div class="actions">
      <div class="left">
        <a
          mat-stroked-button
          type="button"
          data-test="discard"
          color="primary"
          routerLink="/game/campaign"
        >
          {{
            campaign.status === CampaignListStatus.ARCHIVE
              ? 'Vissza'
              : 'Elvetés'
          }}
        </a>
        <button
          *ngIf="campaign.status !== CampaignListStatus.ARCHIVE"
          mat-stroked-button
          type="button"
          data-test="final-delete"
          color="warn"
          (click)="stopCampaign()"
        >
          Kampány leállítása
        </button>
      </div>
      <button
        *ngIf="campaign.status !== CampaignListStatus.ARCHIVE"
        mat-raised-button
        data-test="submit"
        color="primary"
        (click)="submit()"
      >
        Módosítás
      </button>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
