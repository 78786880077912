import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ListEmptyComponent } from './list-empty/list-empty.component';

@NgModule({
  declarations: [ListEmptyComponent],
  imports: [MatCardModule],
  exports: [ListEmptyComponent],
})
export class ListEmptyModule {}
