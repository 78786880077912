import { GameCampaignLifeStageResponseStatusEnum } from '@otp-junior/admin-client';
import { GameCampaignLifeStageResponse } from '@otp-junior/admin-client/model/gameCampaignLifeStageResponse';
import {
  GameCampaignLifeStageStatusEnum,
  LifeStageVM,
} from '../models/life-stage.vm';

export const mapToLifeStageVM = (
  lifeStage: GameCampaignLifeStageResponse
): LifeStageVM => ({
  id: lifeStage.id,
  scheduledAt: lifeStage.scheduledAt,
  status: mapLifeStageStatus(lifeStage.status),
  campaignId: lifeStage.campaignId,
  title: lifeStage.title,
  campaignTitle: lifeStage.campaignTitle,
});

export const mapLifeStageStatus = (
  status: GameCampaignLifeStageResponseStatusEnum
): GameCampaignLifeStageStatusEnum => {
  switch (status) {
    case GameCampaignLifeStageResponseStatusEnum.Active:
      return GameCampaignLifeStageStatusEnum.ACTIVE;
    case GameCampaignLifeStageResponseStatusEnum.Scheduled:
      return GameCampaignLifeStageStatusEnum.SCHEDULED;
  }
};
