<div class="results-header">
  <h1>Pálya eredményei</h1>
  <button
    mat-flat-button
    class="export-button"
    color="primary"
    mat-stroked-button
    type="button"
    (click)="onExportClicked()"
  >
    <img alt="Export-XLS" src="../../../../assets/icons/xls-white.svg" />
    <strong>Export XLS</strong>
  </button>
</div>

<div *ngIf="data$ | async as data">
  <shared-form-card class="results">
    <h2>Választások összegzése</h2>
    <div class="details">
      <div class="level-title-container">
        <img alt="trófea" src="../../../../assets/icons/trophy.svg" />
        <h3>{{ data.campaignTitle }} - {{ data.levelTitle }}</h3>
      </div>
      <div class="header">
        <div class="details-card">
          <p class="count">Elkezdve</p>
          <p class="count-number">{{ data.startedCount }} Fő</p>
        </div>
        <div class="details-card">
          <p class="count">Befejezve</p>
          <p class="count-number">{{ data.finishedCount }} Fő</p>
        </div>
      </div>
    </div>
    <table summary="Eredmények">
      <thead>
        <tr>
          <th>Kártyatípus</th>
          <th>Cím</th>
          <th>Opció</th>
          <th>OTPénz</th>
          <th>Érték</th>
          <th>Élmény</th>
          <th>Tudás</th>
          <th class="accepted">Elfogadava</th>
          <th class="refused">Elutasítva</th>
          <th>%</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cardResult of data.cardResults">
          <td>{{ cardResult.type }}</td>
          <td>{{ cardResult.title }}</td>
          <td>{{ cardResult.actionTitle }}</td>
          <td>{{ cardResult.money }}</td>
          <td>{{ cardResult.prosperity }}</td>
          <td>{{ cardResult.experience }}</td>
          <td>{{ cardResult.life }}</td>
          <td>{{ cardResult.acceptedCount }}</td>
          <td>{{ cardResult.refusedCount }}</td>
          <td>{{ cardResult.percentage }}</td>
        </tr>
      </tbody>
    </table>
  </shared-form-card>
</div>
<div class="loading" *ngIf="exportLoading | async">
  <mat-spinner></mat-spinner>
</div>
