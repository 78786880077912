import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmModalData } from '@web-admin/shared-lib';

@Component({
  selector: 'web-admin-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  public readonly data: ConfirmModalData;
  public constructor(
    @Inject(MAT_DIALOG_DATA) data: ConfirmModalData,
    public dialogReference: MatDialogRef<ConfirmModalComponent>
  ) {
    this.data = {
      color: 'primary',
      ...data,
    };
  }

  public close(value: boolean): void {
    this.dialogReference.close(value);
  }
}
