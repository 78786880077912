<shared-form-card>
  <h2>Állás beállításai</h2>
  <h3>Állás jellemzői</h3>
  <mat-checkbox [formControl]="controls.adultsOnly" data-test="adultsOnly">
    18 éven felülieknek
  </mat-checkbox>
  <br />
  <mat-checkbox
    [formControl]="controls.foreignLanguageRequired"
    data-test="foreignLanguageRequired"
  >
    Nyelvtudás szükséges
  </mat-checkbox>
  <br />
  <mat-form-field>
    <mat-label>Munka típusa</mat-label>
    <web-admin-job-category-select
      [formControl]="controls.jobCategoryId"
      [isVolunteer]="isVolunteer && !isEdit"
    ></web-admin-job-category-select>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <ng-container *ngIf="!isVolunteer">
    <mat-form-field>
      <mat-label>Órabér (Bruttó)</mat-label>
      <input
        type="number"
        matInput
        [formControl]="controls.wageHourly"
        data-test="wageHourly"
      />
      <div matSuffix>Ft</div>
      <mat-error *showError></mat-error>
    </mat-form-field>
    <br />
  </ng-container>
  <mat-form-field>
    <mat-label>Munkavégzés helye</mat-label>
    <web-admin-city-select
      [formControl]="controls.cityId"
    ></web-admin-city-select>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <div class="row">
    <mat-form-field>
      <mat-label>Hirdetés kezdete</mat-label>
      <date-time-input
        data-test="startDate"
        [formControl]="controls.startDate"
      ></date-time-input>
      <mat-error *showError></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Hirdetés vége</mat-label>
      <date-time-input
        [formControl]="controls.endDate"
        data-test="endDate"
      ></date-time-input>
      <mat-error *showError></mat-error>
    </mat-form-field>
  </div>

  <mat-radio-group aria-label="Select an option" [(ngModel)]="switchLink" >
      <mat-radio-button value="link"  (change)="onItemChange('link')" class="radio-button">Jelentkezés linken</mat-radio-button>
      <mat-radio-button value="email"  (change)="onItemChange('email')">Jelentkezés emailen</mat-radio-button>
  </mat-radio-group>

  <br />
  <br />
  <mat-form-field *ngIf="switchLink === 'email'">
    <mat-label>Email cím</mat-label>
    <input
    type="string"
    matInput
    [formControl]="controls.emailTo"
    data-test="'emailTo'"
    />
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br>
  <mat-form-field *ngIf="switchLink === 'email'">
    <mat-label>Email tárgya</mat-label>
    <input
    type="string"
    matInput
    [formControl]="controls.emailSubject"
    data-test="'emailSubject'"
    />
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <h4 *ngIf="switchLink === 'email'">Email szövege</h4>
  <angular-editor
  [formControl]="controls.emailBody"
  [config]="editorConfig"
  placeholder="Bejegyzés szövege"
  data-test="html"
  *ngIf="switchLink === 'email'"
  ></angular-editor>
  
  <mat-form-field *ngIf="switchLink === 'link'">
    <mat-label>Jelentkezés linkje</mat-label>
    <input
      matInput
      [formControl]="controls.applicationUrl"
      data-test="applicationUrl"
    />
    <mat-error *showError></mat-error>
  </mat-form-field>
</shared-form-card>
<shared-form-card class="full-width">
  <h2>Állás leírása</h2>
  <mat-form-field>
    <mat-label>Pozíció neve</mat-label>
    <input matInput [formControl]="controls.name" data-test="name" />
    <mat-hint align="end">
      {{ controls.name.value?.length || 0 }}/{{ nameMaxLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <shared-multi-input
    #multiInput
    [formControl]="controls.conditions"
    [controlGenerator]="conditionsControlGenerator"
  >
    <div
      *sharedMultiInputTemplate="let control; let index = index"
      [formGroupPass]="control"
      class="deletable-control"
    >
      <mat-form-field>
        <mat-label>Feltételek</mat-label>
        <input
          matInput
          formControlName="title"
          [attr.data-test]="'condition-' + index"
        />
        <mat-error *showError></mat-error>
      </mat-form-field>
      <button
        *ngIf="controls.conditions.value?.length > 1"
        (click)="multiInput.removeControl(index)"
        mat-icon-button
        color="warn"
        matSuffix
        [attr.data-test]="'condition-remove-' + index"
      >
        <img src="assets/icons/delete.svg" alt="" />
      </button>
    </div>
  </shared-multi-input>
  <button
    (click)="multiInput.addControl()"
    type="button"
    mat-button
    color="primary"
    class="add-condition"
    data-test="add-condition"
  >
    + Feltétel hozzáadása
  </button>
  <mat-form-field>
    <mat-label>Munkaidő (mettől - meddig, melyik napokon)</mat-label>
    <input
      matInput
      [formControl]="controls.workingHours"
      data-test="workingHours"
    />
    <mat-hint align="end">
      {{ controls.workingHours.value?.length || 0 }}/{{ workingHoursMaxLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Feladat leírása</mat-label>
    <textarea
      matInput
      [formControl]="controls.task"
      data-test="task"
      cdkTextareaAutosize
    ></textarea>
    <mat-hint align="end">
      {{ controls.task.value?.length || 0 }}/{{ taskMaxLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Amit kínálunk</mat-label>
    <textarea
      matInput
      [formControl]="controls.offer"
      data-test="offer"
      cdkTextareaAutosize
    ></textarea>
    <mat-hint align="end">
      {{ controls.offer.value?.length || 0 }}/{{ offerMaxLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <ng-content selector="[in-bottom-card]"></ng-content>
</shared-form-card>
