import { Injectable } from '@angular/core';
import {
  NewsFeedEntryType,
  NewsFeedStatisticsFilter,
} from '@otp-junior/admin-client';

export interface TabData {
  tab: string;
  campaignId?: number;
  type?: NewsFeedEntryType;
  page?: number;
  pageSize?: number;
  sortingRequest?: { [key: string]: string };
  filter?: NewsFeedStatisticsFilter;
}

@Injectable({
  providedIn: 'root',
})
export class TabStorageService {
  private readonly tabStorageKey = 'tabData';

  public storeTabData(tabData: TabData): void {
    const data = { ...tabData, tab: tabData.tab.replace('-', '_') };
    localStorage.setItem(this.tabStorageKey, JSON.stringify(data));
  }

  public getTabData(): TabData | null {
    const data = localStorage.getItem(this.tabStorageKey);
    /* eslint-disable unicorn/no-null */
    return data ? JSON.parse(data) : null;
  }
}
