<shared-form-card>
  <h2>Bejegyzés beállításai</h2>
  <mat-checkbox [formControl]="controls.marketingContent">
    Marketing tartalom
  </mat-checkbox>
  <h3>Publikálás adatai</h3>
  <div class="row">
    <mat-form-field>
      <mat-label>Szavazás kezdete</mat-label>
      <date-time-input
        [formControl]="controls.startDate"
        data-test="startDate"
      ></date-time-input>
      <mat-error *showError></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Szavazás vége</mat-label>
      <date-time-input
        [formControl]="controls.endDate"
        data-test="endDate"
      ></date-time-input>
      <mat-error *showError></mat-error>
    </mat-form-field>
  </div>
</shared-form-card>
<shared-form-card class="full-width">
  <h2>Szavazás részletei</h2>
  <mat-form-field>
    <mat-label>Szavazás kérdése</mat-label>
    <input matInput [formControl]="controls.question" data-test="question" />
    <mat-hint align="end">
      {{ controls.question.value?.length || 0 }}/{{ maxQuestionLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <web-admin-file-picker
    [formControl]="controls.imageId"
    titleText="Képfeltöltése"
  ></web-admin-file-picker>
  <br />
  <mat-form-field>
    <mat-label>A) válasz</mat-label>
    <input
      matInput
      [formControl]="controls.optionADescription"
      data-test="optionADescription"
    />
    <mat-hint align="end">
      {{ controls.optionADescription.value?.length || 0 }}/{{ maxAnswerLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>B) válasz</mat-label>
    <input
      matInput
      [formControl]="controls.optionBDescription"
      data-test="optionBDescription"
    />
    <mat-hint align="end">
      {{ controls.optionBDescription.value?.length || 0 }}/{{ maxAnswerLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Rövid leírás</mat-label>
    <textarea
      matInput
      [formControl]="controls.description"
      data-test="description"
      cdkTextareaAutosize
    ></textarea>
    <mat-hint align="end">
      {{ controls.description.value?.length || 0 }}/{{ maxDescriptionLength }}
    </mat-hint>
    <mat-error *showError></mat-error>
  </mat-form-field>
  <ng-content selector="[in-bottom-card]"></ng-content>
</shared-form-card>
